import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// models
import { AdditionalInfoModel } from '../../models/AdditionalInfoModel';
import { AdditionalInfoInsertModel } from '../../models/AdditionalInfoModel';
// services
import { AdditionalService } from '../../services/additional.services';
// components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from '@ngx-translate/core';
@Component({
selector: 'app-additional-edit',
templateUrl: './edit.component.html',
styleUrls: ['./edit.component.scss']
})
export class AdditionalEditComponent extends AuthenticatedComponent implements OnInit {

constructor(
public additionalService: AdditionalService,
protected translate: TranslateService,
private activeRoute: ActivatedRoute) {
super();
}

logo;
additional: AdditionalInfoModel = new AdditionalInfoModel();
private clicked = false;

  ngOnInit() {

if (this.isEdit) {

const additionalId = this.activeRoute.snapshot.params.id;
this.additionalService.get(additionalId).subscribe(res => {
if (!!res) {
this.additional = res;
} else {
this.alertService.showError(this.translate.instant('alerts.SOMETHINGWENTWRONG'));
this.back();
}
});
}

}

public get isEdit() {
return this.activeRoute.snapshot.url.join('').includes('edit');
}

public logoUpload(): Promise<number> {
return new Promise((resolve, reject) => {
if (this.logo) {
this.additionalService.multipleUploadFile(this.logo).subscribe(res => {
resolve(res.data[0].id);
});
} else {
resolve();
}
});
}

createInsertModel(additional: AdditionalInfoModel): AdditionalInfoInsertModel {
const insertModel: AdditionalInfoInsertModel = new AdditionalInfoInsertModel();

insertModel.id = additional.id;
insertModel.name = additional.name;
insertModel.imageIds = additional.imageUrls.map(item => item.id);
insertModel.logoId = additional.logoId;
insertModel.coverId = additional.coverId;
insertModel.nameTranslationId = additional.nameTranslationId;
insertModel.isActive = additional.isActive;

return insertModel;
}

public save() {

if (!this.isFormValid()) { return; }

const insertModel = this.createInsertModel(this.additional);

if (!this.additional.id) {
this.clicked = true;
this.additionalService.save(insertModel).subscribe(data => {
this.clicked = false;
if (!data.hasError) {
 this.additionalService.updateData({id: data.data}).subscribe(res => {
this.alertService.showSuccess(this.translate.instant('alerts.ADDITIONALINFOADDEDSUCCESFULLY'));
const data = this.createInsertModel(res.data);
this.logoUpload().then(logoId => {
if (!!logoId) { data.logoId = logoId; }
if (!!data.logoId ) {

this.additionalService.save(data).subscribe(d => {
if (!d.hasError) {
this.additionalService.updateData({id: data.id}).subscribe(() => {
this.alertService.showSuccess(this.translate.instant('alerts.ADDITIONALINFOUPDATEDSUCCESFULLY'));
this.back();
});
} else {
const errors = d.errors.map(r => r.message);
this.alertService.showError(errors);
}
});
} else {
this.back();
}
});
});
} else {
const errors = data.errors.map(r => r.message);
this.alertService.showError(errors);
}
});
} else {
      this.clicked = true;
      this.logoUpload().then(logoId => {
if (!!logoId) { insertModel.logoId = logoId; }
this.additionalService.save(insertModel).subscribe(d => {
this.clicked = false;
if (!d.hasError) {
this.additionalService.updateData({id: insertModel.id}).subscribe(res => {
this.alertService.showSuccess(this.translate.instant('alerts.ADDITIONALINFOUPDATEDSUCCSEFULLY'));
this.back();
});
} else {
const errors = d.errors.map(r => r.message);
this.alertService.showError(errors);
}
});
});
}
}

public isFormValid() {
if (!super.isFormValid()) {
this.alertService.showError('The form is not valid');
return false;
}
return true;
}
// public back() {
// 	this.router.navigateByUrl("additional-info/list");
// }
}
