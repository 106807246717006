import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";
import { OrganisationService } from "./services/organisation.service";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class OrganisationShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private organisationService: OrganisationService) {
        super();
    }

    ngOnInit() {
    }

    get moduleDataLoaded() {
        const storeKeysToVerify = ['organisation'];
        return storeKeysToVerify.map(k => !!this.store._(k)).
        reduce((acc, val) => {
            return acc && val;
        }, true)
    }

}