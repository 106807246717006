import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { DiscountStore } from './discount.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex'

const CustomSelectOptions: any = {
    optionValueField: 'id',
    optionTextField: 'name'
};

import { DiscountService } from "./services/discount.service";
import { DiscountShellComponent } from './discount-shell.component';
import { DiscountListComponent } from './discount/list/list.component';
import { DiscountEditComponent } from './discount/edit/edit.component';
import { DatePipe } from '@angular/common';
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    NgxSelectModule.forRoot(CustomSelectOptions)
  ],
  declarations: [
    DiscountShellComponent,
    DiscountListComponent,
    DiscountEditComponent,
  ],

  exports: [
    DiscountListComponent,
  ],
  entryComponents: [],

  providers: [ DiscountService,DatePipe ]
})
export class DiscountModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
      ...services.discountService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let discountService = appinjector.get(DiscountService);

    return {
      store,
      discountService,
    }
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    DiscountStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    DiscountStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.discountService.preloadData();
  }

}
