import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { LOCALE_ID } from "@angular/core";
import hyLocale from "@angular/common/locales/hy";
registerLocaleData(hyLocale);
import { ConnectionServiceModule } from "ng-connection-service";
import { NgModule } from "@angular/core";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { HttpListener } from "./shared/services/HttpListener";
import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { RoutesModule } from "./routes/routes.module";
import { AppService } from "./app.service";
import { AppStore } from "./shared/services/store.service";
import { AppComponent } from "./app.component";

import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { LayoutModule } from "./layout/layout.module";
import { PlaceModule } from "./place.module/place.module";
import { MenuModule } from "./menu.module/menu.module";
import { TableModule } from "./tables.module/tables.module";
import { CategoryModule } from "./category.module/category.module";
import { AccountModule } from "./account.module/account.module";
import { OptionModule } from "./option.module/option.module";
import { OrderModule } from "./order.module/order.module";
import { IntroModule } from "./intro.module/intro.module";
import { StatusModule } from "./status.module/status.module";
import { ClientModule } from "./client.module/client.module";
import { PropertyModule } from "./property.module/property.module";
import { SettingsModule } from "./settings.module/settings.module";
import { DiscountModule } from "./discount.module/discount.module";
import { AdditionalModule } from "./additional.module/additional.module";
import { IngredientModule } from "./ingredient.module/ingredient.module";
import { NotificationModule } from "./notification.module/notification.module";
import { OrganisationModule } from "./Organisation.module/organisation.module";
import { InitializeAppComponent } from "./initialize-app/initialize-app.component";
import { IMaskModule } from "angular-imask";
import { AgGridModule } from "ag-grid-angular";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { registerLocaleData } from "@angular/common";
import { LogModule } from "./log.module/log.module";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { FormsModule } from "@angular/forms";
import { RequestModule } from "./request.module/request.module";

let appStore = new AppStore();
let appService = new AppService(appStore);

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, InitializeAppComponent],
  imports: [
    HttpClientModule,
    NgxSkeletonLoaderModule.forRoot({
      animation: "pulse",
      loadingText: "This item is actually loading...",
    }),
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    PlaceModule,
    LayoutModule,
    AccountModule,
    SharedModule.forRoot(),
    OrganisationModule,
    MenuModule,
    TableModule,
    OrderModule,
    RoutesModule,
    IntroModule,
    OptionModule,
    StatusModule,
    ClientModule,
    SettingsModule,
    CategoryModule,
    PropertyModule,
    DiscountModule,
    AdditionalModule,
    IngredientModule,
    NotificationModule,
    LogModule,
    RequestModule,
    ConnectionServiceModule,
    NgxMaterialTimepickerModule,
    IMaskModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: AppService, useValue: appService },
    { provide: AppStore, useValue: appStore },
    { provide: LOCALE_ID, useValue: "hy" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpListener,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
