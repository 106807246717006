import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
//components
import { ClientShellComponent } from './client-shell.component';
import { ClientListComponent } from './client/list/list.component';
import { ClientEditComponent } from './client/edit/edit.component';

export const client = [
    {
      path: "list",
      component: ClientListComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    },
    {
      path: "add",
      component: ClientEditComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    },
    {
      path: "edit/:id",
      component: ClientEditComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    }
]
