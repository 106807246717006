import { Component, OnInit } from "@angular/core";
import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
@Component({
    template: `
    <a routerLink="/settings/privacy/list" class="btn btn-info btn-round mb-2 mr-2">
    <i class="fas fa-bars"></i>
    <span> {{'settigns.PRIVACYPOLICY'|translate}}</span>
    </a>
    <a routerLink="/settings/about-us/list" class="btn btn-info btn-round mb-2 mr-2">
    <i class="fas fa-bars"></i>
    <span> {{'settigns.ABOUTUS'|translate}}</span>
    </a>
    <a routerLink="/settings/terms-of-use/list" class="btn btn-info btn-round mb-2 mr-2">
    <i class="fas fa-bars"></i>
    <span> {{'settigns.TERMSOFUSE'|translate}}</span>
    </a>
    <router-outlet></router-outlet>
    `
})
export class SettingsShellComponent extends AuthenticatedComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit() { }

}
