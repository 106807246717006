import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { DataStore } from '../shared/models/DataStore';
import { MenuStore } from './menu.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';
import { AgmCoreModule } from '@agm/core';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import { ImageCropperModule } from 'ngx-image-cropper';

const CustomSelectOptions: any = { // Check the interface for more options
    optionValueField: 'id',
    optionTextField: 'name'
};


import { OptionModule } from '../option.module/option.module';
import { MenuService } from './services/menu.service';
import { SectionService } from './services/menu.service';
import { MenuItemService } from './services/menu.service';
import { MenuShellComponent } from './menu-shell.component';
import { MenuListComponent } from './menu/list/list.component';
import { MenuEditComponent } from './menu/edit/edit.component';
import { MenuItemComponent } from './menu/edit/menu-item/menu-item.component';
import { SectionsComponent } from './menu/edit/sections/sections.component';
import { MenuComponent } from './menu/edit/menu/menu.component';
import { SectionModalComponent } from './menu/edit/sections/section-modal/section-modal.component';
import { MenuItemModalComponent } from './menu/edit/menu-item/menu-item-modal/menu-item-modal.component';
import {FormsModule} from '../routes/forms/forms.module';
import {SortingModalComponent} from './menu/edit/sections/sorting-modal/sorting-modal.component';
import {DndModule, DragDropConfig, DragDropService, DragDropSortableService} from 'ng2-dnd';
import {SortingSectionsModalComponent} from './menu/edit/menu/sortingSections-modal/sortingSections-modal.component';
import {SortingMenusModalComponent} from './menu/list/sortingMenus-modal/sortingMenus-modal.component';
import {ImportSectionsModalComponent} from './menu/edit/sections/importSections-modal/importSections-modal.component';
import {ImportMenuItemsModalComponent} from './menu/edit/sections/importMenuItems-modal/importMenuItems-modal.component';
import {ImportMenusModalComponent} from './menu/list/importMenus-modal/importMenus-modal.component';
import {ImportOptionsModalComponent} from './menu/edit/menu-item/importOptions-modal/importOptions-modal.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    NgxSelectModule.forRoot(CustomSelectOptions),
    OptionModule,
    FormsModule,
    ImageCropperModule,
    DndModule
  ],
  declarations: [
    MenuShellComponent,
    MenuListComponent,
    MenuEditComponent,
    MenuItemComponent,
    SectionsComponent,
    MenuComponent,
    SectionModalComponent,
    SortingModalComponent,
    SortingSectionsModalComponent,
    MenuItemModalComponent,
    SortingMenusModalComponent,
    ImportSectionsModalComponent,
    ImportMenuItemsModalComponent,
    ImportMenusModalComponent,
    ImportOptionsModalComponent
  ],

  exports: [
    MenuListComponent,
  ],
  entryComponents: [
SectionModalComponent,
MenuItemModalComponent,
SortingModalComponent,
SortingSectionsModalComponent,
SortingMenusModalComponent,
ImportSectionsModalComponent,
ImportMenuItemsModalComponent,
ImportMenusModalComponent,
ImportOptionsModalComponent
],

  providers: [MenuService, SectionService, MenuItemService, DragDropService, DragDropConfig, DragDropSortableService]
})
export class MenuModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
        ...services.menuService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    const appinjector = appInjector.injector();
    const store = appinjector.get(AppStore);
    const menuService = appinjector.get(MenuService);

    return {
      store,
      menuService,
    };
  }

  static initializeModuleData() {
    const injector = appInjector.injector();
    const store = injector.get(AppStore);
    MenuStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    MenuStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.menuService.preloadData();
  }

}
