import { Injectable } from "@angular/core";
import { DataStore } from "../shared/models/DataStore";

@Injectable()
export class TableStore extends DataStore {
    constructor() {
        super();
    }

    public initializeStore() {
        this.set('table', null);
        this.set('tablegroup', null);
    }
}