import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { TableModel } from '../../models/TableModel';
import { KeyValuePair } from '../../../models/KeyValuePair';
//services
import { TablesService } from "../../services/tables.service";
import { PlaceService } from "../../../place.module/services/place.service";
import { TablesGroupesService } from "../../services/tableGroupes.service";
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import QRCodeStyling from "qr-code-styling";
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class TableEditComponent extends AuthenticatedComponent implements OnInit {

  constructor(
    private placeService: PlaceService,
    private activeRoute: ActivatedRoute,
    private tablesService: TablesService,
    private tgroupService: TablesGroupesService) {
    super();
  }

  @ViewChild('pRef', {static: false}) pRef
  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  public table: TableModel = new TableModel();
  public placeList: Array<KeyValuePair> = [];
  public tableGroupList: Array<KeyValuePair> = [];
  public elementType: 'url' | 'canvas' | 'img' = 'url';
  public href: string = '';
  public placeId: string = null;
  public typelist: Array<KeyValuePair> = [
    new KeyValuePair(0, this.translate.instant("table.Withoutservice")),
    new KeyValuePair(1, this.translate.instant("table.Withservice"))
  ];

  place:string=null;
  clicked:boolean=false;
  qrCode:any=null;

  ngOnInit() {

    this.placeId = this.activeRoute.snapshot.params['placeId'];

    if ( !!this.placeId ) {
      this.table.placeId = Number(this.placeId);

      this.placeService.get(this.placeId).subscribe(res => {
        this.place = res.name;
      });
    }

    this.placeService.getAllSimplified().subscribe(res => {
      this.placeList = res.map(k => new KeyValuePair(k.id, k.name));
    });

    this.getTableGroupsByPlace();
    if (this.isEdit) {
      let tableId = this.activeRoute.snapshot.params['id'];
      this.tablesService.get(tableId).subscribe(res => {
        if (!!res) {
          this.table = res;
          this.showqr();
        }else {
          this.alertService.showError('Something went wrong!');
          this.back();
        }
      });
    }
  }
  showqr() {
    this.qrCode = new QRCodeStyling({
      width: 300,
      height: 300,
      type: 'svg',
      data: this.table.guId,
     // image: 'https://inmenu.am/assets/image/Asset.png',
      margin: 30,
      qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'Q'
      },
      /*imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.5,
        margin: 1,
        crossOrigin: 'anonymous',
      },*/
      dotsOptions: {
        color: '#000000',
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 0,
        //   colorStops: [{ offset: 0, color: '#8688B2' }, { offset: 1, color: '#77779C' }]
        // },
        type: 'square'
      },
      backgroundOptions: {
        color: '#00000000',
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 0,
        //   colorStops: [{ offset: 0, color: '#ededff' }, { offset: 1, color: '#e6e7ff' }]
        // },
      },
      cornersSquareOptions: {
        color: '#000000',
        type:"extra-rounded",
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 180,
        //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
        // },
      },
      cornersDotOptions: {
        color: '#000000',
        type: 'dot',
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 180,
        //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
        // },
      }
    });
    this.qrCode.applyExtension((svg,options)=>
    {
      const { width, height } = options;
      const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
      text.innerHTML = this.table.name+" "+this.table.number;

      const textAttributes={
        "x": width / 2,
        "y": height - 10,
        "font-size":"105%",
        "fill":"#ffffff",
        "text-anchor":"middle"
      }
      Object.keys(textAttributes).forEach(attribute => {
        text.setAttribute(attribute, textAttributes[attribute]);
      });
      svg.appendChild(text);
    });
    this.qrCode.append(this.canvas.nativeElement);
  }
  public getTableGroupsByPlace() {

    if(!!this.table.placeId) {

      this.tgroupService.getAllForPlace(this.table.placeId).subscribe(res => {
        this.tableGroupList = res.map(k => new KeyValuePair(k.id, k.name));
      })
    }
  }

  public get isEdit() {
    return this.activeRoute.snapshot.url.join('').includes("edit");
  }

  public isFormValid() {

    if (!super.isFormValid()) {
      this.alertService.showError('The form is not valid.');
      return false;
    }
    return true;
  }

  public save() {

    if (!this.isFormValid()) return;

    this.table.tableGroupId = (this.table.tableGroupId != 0) ? this.table.tableGroupId : undefined;
    if (!this.table.id ) {
      this.clicked=true;
      this.tablesService.save(this.table).subscribe(data => {
        this.clicked=false;
        if(!data.hasError) {
          this.alertService.showSuccess('Table add successfully.');
          this.tablesService.updateData({id:data.data}).subscribe(res => {
            //this.placeService.loadData().subscribe();
            this.back();
          })
        } else {
          let errors = data.errors.map(r => {return r.message});
          this.alertService.showError(errors);
        }
      });

    } else {

      this.table.place = null;
      this.table.tableGroup = null;
      this.clicked=true;
      this.tablesService.save(this.table).subscribe(data => {
        this.clicked=false;
        if(!data.hasError) {
          this.alertService.showSuccess('Table updated successfully.');
          this.tablesService.updateData({id:this.table.id}).subscribe(res => {
            //this.placeService.loadData().subscribe();
            this.back();
          })
        } else {
          let errors = data.errors.map(r => {return r.message});
          this.alertService.showError(errors);
        }
      });
    }
  }

  public download(extension) {
    this.qrCode.download({name:this.table.place.name+'_'+this.table.name+'_'+this.table.number,extension:extension});
  }
}
