import { Component, OnInit, Input , OnChanges } from '@angular/core';
//models
import {TableModel} from '../../models/TableModel';
//services
import { TablesService } from '../../services/tables.service';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {MenuInsertModel, MenuModel} from "../../../menu.module/models/MenuModel";
@Component({
	selector: 'tables-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class TableListComponent extends AuthenticatedComponent {

	constructor(

		private tablesService: TablesService) {
		super();
	}

	@Input() placeId: number = null;
	@Input() tables: Array<TableModel> = [];
	public tableList: Array<TableModel> = [];
	public data: Array<TableModel> = [];

	public columns: Array<any> = [

				{ title: 'id', name: 'id' },
				{ title: 'name', name: 'name' },
				{ title: 'number', name: 'number' },
				{ title: 'table Group', name: 'table Group' },
				{ title: 'settings', name: 'settings' },

				];


	ngOnInit() {}


  public save(table: TableModel ) {

    table.place=null;
    table.tableGroup=null;
		this.tablesService.save(table).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.TABLEUPDATEDSUCCESFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
    window.location.reload();
	}
}
