import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { delay, catchError, onErrorResumeNext, switchMap, concatAll, tap, mergeAll, map, mergeMap, finalize, first } from "rxjs/operators";
import { of, merge, Observable, Subject, concat, forkJoin } from "rxjs";
//services
import {TranslateService} from "@ngx-translate/core";
import { AppService } from "../app.service";
//components
import { BaseComponent } from "../shared/components/BaseComponent";
import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";

@Component({
  templateUrl: "initialize-app.component.html",
  styleUrls: ['initialize-app.component.scss']
})
export class InitializeAppComponent extends AuthenticatedComponent {

  constructor(
    private appService: AppService,
    protected translate:TranslateService,
    private route: ActivatedRoute) {
    super();
  }

  timeout = null;
  ngOnInit() {
    this.appService.clearModulesCachedData();

    this.loadIndependentData(() => {
      this.finished();
    });

    this.timeout = setTimeout(() => {
      this.finished()
    }, 10000);

    this.makeContinueLoading();
  }

  loadIndependentData(done) {
    let requests$ = this.observablesFrom(this.appService.dependentData());
    this.executeRequests(requests$, true)
      .subscribe(d => this.responseArrived(d), err => { this.responseArrived(null, err) }, () => done())
  }

  observablesFrom(data) {
    let requests$ = [];
    Object.keys(data).forEach(k => {
      this.requestsNumber += data[k].resources.length;
      let moduleRequest$ = data[k].resources.map(o => {
        o.message = k;
        return o;
      });
      requests$ = [...requests$, ...moduleRequest$];
    })
    return requests$;
  }

  loadingPercent = 0;
  private requestsNumber = 0;
  private doneRequests = 0;

  makeContinueLoading() {
    const interval = setInterval(() => {
      if (this.loadingPercent > 90) clearInterval(interval);
      this.loadingPercent++;
    }, 350);
  }

  public get doneLoading() {
    return this.doneRequests >= this.requestsNumber;
  }

  responseArrived(val, err = null) {
    this.doneRequests++;
    this.doneRequests = this.doneLoading ? this.requestsNumber : this.doneRequests;
    this.loadingPercent = Math.floor(100 / this.requestsNumber * this.doneRequests)
    //console.log("responseArrived", val || err, this.doneRequests, this.loadingPercent)
    //this.checkFinished();
  }

  finished() {
    // this.translate.use("hy");
    // console.log(this.translate.currentLang);
    let returnurl=this.route.snapshot.queryParamMap.get('returnurl')||"/order/list";

    clearTimeout(this.timeout);
    //if (this.doneLoading)
    {
      // set app initialized
      this.store.set("app.initialized", true);
      this.appService.handleReloadData();
      //this.goToUrl('/order/list');
      //this.goToUrl(returnurl);
      this.router.navigate([returnurl]);
    }
  }

  executeRequests(observablesArray: Array<Observable<any>> = [], inOrder = false) {
    if (observablesArray.length > 0) {
      //observablesArray = observablesArray.map(o=> o.pipe(first()));
      if (inOrder) return concat(...observablesArray);
      return merge(...observablesArray);
    }
    else {
      return of({ res: 'No data' });
    }
  }
}
