import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";
import { ClientService } from "./services/client.service";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class ClientShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private clientService: ClientService) {
        super();
    }

    ngOnInit() {
    }
}