import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { KeyValuePair } from '../../../models/KeyValuePair';
import { MeasureUnitModel } from '../../models/MeasureUnitModel';
//services
import { MeasureUnitService } from "../../services/MeasureUnitService";
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class MeasureunitEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private activeRoute: ActivatedRoute,
		private measureunitService: MeasureUnitService) {
		super();
	}

	public measureunit: MeasureUnitModel = new MeasureUnitModel();
  clicked:boolean=false;


	ngOnInit() {
		if (this.isEdit) {
			let mesId = this.activeRoute.snapshot.params['id'];
			this.measureunitService.get(mesId).subscribe(res => {
				if (!!res) {
					this.measureunit = res;
				}else {
					this.alertService.showError('Something went wrong!');
					this.back();
				}
			});
		}


	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public isFormValid() {

		if (!super.isFormValid()) {
			this.alertService.showError('The form is not valid.');
			return false;
		}
		return true;
	}

	// public back() {
	// 	this.router.navigateByUrl("ingredient/list");
	// }

	public save() {

		if (!this.isFormValid()) return;

		if (!this.measureunit.id ) {
      this.clicked=true;
			this.measureunitService.save(this.measureunit).subscribe(data => {
			  this.clicked=false;
				if(!data.hasError) {
					this.alertService.showSuccess('Measureunit add successfully.');
					this.measureunitService.updateData({id:data.data}).subscribe(res => {
						this.back();
					})
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});

		} else {
      this.clicked=true;
			this.measureunitService.save(this.measureunit).subscribe(data => {
        this.clicked=false;
        if(!data.hasError) {
					this.alertService.showSuccess('Measureunit updated successfully.');
					this.measureunitService.updateData({id:this.measureunit.id}).subscribe(res => {
						this.back();
					})
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});
		}
	}

}
