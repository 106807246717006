import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models

//services

//component
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
import {MenuItemOrderRefGroupModel, MenuItemOrderRefModel} from "../../../models/OrderModel";
import {number} from "ng2-validation/dist/number";
@Component({
	selector: 'app-section-modal',
	templateUrl: './Statushistory-modal.component.html',
	styleUrls: ['./Statushistory-modal.component.scss']
})

export class StatusHistoryModalComponent extends AuthenticatedComponent implements OnInit {

	constructor(){
		super();
	}
	public amount:number=0;
	@Input() ref: MenuItemOrderRefGroupModel = new MenuItemOrderRefGroupModel();
  @Input() currency: string;
	ngOnInit() {
	}





}
