import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { AuthorizeGuard } from '../shared/services/security/authorizeGuard';
// components
import { MenuShellComponent } from './menu-shell.component';
import { MenuListComponent } from './menu/list/list.component';
import { MenuEditComponent } from './menu/edit/edit.component';
import { MenuItemModalComponent } from './menu/edit/menu-item/menu-item-modal/menu-item-modal.component';

export const menus = [
     {
        path: 'list',
        component: MenuListComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: 'add',
        component: MenuEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: 'edit/:id',
        component: MenuEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: 'menu-item/add/:menuId',
        component: MenuItemModalComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: 'menu-item/edit/:id',
        component: MenuItemModalComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: 'add/:placeId',
        component: MenuEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: 'edit/:id/:placeId',
        component: MenuEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      }
];
