import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
//components
import { DiscountShellComponent } from './discount-shell.component';
import { DiscountListComponent } from './discount/list/list.component';
import { DiscountEditComponent } from './discount/edit/edit.component';

export const discount = [
    {
      path: "list",
      component: DiscountListComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    },
    {
      path: "add",
      component: DiscountEditComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    },
    {
      path: "edit/:id",
      component: DiscountEditComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    },
    {
    path: "add/:placeId",
    component: DiscountEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "edit/:id/:placeId",
    component: DiscountEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
]
