import { DeclineOrderModalComponent } from './order/edit/Declineorder-modal/Declineorder-modal.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { DataStore } from '../shared/models/DataStore';
import { OrderStore } from './order.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';
import { OrderShellComponent } from './order-shell.component';
import {StatusHistoryModalComponent} from './order/edit/statushistory-modal/Statushistory-modal.component';
import { OrderService } from './services/order.service';
import { OrderListComponent } from './order/list/list.component';
import { OrderEditComponent } from './order/edit/edit.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        SharedModule,
        RouterModule,
        NgxSkeletonLoaderModule
    ],
  declarations: [
    OrderShellComponent,
    OrderListComponent,
    OrderEditComponent,
    StatusHistoryModalComponent,
    DeclineOrderModalComponent
  ],

  exports: [],
  entryComponents: [StatusHistoryModalComponent, DeclineOrderModalComponent],

  providers: [ OrderService ]
})
export class OrderModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
        ...services.orderService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    const appinjector = appInjector.injector();
    const store = appinjector.get(AppStore);
    const orderService = appinjector.get(OrderService);

    return {
      store,
      orderService,
    };
  }

  static initializeModuleData() {
    const injector = appInjector.injector();
    const store = injector.get(AppStore);
    OrderStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    OrderStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.orderService.preloadData();
  }

}
