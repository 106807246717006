import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// models
import { KeyValuePair } from '../../../models/KeyValuePair';
import { LogModel } from '../../models/LogModel';
// services
import { LogService } from '../../services/log.service';
// components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
@Component({
  selector: "status-edit",
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class LogEditComponent extends AuthenticatedComponent implements OnInit {
  constructor(
    private activeRoute: ActivatedRoute,
    private logService: LogService
  ) {
    super();
  }

  public log: LogModel = new LogModel();
  clicked = false;

  ngOnInit() {
    const logId = this.activeRoute.snapshot.params.id;
    this.logService.get(logId).subscribe((res) => {
      if (!!res) {
        this.log = res;
      } else {
        this.alertService.showError('Something went wrong!');
        this.back();
      }
    });
  }

  // public back() {
  // 	this.router.navigateByUrl("log/list");
  // }
  public toggle() {
    this.logService
      .toggle(this.log.id, this.log.hasResolved)
      .subscribe((data) => {
        if (!data.hasError) {
          this.alertService.showSuccess('Log updated successfully.');
        } else {
          const errors = data.errors.map((r) => {
            return r.message;
          });
          this.alertService.showError(errors);
        }
      });
  }
}
