import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { SharedModule } from '../shared/shared.module';
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { TableStore } from './tables.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';

import { TablesService } from './services/tables.service';
import { TablesGroupesService } from './services/tableGroupes.service';
import { TablesShellComponent } from './table-shell.component';
import { TableListComponent } from './tables/list/list.component';
import { TableEditComponent } from './tables/edit/edit.component';
import { TableGropesListComponent } from './table-groupes/list/list.component';
import { TableGropesEditComponent } from './table-groupes/edit/edit.component';

@NgModule({
  imports: [
  CommonModule,
  BrowserModule,
  SharedModule,
  RouterModule,
  NgxQRCodeModule,
  ],
  declarations: [
  TablesShellComponent,
  TableListComponent,
  TableEditComponent,
  TableGropesListComponent,
  TableGropesEditComponent,
  ],

  exports: [
  TableListComponent,
  TableEditComponent,
  TableGropesListComponent,
  TableGropesEditComponent,
  ],
  entryComponents: [],

  providers: [ TablesService, TablesGroupesService ]
})
export class TableModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
      // ...services.tablesService.preloadData(),
      // ...services.tgroupesService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    const appinjector = appInjector.injector();
    const store = appinjector.get(AppStore);
    const tablesService = appinjector.get(TablesService);
    const tgroupesService = appinjector.get(TablesGroupesService);

    return {
      store,
      tablesService,
      tgroupesService
    };
  }

  static initializeModuleData() {
    const injector = appInjector.injector();
    const store = injector.get(AppStore);
    TableStore.prototype.initializeStore.call(store);
  //  this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    TableStore.prototype.initializeStore.call(store);
  }

  // public static loadData() {
  //   const services = this.moduleServices();
  //   // services.tablesService.preloadData();
  //   // services.tgroupesService.preloadData();
  // }

}
