import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models

//services

//component
import { AuthenticatedComponent} from "../../../../shared/components/AuthenticatedComponent";
import {number} from "ng2-validation/dist/number";
import {MenuService} from "../../../services/menu.service";
import {BsModalRef} from "ngx-bootstrap";
@Component({
	selector: 'app-section-modal',
	templateUrl: './sortingMenus-modal.component.html',
	styleUrls: ['./sortingMenus-modal.component.scss']
})

export class SortingMenusModalComponent extends AuthenticatedComponent implements OnInit {

	constructor(public menuService:MenuService,public bsModalRef:BsModalRef){
		super();
	}
  @Input() placeId: number;
	sortList:Array<any>;
	clicked:boolean=false;
	ngOnInit() {
      this.menuService.getSortmenu(this.placeId).subscribe(data=>
      {
            this.sortList= data.sort((a, b) => a.sortOrder - b.sortOrder);
      })
	}
  public save()
  {
    for (let i=0;i<this.sortList.length;i++)
    {
      this.sortList[i].sortOrder=i+1;
    }
    this.clicked=true;
    this.menuService.UpdateSortmenu(this.sortList).subscribe(d => {
      this.clicked=false;
      if(!d.hasError) {
          this.alertService.showSuccess(this.translate.instant("alerts.SORTORDERUPDATEDSUCCESSFULLY"));
          this.bsModalRef.hide();
        }
       else {
        let errors = d.errors.map(r => {return r.message});
        this.alertService.showError(errors);
      }
    });
  }






}
