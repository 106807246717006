import { EventEmitter, OnInit } from '@angular/core';
import { Component, Input, forwardRef, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.css'],
})
export class UploadComponent implements OnInit {
    @Input() label: string;
    @Input() disabled: boolean;
    @Input() required: boolean;
    @Input() multiple: boolean = false;
    @Input() fileUrl: string;
    @Output() fileChanged = new EventEmitter();
    @ViewChild('fileInput', {static: false}) fileInput: any;

    constructor() {
    }

    ngOnInit() {
    }

    fileChangeEvent(event) {
        this.fileUrl = event.target.value;
        let fi = event.srcElement;
        if (fi.files && fi.files[0]) {
            let fileToUpload = fi.files;
            this.fileChanged.emit(fileToUpload);
        }
    }

    removeFile() {
        this.fileInput.nativeElement.value = "";
        this.fileUrl = "";
    }
}
