import { AlertsService } from './../../../shared/services/alerts.service';
import { AccountService } from './../../../account.module/services/account.service';
import { AuthenticationService } from './../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { KeyValuePair } from 'src/app/models/KeyValuePair';
import { PublicComponent } from 'src/app/shared/components/PublicComponent';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends PublicComponent implements OnInit {
  countries;
  code;
  phoneCode = '+374';
  valForm: FormGroup;
  passwordForm: FormGroup;
  genders: Array<KeyValuePair> = [
    new KeyValuePair(0, 'male'),
    new KeyValuePair(1, 'female'),
    new KeyValuePair(2, 'other'),
  ];
  constructor(
    public settings: SettingsService,
    fb: FormBuilder,
    private authService: AuthenticationService,
    private alertsService: AlertsService
  ) {
    super();
    const password = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$'),
      ])
    );
    const certainPassword = new FormControl('', [
      Validators.required,
      CustomValidators.equalTo(password),
    ]);

    this.valForm = fb.group({
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email]),
      ],
      phoneNumber: [null, Validators.required],
      countryId: [1366, Validators.required],
      gender: ['', Validators.required],
      birthDate: [new Date(), Validators.required],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      accountagreed: [null, Validators.required],
      password,
      confirmPassword: certainPassword,
    });
  }

  submitForm(value: any) {
    this.valForm.value.phoneNumber =
      this.phoneCode + this.valForm.value.phoneNumber;
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.authService
        .code(this.valForm.value.phoneNumber, false)
        .subscribe((data) => {
          if (!data.data) {
            const errors = data.errors.map((r) => r.message);
            this.alertsService.showError(errors);
          } else {
            document.getElementById('reg').hidden = true;
            document.getElementById('code').hidden = false;
            setTimeout(this.enable, 30000);
          }
        });
      // this.authService.register(this.valForm.value).subscribe(data=>{

      // })
    }
  }
  enable() {
    const element = document.getElementById('resesnd') as HTMLInputElement;
    element.hidden = false;
    const element1 = document.getElementById('resendlabel') as HTMLInputElement;
    element1.hidden = true;
  }
  sendCode() {
    this.authService.code(this.valForm.value.phoneNumber, false).subscribe((data) => {
      if (!data.data) {
        const errors = data.errors.map((r) => r.message);
        this.alertsService.showError(errors);
      } else {
        const element = document.getElementById('resesnd') as HTMLInputElement;
        element.hidden = true;
        const element1 = document.getElementById('resendlabel') as HTMLInputElement;
        element1.hidden = false;

        setTimeout(this.enable, 30000);
      }
    });
  }

  verify() {
    this.authService
      .verify(this.valForm.value.phoneNumber, this.code, false)
      .subscribe((data) => {
        if (!data.data) {
          const errors = data.errors.map((r) => r.message);
          this.alertsService.showError(errors);
        } else {
          this.authService.register(this.valForm.value).subscribe((data) => {
            if (!data.data) {
              const errors = data.errors.map((r) => r.message);
              this.alertsService.showError(errors);
            } else {
              this.alertsService.showSuccess(
                // tslint:disable-next-line: quotemark
                this.translate.instant("alerts.LOGINWITHSUCCESFULLY")
              );
              this.goToUrl('/order/list/');
            }
          });
        }
      });
  }
  change(id, numberCode) {
    this.phoneCode = numberCode;
    const element = document.getElementById('country') as HTMLInputElement;
    element.value = id;
    this.valForm.value.countryId = id;
  }

  ngOnInit() {
    this.authService.Countries().subscribe((data) => {
      this.countries = data;
    });
  }
}
