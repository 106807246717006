import { Component, OnInit } from '@angular/core';
//services
import { OrganisationService } from "../../services/organisation.service";
import { ModalsService } from '../../../bootstrap-components.module/modals/modals.service';
//models
import { OrganisationModel } from '../../models/OrganisationModel';
//componts
import { BaseComponent } from '../../../shared/components/BaseComponent';
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';

@Component({
	selector: 'organisation-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class OrganisationListComponent extends AuthenticatedComponent implements OnInit {

	constructor(
        private modalService: ModalsService,
		private organisationService: OrganisationService) {
		super();
	}

	public organisationList: Array<OrganisationModel> = [];
    public data: Array<OrganisationModel> = [];

    public columns: Array<any> = [

        { title: 'ID', name: 'id',sort: 'asc' },
        { title: 'logo', name: 'logo' },
        { title: 'Name', name: 'name', filtering: { filterString: '', placeholder: 'Filter by name' }, sort: 'asc' },
        { title: 'settings', name: 'settings' },

    ];

	ngOnInit() {
		this.organisationService.getAll().subscribe(data => {
			this.organisationList = data;
		});
	}

    public save(organisation: OrganisationModel ) {
        this.organisationService.save(organisation).subscribe(data => {
            if(!data.hasError ) {
                this.alertService.showSuccess('Organisation updated successfully.');
            } else {
                let errors = data.errors.map(r => {return r.message});
                this.alertService.showError(errors);
            }
        })
    }
}
