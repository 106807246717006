import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//services
import { PlaceService } from "../../../services/place.service";
//models
import { KeyValuePair } from '../../../../models/KeyValuePair';
import { PlaceModel } from '../../../models/PlaceModel';
//components
import { BaseComponent } from '../../../../shared/components/BaseComponent';
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
@Component({
  selector: 'app-place-settings',
  templateUrl: './place-settings.component.html',
  styleUrls: ['./place-settings.component.scss']
})
export class PlaceSettingsComponent extends AuthenticatedComponent implements OnInit {

  constructor(
    public placeService: PlaceService,
    private activeRoute: ActivatedRoute) {
    super();
  }

  @Input() place: PlaceModel = new PlaceModel();
  placeList:any = [];

  ngOnInit() {

    if(this.place) {

      this.placeService.getAllSimplified().subscribe(res => {
      // this.clientList = res.map(k => new ListClass(k.id, k.email));

        this.placeList = res.filter(i=> i.id != this.place.id)
      });
    }

  }

  public get isEdit() {
    return this.activeRoute.snapshot.url.join('').includes("edit");
  }

  public placeListChange(event) {
    this.place.childrenIds = event.map(i => i.key);
  }

}
