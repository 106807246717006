import { ImageModel } from '../../models/ImageModel';

export class AdditionalInfoModel{
	id: number;
	name: string;
	imageIds: Array<number> =[];
	logoId: number;
	coverId: number;
	logoUrl: ImageModel = new ImageModel();
	coverUrl: ImageModel = new ImageModel();
	imageUrls: Array<ImageModel> = [];
	nameTranslationId: number = 0;
	isActive: boolean = true;
}

export class AdditionalInfoInsertModel{
	id: number;
	name: string;
	imageIds: Array<number> =[];
	logoId: number;
	coverId: number;
	nameTranslationId: number = 0;
	isActive: boolean = true;
}