import { Component, OnInit, Input , OnChanges} from '@angular/core';
//models
import { TableGroupModel } from '../../models/TableGroupModel';
//services
import { TablesGroupesService } from '../../services/tableGroupes.service';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'table-groupes-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class TableGropesListComponent extends AuthenticatedComponent {

	constructor(
    private tgroupesService: TablesGroupesService,
    protected translate: TranslateService
  ) {
		super();
	}

	@Input() placeId: number = null;
	@Input() tablegroups: Array<TableGroupModel> = [];
	public tableGroupList: Array<TableGroupModel> = [];
	public data: Array<TableGroupModel> = [];

	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'name', name: 'name' },
	{ title: 'place', name: 'place' },
	{ title: 'settings', name: 'settings' },

	];

	

	public save(table: TableGroupModel ) {
	  table.place=null;
		this.tgroupesService.save(table).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.TABLEGOUPUPDATEDSUCCESSFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}
}
