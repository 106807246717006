import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OrganisationModel } from '../models/OrganisationModel';

@Injectable()
export class OrganisationService extends CrudService<OrganisationModel> {

    protected serviceItemsKey = 'organisation';

    constructor(protected store: AppStore) {
        super('Organisation', null);
    }

    public preloadData() {
        return [
            this.getAllOrganisations(),
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

    public getAllOrganisations() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetAll'))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

    public getAllOrganisationalAsync() {
        return this.apirequest('get', this.apiCallTo('Role/GetAllOrganisational'))
            .pipe(
                map(i => i.data),
                // tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

    public getAllTransactions(orgId: number) {
        return this.apirequest('get', this.apiCallTo(`Transaction/GetAll?orgId=${orgId}`))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set('transaction', d))
            );
    }
}