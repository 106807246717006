import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";
import { OrderService } from "./services/order.service";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class OrderShellComponent extends AuthenticatedComponent implements OnInit {

    constructor(
        private orderService: OrderService) {
        super();
    }

    ngOnInit() {
        // if (!this.moduleDataLoaded) {
        //     this.orderService.loadData().subscribe(
        //         r => {
        //             this.alertService.showInfo(`Options data has been loaded!`);
        //             this.goToUrl('/option/list');
        //         },
        //         e => {
        //             this.alertService.showError(`Loading Options data had encounter error.`);
        //             this.goToUrl('/dashboard');
        //         });
        // } else {
        //     this.alertService.showInfo(`Options data has been loaded!`);
        //     this.goToUrl('/option/list');
        // }
    }

    get moduleDataLoaded() {
        const storeKeysToVerify = ['order'];
        return storeKeysToVerify.map(k => !!this.store._(k)).
            reduce((acc, val) => {
                return acc && val;
            }, true)
    }

}
