export class AddressLocationModel {
id: number;
formattedAddress: string;
postalCode:	string;
line1: string;
line2: string;
latitude: number;
longitude: number;
countryLongName: string;
countryShortName: string;
cityShortName: string;
isActive: boolean;
}
