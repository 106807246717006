import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { KeyValuePair } from '../../../models/KeyValuePair';
import { StatusModel } from '../../models/StatusModel';
//services
import { StatusService } from "../../services/status.service";
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
@Component({
  selector: 'status-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class StatusEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private activeRoute: ActivatedRoute,
		private statusService: StatusService) {
		super();
	}

	public status: StatusModel = new StatusModel();
  clicked:boolean=false;

	ngOnInit() {
		if (this.isEdit) {
			let statusId = this.activeRoute.snapshot.params['id'];
			this.statusService.get(statusId).subscribe(res => {
				if (!!res) {
					this.status = res;
				}else {
					this.alertService.showError('Something went wrong!');
					this.back();
				}
			});
		}
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public isFormValid() {

		if (!super.isFormValid()) {
			this.alertService.showError('The form is not valid.');
			return false;
		}
		return true;
	}

	// public back() {
	// 	this.router.navigateByUrl("log/list");
	// }

	public save() {

		if (!this.isFormValid()) return;
    this.clicked=true;
		this.statusService.save(this.status).subscribe(data => {
		  this.clicked=false;
			if(!data.hasError) {
				this.alertService.showSuccess('log updated successfully.');
				this.statusService.updateData({id:this.status.id}).subscribe(res => {
					this.back();
				})
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		});
	}
}
