import { Component, OnInit, Input , OnChanges } from '@angular/core';
//models
import { DiscountModel } from '../../models/DiscountModel';
//services
import { DiscountService } from '../../services/discount.service';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'discount-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class DiscountListComponent extends AuthenticatedComponent implements OnChanges {

	constructor(
		private discountService: DiscountService,
    protected translate:TranslateService) {
		super()
	}

	@Input() placeId: number = null;
	@Input() discounts: Array<DiscountModel> = [];
	public discountList: Array<DiscountModel> = [];
	public data: Array<DiscountModel> = [];

	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'username', name: 'username' },
	{ title: 'name', name: 'name' },
	{ title: 'percent', name: 'percent' },
    { title: 'startDate', name: 'start Date' },
    { title: 'expireDate', name: 'expire Date' },
	{ title: 'settings', name: 'settings' },

	];

	ngOnInit() {}
	ngOnChanges() {

		if(!this.placeId) {
			this.discountService.getAll().subscribe(data => {
				this.discountList = data;
			})
		}
	}

	public save(not: DiscountModel ) {
	  not.place=null;
	  not.userIds=[not.user.id];
	  not.user=null;
	  this.discountService.save(not).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.DISCOUNTUPDATEDSUCCESSFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
    window.location.reload();
	}
}
