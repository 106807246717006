import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { PlaceModel } from '../../../models/PlaceModel';
//services
import { PlaceService } from "../../../services/place.service";
//components
import { BaseComponent } from '../../../../shared/components/BaseComponent';
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
@Component({
  selector: 'app-place-images',
  templateUrl: './place-images.component.html',
  styleUrls: ['./place-images.component.scss']
})
export class PlaceImagesComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private activeRoute: ActivatedRoute,
		public placeService :PlaceService) {
		super(); 
	}

    @Input() place: PlaceModel = new PlaceModel();
    @Output() valueChange = new EventEmitter();

    ngOnInit() {

    }

    delete(url) {
      this.placeService.deleteFile(url).subscribe(res=> {
        if (res) {
          this.placeService.updateData({id:this.place.id}).subscribe(res => {
            this.place = res.data;
            this.alertService.showSuccess('The image is Deleted!');
          });
        }
      })
    }

    imageChange(key,value){
        this.valueChange.emit({key:key,value:value});
    }
}
