import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { NotificationService } from "../../notification.module/services/notfication.service";
import {StorageService} from "./storage.service";
import {AlertsService} from "./alerts.service";
import {notification} from "../../notification.module/notification.routes";
import {ToasterService} from "angular2-toaster/angular2-toaster";

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging,
    private notificationService: NotificationService,
    private storage:StorageService,
    private toasterService:ToasterService,
    private alertService:AlertsService) {
    this.angularFireMessaging.messaging.subscribe((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.storage.save(this.storage.Keys.DevToken,token);
        this.notificationService.setToken(token).subscribe(()=>{
          this.notificationService.setPrefferedLanguage(token,this.storage.get(this.storage.Keys.Languages)).subscribe();
        });
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload:{notification}) => {
      //this.alertService.showSuccess(payload.notification.title+payload.notification.body);
      this.toasterService.pop("success", payload.notification.title, payload.notification.body);
      this.currentMessage.next(payload);
    });
  }
}
