﻿import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AppStore } from '../store.service';

@Injectable()
export class RedirectToLoginGuard implements CanActivate {

    constructor(private router: Router,
        private appStore: AppStore
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let isAuthenticated = this.appStore._('account.isAuthenticated');
        if (isAuthenticated) {
            return true;
        }
        // if user is not authenticated redirect to login
        ////console.log('RedirectToLoginGuard - Not activate route',  this.sharedStore)
        this.router.navigate(['/login'] , { queryParams: { returnUrl: state.url } });
        return false;
    }
}