import { Injectable } from '@angular/core';
import { DataStore } from '../shared/models/DataStore';

@Injectable()
export class MenuStore extends DataStore {
    constructor() {
        super();
    }

    public initializeStore() {
        this.set('menu', null);
    }
}

@Injectable()
export class MenuDataStore extends DataStore {
    constructor() {
        super();
    }

    public initializeStore() {
        this.set('section', {});
        this.set('menuitem', {});
    }
}
