import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LogModel } from '../models/LogModel';

@Injectable()
export class LogService extends CrudService<LogModel> {

    protected serviceItemsKey = 'log';

    constructor(protected store: AppStore) {
        super('LogViewer', null);
    }

    public preloadData() {
        return [
            this.getAllLogs(1,10000),
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

    public getAllLogs(page,pagesize) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAll?page=${page}&pageSize=${pagesize}`))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }
    public toggle(id:number,hasResolved:boolean)
    {
      return this.apirequest("get",this.apiCallTo(this.serviceApiUrl + `/Resolve?id=${id}&state=${hasResolved}`))
        .pipe(
          map(i => i.data)
        );
    }

}
