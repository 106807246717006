import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { KeyValuePair } from '../../../models/KeyValuePair';
import { TableGroupModel } from '../../models/TableGroupModel';
//services
import { PlaceService } from "../../../place.module/services/place.service";
import { TablesGroupesService } from "../../services/tableGroupes.service";
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
	selector: 'app-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss']
})
export class TableGropesEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
    protected translate: TranslateService,
    private placeService: PlaceService,
		private activeRoute: ActivatedRoute,
		private tgroupService: TablesGroupesService) {
		super();
	}

	public tableGroup: TableGroupModel = new TableGroupModel();
	public placeList: Array<KeyValuePair> = [];
	public placeId: string = null;

  place:string=null;
  clicked:boolean=false;
  ngOnInit() {

		this.placeId = this.activeRoute.snapshot.params['placeId'];
console.log(this.tableGroup)
		if ( !!this.placeId ) {
			this.tableGroup.placeId = Number(this.placeId);
		}

    this.placeService.get(this.placeId).subscribe(res => {
      this.place = res.name;
    });

		if (this.isEdit) {
			let tableGroupId = this.activeRoute.snapshot.params['id'];
			this.tgroupService.get(tableGroupId).subscribe(res => {
				if (!!res) {
					this.tableGroup = res;
				}else {
					this.alertService.showError(this.translate.instant("alerts.SOMETHINGWENTWRONG"));
					this.back();
				}
			});
		}
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public isFormValid() {

		if (!super.isFormValid()) {
			this.alertService.showError(this.translate.instant("alerts.INVALID"));
			return false;
		}
		return true;
	}

	public save() {

		if (!this.isFormValid()) return;

		if (!this.tableGroup.id) {
      this.clicked=true;
			this.tgroupService.save(this.tableGroup).subscribe(data => {
			  this.clicked=false;

				if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.TABLEGOUPADDEDSUCCESSFULLY"));
					this.tgroupService.updateData({id:data.data}).subscribe(res => {
						//this.placeService.loadData().subscribe();
						this.back();
					})
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});
		} else {
			this.tableGroup.place = null;
      this.clicked=true;
      this.tgroupService.save(this.tableGroup).subscribe(data => {
        this.clicked=false;
        if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.TABLEGOUPUPDATEDSUCCESSFULLY"));
					this.tgroupService.updateData({id:this.tableGroup.id}).subscribe(res => {
						//this.placeService.loadData().subscribe();
						this.back();
					})
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});
		}
	}
}
