import { UserProfileModel } from './../../account.module/models/UserProfileModel';
import { PlaceModel } from '../../place.module/models/PlaceModel';

export class DiscountModel{
	id: number;
	userIds: Array<any> = [];
	placeId: number;
	percent: number;
	type: number = 1;
	startDate: Date = new Date();
	expireDate: Date = new Date();
	user:UserProfileModel = new UserProfileModel(); 
	place:PlaceModel = new PlaceModel();
	isActive: boolean = true;
}