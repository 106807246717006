import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit } from '@angular/core';
import { concat, merge, Observable, of } from 'rxjs';
import { AppService } from '../app.service';
import { MessagingService } from '../shared/services/messaging.service';
import {AppStore} from "../shared/services/store.service";

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit{
message;
    constructor(private appStore:AppStore,private messagingService: MessagingService,
        private appService: AppService) { }

    ngOnInit() {
       // this.loadIndependentData();
        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();
        this.message = this.messagingService.currentMessage;
    }
//     loadIndependentData() {
//         let requests$ = this.observablesFrom(this.appService.dependentData());
//         this.executeRequests(requests$, true)
//           .subscribe(()=>{this.appService.handleReloadData();});
//       }
    
//       observablesFrom(data) {
//         let requests$ = [];
//         Object.keys(data).forEach(k => {
//           this.requestsNumber += data[k].resources.length;
//           let moduleRequest$ = data[k].resources.map(o => {
//             o.message = k;
//             return o;
//           });
//           requests$ = [...requests$, ...moduleRequest$];
//         })
//         return requests$;
//       }
    
   
//       private requestsNumber = 0;
//       private doneRequests = 0;
    
//   executeRequests(observablesArray: Array<Observable<any>> = [], inOrder = false) {
//     if (observablesArray.length > 0) {
//       //observablesArray = observablesArray.map(o=> o.pipe(first()));
//       if (inOrder) return concat(...observablesArray);
//       return merge(...observablesArray);
//     }
//     else {
//       return of({ res: 'No data' });
//     }
//   }

    

    

}
