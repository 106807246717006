import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { NotificationStore } from './notification.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex'

const CustomSelectOptions: any = { // Check the interface for more options
    optionValueField: 'id',
    optionTextField: 'name'
};

import { NotificationService } from "./services/notfication.service";
import { NotificationRoleService } from "./services/notfication.service";
import { RoleService } from "./services/notfication.service";
import { NotificationShellComponent } from './notification-shell.component';
import { NotificationListComponent } from './notification/list/list.component';
import { NotificationEditComponent } from './notification/edit/edit.component';
import { NotificationRoleListComponent } from './notificationRole/list/list.component';
import { EditComponent } from './notificationRole/edit/edit.component';
import { RoleEditComponent } from './roles/edit/edit.component';
import { MyNotificationComponent } from './my-notification/my-notification.component';
import {HeaderComponent} from "../layout/header/header.component";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    NgxSelectModule.forRoot(CustomSelectOptions),

  ],
  declarations: [
    NotificationShellComponent,
    NotificationListComponent,
    NotificationEditComponent,
    NotificationRoleListComponent,
    EditComponent,
    RoleEditComponent,
    MyNotificationComponent
  ],

  exports: [],
  entryComponents: [],

  providers: [ NotificationService , NotificationRoleService, RoleService,HeaderComponent ]
})
export class NotificationModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
      ...services.notesService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let notesService = appinjector.get(NotificationService);

    return {
      store,
      notesService,
    }
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    NotificationStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    NotificationStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.notesService.preloadData();
  }

}
