import { Injectable } from "@angular/core";
import { DataStore } from "../shared/models/DataStore";

@Injectable()
export class StatusStore extends DataStore {
    constructor() {
        super();
    }

    public initializeStore() {
        this.set('status', null);
    }
}