import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AppStore } from "./shared/services/store.service";
import { appInjector } from "./bootstrap-components.module/utils/appInjector";
//modules
import { SharedModule } from "./shared/shared.module";
import { AccountModule } from "./account.module/account.module";
import { MenuModule } from './menu.module/menu.module';
import { PlaceModule } from './place.module/place.module';
import { OrderModule } from './order.module/order.module';
import { IntroModule } from './intro.module/intro.module';
import { TableModule } from './tables.module/tables.module';
import { StatusModule } from './status.module/status.module';
import { OptionModule } from './option.module/option.module';
import { ClientModule } from './client.module/client.module';
import { CategoryModule } from './category.module/category.module';
import { SettingsModule } from './settings.module/settings.module';
import { PropertyModule } from './property.module/property.module';
import { DiscountModule } from './discount.module/discount.module';
import { AdditionalModule } from './additional.module/additional.module';
import { IngredientModule } from './ingredient.module/ingredient.module';
import { NotificationModule } from './notification.module/notification.module';
import { OrganisationModule } from './Organisation.module/organisation.module';
//services
import { AlertsService } from "./shared/services/alerts.service";
import { BaseService } from "./shared/services/BaseService";
import {tap} from "rxjs/operators";

@Injectable()
export class AppService {

  constructor(private appStore: AppStore) {
  }

  private get injector() {
    return appInjector.injector();
  }

  get router(): Router {
    return this.injector.get(Router);
  }
  get alertService(): AlertsService {
    return this.injector.get(AlertsService);
  }
  public loadData() {
    this.loadModulesData();
  }

  clearModulesCachedData() {
    MenuModule.initializeStore(this.appStore);
    PlaceModule.initializeStore(this.appStore);
    TableModule.initializeStore(this.appStore);
    OrderModule.initializeStore(this.appStore);
    IntroModule.initializeStore(this.appStore);
    OptionModule.initializeStore(this.appStore);
    StatusModule.initializeStore(this.appStore);
    ClientModule.initializeStore(this.appStore);
    CategoryModule.initializeStore(this.appStore);
    SettingsModule.initializeStore(this.appStore);
    PropertyModule.initializeStore(this.appStore);
    DiscountModule.initializeStore(this.appStore);
    IngredientModule.initializeStore(this.appStore);
    AdditionalModule.initializeStore(this.appStore);
    OrganisationModule.initializeStore(this.appStore);
    NotificationModule.initializeStore(this.appStore);
  }

  private loadModulesData() {
    AccountModule.initializeModuleData();
  }

  initializeApp() {
    this.loadModulesData();
  }

  public independentData() {
    let result = {};

    //result["Public Data"] = SharedModule.preloadData();

    return result;
  }

  public dependentData() {
    let result = {};

    result["Account Data"] = AccountModule.preloadData();

    return result;
  }

  public get modulesRoutes() {
    let result = {};

    return result;
  }


  handleReloadData() {
    this.appStore.action$("app.reload").subscribe(action => {
      if (action.payload === true) {
        this.clearModulesCachedData();
        this.appStore.dispatchAction('reloadComponent', "true");
      }
    });
  }
}
