import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// services
import { AccountService } from '../../../../account.module/services/account.service';
import { PlaceUsersService } from '../../../../place.module/services/placeUsers.service';
// models
import { CreateUserModel } from '../../../../account.module/models/CreateUserModel';
// components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-organisation-users',
  templateUrl: './organisation-users.component.html',
  styleUrls: ['./organisation-users.component.scss'],
})
export class OrganisationUsersComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private usersService: PlaceUsersService,
    protected translate: TranslateService,
    private activeRoute: ActivatedRoute,
    private accountService: AccountService
  ) {
    super();
  }

  public userList: Array<CreateUserModel> = [];
  public data: Array<CreateUserModel> = [];
  public organisationId: number;
  public columns: Array<any> = [
    { title: 'id', name: 'id' },
    { title: 'login', name: 'login' },
    { title: 'userName', name: 'userName' },
    { title: 'settings', name: 'settings' },
  ];

  ngOnInit() {
    this.organisationId = this.activeRoute.snapshot.params.id;

    if (!!this.organisationId) {
      this.accountService
        .getAllOrganisationsUser(this.organisationId)
        .subscribe((data) => {
          this.userList = data;
        });
    }
  }

  public save(user) {
    if (user.isActive) {
      this.accountService.deactivate(user.id).subscribe((data) => {
        if (!data.hasError) {
          if (!!this.organisationId) {
            this.accountService
              .getAllOrganisationsUser(this.organisationId)
              .subscribe((data) => {
                this.userList = data;
                this.alertService.showSuccess(
                  this.translate.instant('alerts.USERUPDATEDSUCCESFULLY')
                );
              });
          }
        } else {
          const errors = data.errors.map((r) => {
            return r.message;
          });
          this.alertService.showError(errors);
        }
      });
    } else {
      this.accountService.activate(user.id).subscribe((data) => {
        if (!data.hasError) {
          if (!!this.organisationId) {
            this.accountService
              .getAllOrganisationsUser(this.organisationId)
              .subscribe((data) => {
                this.userList = data;
                this.alertService.showSuccess(
                  this.translate.instant('alerts.USERUPDATEDSUCCESFULLY')
                );
              });
          }
        } else {
          const errors = data.errors.map((r) => {
            return r.message;
          });
          this.alertService.showError(errors);
        }
      });
    }
  }
}
