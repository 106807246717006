import { Response } from '@angular/http';
export class ApiResponseModel {
    constructor() {
        this.errors = [];
        this.statusCode = 200;
        this.data = {};
        this.hasError = false;
    }

    data: any;
    hasError: boolean;
    errors: any;
    statusCode: number;

    public get isError(): Boolean {
        //console.log("apiresponse this ", this);
        return (this.statusCode >= 400 && this.statusCode <= 599);
    }

    static fromResponse(response) {
        let responseResult = new ApiResponseModel();
        if (response instanceof Response) {

            try {
                //console.log(response);
                let apiResult = JSON.parse(response["_body"]);
                //responseResult.isError = !response.ok;
                responseResult = <ApiResponseModel>apiResult;

            } catch (e) {
                //console.log("response", response)
                //console.log("Exception:", e)
                responseResult.data = response["_body"];
                responseResult.errors = ["An error occured while processing request. Please contact administrator or try again later."];
                responseResult.statusCode = 500;
                responseResult.hasError = false;
            }
        }

        if (response.hasOwnProperty('data') && response.hasOwnProperty('statusCode') && response.hasOwnProperty('errors')) {

            responseResult.statusCode = response['statusCode'];
            responseResult.errors = response['errors'];
            responseResult.data = response['data'];
            responseResult.hasError = response['hasError'];
        }
        return responseResult;
    }
}
