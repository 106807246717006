export class LogModel {
	id: number;
	contentType: number;
  message: string;
	source: string;
	type: number;
  actionInfo:string;
  modelJson:string;
  token:string;
  period:number;
  uniqueId:number;
  createdDate:Date;
  updatedDate:Date;
  hasResolved:boolean = false;
	isActive: boolean = true;
}
