import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { IngredientStore } from './ingredient.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex'

const CustomSelectOptions: any = { // Check the interface for more options
    optionValueField: 'id',
    optionTextField: 'name'
};
import { IngredientService } from "./services/ingredient.service";
import { IngredientShellComponent } from './ingredient-shell.component';
import { IngredientListComponent } from './ingredient/list/list.component';
import { IngredientEditComponent } from './ingredient/edit/edit.component';


@NgModule({
  imports: [
  CommonModule,
  BrowserModule,
  SharedModule,
  RouterModule,
  NgxSelectModule.forRoot(CustomSelectOptions),
  ],
  declarations: [
  IngredientShellComponent,
  IngredientListComponent,
  IngredientEditComponent,
  ],

  exports: [],
  entryComponents: [],

  providers: [ IngredientService ]
})
export class IngredientModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
      ...services.ingredientService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let ingredientService = appinjector.get(IngredientService);

    return {
      store,
      ingredientService,
    }
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    IngredientStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    IngredientStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.ingredientService.preloadData();
  }

}
