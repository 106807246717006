import { Component, OnInit } from '@angular/core';
//models
import { IngredientModele } from '../../models/IngredientModele';
import { KeyValuePair } from '../../../models/KeyValuePair';
//services
import { IngredientService } from '../../services/ingredient.service';
import { PlaceService } from '../../../place.module/services/place.service';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class IngredientListComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private placeService: PlaceService,
		protected translate:TranslateService,
		private ingredientService: IngredientService) {
		super();
	}

	public ingredientList: Array<IngredientModele> = [];
	public data: Array<IngredientModele> = [];
	public page: number = 1;
	public itemsPerPage: number = 10;
	public maxSize: number = 5;
	public numPages: number = 1;
	public length: number = 0;
	public placeList: Array<any> = [];
	public ingFilter: IngFilter = new IngFilter();
	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'name', name: 'name' },
	{ title: 'Placename', name: 'Place name' },
	{ title: 'settings', name: 'settings' },

	];

	ngOnInit() {
		this.onChangeTable(this.config);
		this.ingredientService.getTotal().subscribe(res => {
			this.length = res;
		});
		// this.selectControl.valueChanges.subscribe(value => {
		// 	this.ingredientService.getAllByPlace(value).subscribe(res=> {
		// 		this.ingredientList = res;
		// 		this.length = res.length;
		// 	})
		// });

		this.placeService.getAllSimplified().subscribe(res => {
			this.placeList = res;
		});
	}

	public config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: '' },
		className: ['table-striped', 'table-bordered', 'mb-0', 'd-table-fixed']
	};

	public filter() {
		this.ingFilter.placeName = this.placeList.filter(i=> i.id == this.ingFilter.placeId)[0].name;
		this.ingredientService.getAllByName(this.ingFilter).subscribe(res=> {
			this.ingredientList = res;
			this.length =res.length;
		})
	}

	public clear(){
		this.ingFilter = new IngFilter()
	}

	public onChangeTable(config: any, page: any = { page: this.ingFilter.page, itemsPerPage: this.itemsPerPage }): any {
		this.ingredientService.getAllIngredient(page.page).subscribe(res=>{
			this.ingredientList = res;

		});
	}

	public save(table: IngredientModele ) {
		this.ingredientService.save(table).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.INGREDIENTUPDATEDSUCCESSFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}
}

export class IngFilter{
	placeId: number;
	placeName: string = "";
	ingName: string = "";
	page: number = 1;
	pageSize: number = 10;
}
