import { SortingOptionsModalComponent } from './option/list/sortingOptions-modal/sortingOptions-modal.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { DataStore } from '../shared/models/DataStore';
import { OptionStore } from './option.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';
import { OptionShellComponent } from './option-shell.component';

import { OptionService } from "./services/option.service";
import { OptionListComponent } from './option/list/list.component';
import { OptionEditComponent } from './option/edit/edit.component';
import {PropertyModule} from "../property.module/property.module";
import {DndModule, DragDropConfig, DragDropService, DragDropSortableService} from 'ng2-dnd';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        SharedModule,
        RouterModule,
        PropertyModule,
        DndModule
    ],
  declarations: [
    OptionShellComponent,
    OptionListComponent,
    OptionEditComponent,
    SortingOptionsModalComponent
  ],

  exports: [
      OptionShellComponent,
    OptionListComponent,
    OptionEditComponent],
  entryComponents: [SortingOptionsModalComponent],

  providers: [ OptionService ]
})
export class OptionModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
        ...services.optionService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let optionService = appinjector.get(OptionService);

    return {
      store,
      optionService,
    }
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    OptionStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    OptionStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.optionService.preloadData();
  }

}
