import { Component, OnInit } from '@angular/core';
//models
import { UserProfileModel } from '../../../account.module/models/UserProfileModel';
//services
import { ClientService } from '../../services/client.service';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
	selector: 'client-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class ClientListComponent extends AuthenticatedComponent implements OnInit {

	constructor(public clientService: ClientService,protected translate:TranslateService) {
		super()
	}

	public clientList: Array<UserProfileModel> = [];
	public data: Array<UserProfileModel> = [];

	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'image', name: 'image' },
	{ title: 'username', name: 'username' },
	{ title: 'firstName', name: 'firstname'},
	{ title: 'lastName', name: 'lastname' },
	{ title: 'email', name: 'email'},
	{ title: 'settings', name: 'settings' },

	];

	ngOnInit() {
		this.clientService.getAll().subscribe(data => {
			this.clientList = data;
		});
	}

	public save(client: UserProfileModel ) {

		this.clientService.save(client).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.CLIENTUPDATEDSUCCESSFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}

}
