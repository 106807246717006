import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CategoryModel } from '../models/CategoryModel';

@Injectable()
export class CategoryService extends CrudService<CategoryModel> {

    protected serviceItemsKey = 'category';

    constructor(protected store: AppStore) {
        super('Category', null);
    }

    public preloadData() {
        return [
            this.getAllCategories(),
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

  public getSortmenu()
  {
    return this.apirequest("get", this.apiCallTo(this.serviceApiUrl+`/GetAllForSorting`))
      .pipe(
        map(i => i.data),
      );
  }

  public UpdateSortmenu(sortList)
  {
    return this.apirequest("post", this.apiCallTo(this.serviceApiUrl + `/UpdateSortOrder`), null, JSON.stringify(sortList))
      .pipe(
        map(i => i.data),
      );
  }

    public getAllCategories() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetAll'))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

}
