import { Component, OnInit } from '@angular/core';
//models
import { StatusModel } from '../../models/StatusModel';
//services
import { StatusService } from '../../services/status.service';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
@Component({
  selector: 'status-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class StatusListComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private statusService: StatusService) {
		super();
	}

	public statusList: Array<StatusModel> = [];
	public data: Array<StatusModel> = [];

	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'name', name: 'name' },
	{ title: 'settings', name: 'settings' },

	];

	ngOnInit() {
		this.statusService.getAll().subscribe(data => {
			this.statusList = data;
		});
	}

	public save(status: StatusModel ) {
		this.statusService.save(status).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess('Status updated successfully.');
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}
}
