import { Injectable } from "@angular/core";
import { CrudService } from "./../../shared/services/crud.service";
import { AppStore } from "./../../shared/services/store.service";
import { forkJoin, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { NotificationModel } from "../models/NotificationModel";

@Injectable()
export class NotificationService extends CrudService<NotificationModel> {
  protected serviceItemsKey = "notification";

  constructor(protected store: AppStore) {
    super("Notification", null);
  }

  public preloadData() {
    return [this.getAllNotifications()];
  }

  public loadData() {
    return forkJoin(this.preloadData());
  }
  public setToken(token) {
    return this.apirequest(
      "get",
      this.apiCallTo(this.serviceApiUrl + `/setToken?token=${token}`)
    ).pipe(map((i) => i.data));
  }
  public setPrefferedLanguage(token, lng) {
    return this.apirequest(
      "get",
      this.apiCallTo(
        this.serviceApiUrl +
          `/SetPrefferedLanguage?lng=${lng}&deviceToken=${token}`
      )
    ).pipe(map((i) => i.data));
  }

  public deactivateToken(token) {
    return this.apirequest(
      "get",
      this.apiCallTo(this.serviceApiUrl + `/DeactivateToken?token=${token}`)
    ).pipe(map((i) => i.data));
  }
  public getAllNotifications() {
    return this.apirequest(
      "get",
      this.apiCallTo(this.serviceApiUrl + "/GetAll")
    ).pipe(
      map((i) => i.data),
      tap((d) => this.appStore.set(this.serviceItemsKey, d))
    );
  }
  public getNotCount() {
    return this.apirequest(
      "get",
      this.apiCallTo(this.serviceApiUrl + "/GetNotificationCount")
    ).pipe(map((i) => i.data));
  }
  public resetCount() {
    return this.apirequest(
      "get",
      this.apiCallTo(this.serviceApiUrl + "/ResetNotificationCount")
    ).pipe(map((i) => i.data));
  }
  public GetTotalNotifs() {
    return this.apirequest(
      "get",
      this.apiCallTo(this.serviceApiUrl + `/GetTotal`)
    ).pipe(map((i) => i.data));
  }

  public notifs(page, pageSize) {
    return this.apirequest(
      "get",
      this.apiCallTo(
        this.serviceApiUrl + `/Notifications?page=${page}&pageSize=${pageSize}`
      )
    ).pipe(
      map((i) => i.data),
      tap((d) => this.appStore.set(this.serviceItemsKey, d))
    );
  }
}

@Injectable()
export class NotificationRoleService extends CrudService<NotificationRoleRef> {
  protected serviceItemsKey = "NotificationRoleRef";

  constructor(protected store: AppStore) {
    super("NotificationRoleRef", null);
  }

  public preloadData() {
    return [this.getAllNotificationRoleRef()];
  }

  public loadData() {
    return forkJoin(this.preloadData());
  }

  public getAllNotificationRoleRef() {
    return this.apirequest(
      "get",
      this.apiCallTo(this.serviceApiUrl + "/GetAll")
    ).pipe(
      map((i) => i.data)
      // tap(d => this.appStore.set(this.serviceItemsKey, d))
    );
  }
  public getPairs(placeId) {
    return this.apirequest(
      "get",
      this.apiCallTo(this.serviceApiUrl + `/GetAllPairs?placeId=${placeId}`)
    ).pipe(map((i) => i.data));
  }
  public update(object): Observable<any> {
    return this.apirequest(
      "post",
      this.apiCallTo(this.serviceApiUrl + "/Update"),
      null,
      JSON.stringify(object)
    ).pipe(map((i) => i.data));
  }
  public getAllByPlace(placeName) {
    return this.apirequest(
      "get",
      this.apiCallTo(
        this.serviceApiUrl + `/GetAllByPlaceName?placeId=${placeName}`
      )
    ).pipe(
      map((i) => i.data)
      // tap(d => this.appStore.set(this.serviceItemsKey, d))
    );
  }
  public roleToggle(id) {
    return this.apirequest(
      "get",
      this.apiCallTo(this.serviceApiUrl + `/toggle?notificationRoleRefId=${id}`)
    ).pipe(
      map((i) => i.data)
      // tap(d => this.appStore.set(this.serviceItemsKey, d))
    );
  }
}

@Injectable()
export class RoleService extends CrudService<RoleModel> {
  protected serviceItemsKey = "Role";

  constructor(protected store: AppStore) {
    super("Role", null);
  }

  public preloadData() {
    return [this.getRoles()];
  }

  public loadData() {
    return forkJoin(this.preloadData());
  }

  public getRoles() {
    return this.apirequest(
      "get",
      this.apiCallTo(this.serviceApiUrl + "/GetAll")
    ).pipe(map((i) => i.data));
  }

  public getAllPersonalForPlace(id) {
    return this.apirequest(
      "get",
      this.apiCallTo(
        this.serviceApiUrl + `/GetAllPersonalForPlace?placeId=${id}`
      )
    ).pipe(map((i) => i.data));
  }

  public getAllExternalForPlace(id) {
    return this.apirequest(
      "get",
      this.apiCallTo(
        this.serviceApiUrl + `/GetAllExternalForPlace?placeId=${id}`
      )
    ).pipe(map((i) => i.data));
  }
  public getAllOrganisational() {
    return this.apirequest(
      "get",
      this.apiCallTo(this.serviceApiUrl + `/GetAllOrganisational`)
    ).pipe(map((i) => i.data));
  }
}

export class NotificationRoleRef {
  id: number;
  roleId: number;
  notificationIds: Array<number> = [];
  isActive: boolean = true;
}

export class RoleModel {
  id: number;
  name: string;
  placeId: number;
  place: any;
  isActive: boolean = true;
}
