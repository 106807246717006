import { Component, OnInit } from '@angular/core';
//models
import { PrivacyPolicyModel } from '../../../models/PrivacyPolicyModel';
//services
import { SettingsService } from '../../../services/settings.service';
//componts
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class TermsListComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private settingsService: SettingsService) {
		super();
	}

	public settingsList: Array<PrivacyPolicyModel> = [];
	public data: Array<PrivacyPolicyModel> = [];

	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'termsOfUse', name: 'Terms Of Use' },
	{ title: 'settings', name: 'settings' },

	];

	ngOnInit() {
		this.settingsService.getAll().subscribe(data => {
			this.settingsList = data.filter(item => item.termsOfUse !== null );
		});
	}

	public save(item: PrivacyPolicyModel ) {
		this.settingsService.save(item).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess('Terms Of Use updated successfully.');
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}

}
