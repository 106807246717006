export class LoginModel {

    constructor(login: string = '', password: string = '') {
        this.login = login;
        this.password = password;
    }

    public login = '';
    public password = '';
    public placeId = 0;
    public rememberMe = false;

    public IsValid() {
        return true;
    }

    public errors() {
        const result = [];

        return result;
    }
}
