import { Injectable } from '@angular/core';
import { DataStore } from '../shared/models/DataStore';

@Injectable()
export class OrderStore extends DataStore {
    constructor() {
        super();
    }

    public initializeStore() {
        this.set('order', null);
    }
}
