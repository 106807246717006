import { Observable, combineLatest, of, Subject } from 'rxjs';
import { Injectable, ViewContainerRef, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap';
import { ConfirmModalComponent } from './confirm/confirm-modal.component';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { InformModalComponent } from './inform/inform-modal.component';

@Injectable()
export class ModalsService {
    bsModalRef: BsModalRef;
    constructor(private modalService: BsModalService) { }

    public closeModal() {
        this.bsModalRef.hide();
    }

    public info(title: string,
        message: string=null,html:string=null): { ref: BsModalRef} {
        const initialState = {
            title,
            message,
            html
        }
        let result = {
            ref: null
        };

        result.ref = this.modalService.show(InformModalComponent, { initialState });
        
        const subscription = this.modalService.onHidden.subscribe(() => {
            result.ref.content.actionResponse.next(false);
            result.ref = null;
            subscription.unsubscribe();
        });

        return result; //modalRef.content.actionResponse.asObservable();
    }

    public confirm(title: string,
        message: string): { ref: BsModalRef, option: Observable<boolean> } {
        const initialState = {
            title,
            message
        }
        let result = {
            ref: null,
            option: null
        };

        result.ref = this.modalService.show(ConfirmModalComponent, { initialState });
        result.option = result.ref.content.actionResponse.asObservable();

        const subscription = this.modalService.onHidden.subscribe(() => {
            result.ref.content.actionResponse.next(false);
            result.ref = null;
            result.option = null;
            subscription.unsubscribe();
        });

        return result; //modalRef.content.actionResponse.asObservable();
    }

    private simpleModal(title: string, componentForDialog, componentData, largeModal = false) {
        const initialState = {
            title,
            componentType: componentForDialog,
            componentData: componentData
        }

        let result = {
            ref: null,
            onShow: this.modalService.onShow.asObservable(),
            onShown: this.modalService.onShown.asObservable(),
            onHide: this.modalService.onHide.asObservable(),
            onHidden: this.modalService.onHidden.asObservable()
        }

        const subscription = this.modalService.onHidden.subscribe(() => {
            result.ref = null;
        });

        result.ref = this.modalService.show(ModalContainerComponent, { initialState, class: (largeModal ? "modal-lg" : "") });
        return result;
    }

    public simple(title: string, componentForDialog, componentData = null) {
        return this.simpleModal(title, componentForDialog, componentData);
    }

    public simpleLg(title: string, componentForDialog, componentData = null) {
        return this.simpleModal(title, componentForDialog, componentData, true);
    }

    // public confirmWithoutContainer(title: string, message: string, titleAlign: string = 'center', messageAlign: string = 'center', btnOkText: string = 'Ok', btnCancelText: string = 'Cancel'): Observable<boolean> {

    //     let dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    //     let config = new MatDialogConfig();
    //     // config.viewContainerRef = viewContainerRef;

    //     dialogRef = this.dialog.open(ConfirmationDialogComponent, config);

    //     dialogRef.componentInstance.title = title;
    //     dialogRef.componentInstance.message = message;
    //     dialogRef.componentInstance.titleAlign = titleAlign;
    //     dialogRef.componentInstance.messageAlign = messageAlign;
    //     dialogRef.componentInstance.btnOkText = btnOkText;
    //     dialogRef.componentInstance.btnCancelText = btnCancelText;

    //     return dialogRef.afterClosed();
    // }
}




