import { Component, OnInit, Input } from '@angular/core';
//services
import { MenuService } from "../../services/menu.service";
//models
import { MenuModel } from '../../models/MenuModel';
import { MenuInsertModel } from '../../models/MenuModel';
//componts
import { BaseComponent } from '../../../shared/components/BaseComponent';
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
import {SortingSectionsModalComponent} from "../edit/menu/sortingSections-modal/sortingSections-modal.component";
import {ModalsService} from "../../../bootstrap-components.module/modals/modals.service";
import {SortingMenusModalComponent} from "./sortingMenus-modal/sortingMenus-modal.component";
import {ImportMenuItemsModalComponent} from "../edit/sections/importMenuItems-modal/importMenuItems-modal.component";
import {saveAs} from "file-saver";
import {ImportMenusModalComponent} from "./importMenus-modal/importMenus-modal.component";
import {LoadingService} from "../../../shared/services/loading.service";
@Component({
	selector: 'menu-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class MenuListComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private menuService: MenuService,
    private modalService: ModalsService,
    protected translate: TranslateService,
    private loadingService: LoadingService
  ) {
		super();
	}

	@Input() placeId: number = null;
	@Input() menus: Array<MenuModel> = [];
	@Input() copyList: Array<any> = null;
	public menuList: Array<MenuModel> = [];
	public data: Array<MenuModel> = [];
  private modalRef: any = null;
  public copyId: number = null;

	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'logo', name: 'logo' },
	{ title: 'name', name: 'name' },
	{ title: 'settings', name: 'settings' },

	];

	ngOnInit() {
		if (!this.placeId) {
			this.menuService.getAllMenues().subscribe(data => {
				this.menuList = data;
			});
		}
	}
  public openSortmodal()
  {
    this.modalRef = this.modalService.simpleLg("Sort Menus", SortingMenusModalComponent, {
      placeId:this.placeId,
    });
  }

  public import()
  {
    this.modalRef = this.modalService.simpleLg("Import Menus", ImportMenusModalComponent, {
      placeId:this.placeId
    });
  }
  public export()
  {
    this.menuService.ExportMenus(this.placeId).subscribe(data=>
    {
      var blob = new Blob([data],{type:`text/csv`});
      saveAs(blob,"Menus.csv");
    })
  }

  public copymenu()
  {
    this.modalService.confirm("Copy Menu",
      "Do you really want to copy menu?")
      .option.subscribe(option => {
      if (option == true) {
        this.loadingService.startLoading();
        this.menuService.CopyMenu(this.copyId,this.placeId).subscribe(data=>
        {
          if(!data.hasError) {
            this.alertService.showSuccess('Menu Copied successfully.');
            this.menuService.getAll().subscribe(data => {
              this.menuList = data;
            });
          } else {
            let errors = data.errors.map(r => {return r.message});
            this.alertService.showError(errors);
            this.loadingService.stopLoading();
          }
        })
      }
      else {
        this.modalService.closeModal();
      }
    });
  }

	createInsertModel(menu: MenuModel): MenuInsertModel {

		let insertModel: MenuInsertModel = new MenuInsertModel();

		insertModel.id = menu.id;
		insertModel.name = menu.name;
		insertModel.placeId = menu.placeId;
		insertModel.imageIds = menu.imageUrls.map(item => item.id);
		insertModel.nameTranslationId = menu.nameTranslationId;
		insertModel.isActive = menu.isActive;

		return insertModel;
	}

	public save(menu: MenuModel ) {

		let insertModel = this.createInsertModel(menu);

		this.menuService.save(insertModel).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.MENUUPDATEDSUCCSESSFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}
}
