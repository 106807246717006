import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
//components
import { PropertyShellComponent } from './property-shell.component';
import { PropertyListComponent } from './property/list/list.component';
import { PropertyEditComponent } from './property/edit/edit.component';

export const property = [
  {
    path: "list/:optionId",
    component: PropertyListComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "add/:optionId",
    component: PropertyEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "edit/:id",
    component: PropertyEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },

]
