import { Component, OnInit } from '@angular/core';
//models
import { IntroModel } from '../../models/introModel';
//services
import { IntroService } from '../../services/intro.service';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
@Component({
  selector: 'intro-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class IntroListComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private introService: IntroService) {
		super();
	}

	public introList: Array<IntroModel> = [];
	public data: Array<IntroModel> = [];

	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'image', name: 'image' },
	{ title: 'name', name: 'name' },
	{ title: 'settings', name: 'settings' },

	];

	ngOnInit() {
		this.introService.getAll().subscribe(data => {
			this.introList = data;
		});
	}

	public save(intro: IntroModel ) {
		this.introService.save(intro).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess('Intro updated successfully.');
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}

}
