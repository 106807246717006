import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { DataStore } from '../shared/models/DataStore';
import { CategoryStore } from './category.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';
import { CategoryShellComponent } from './category-shell.component';
import { TreeModule } from 'angular-tree-component';

import { CategoryService } from "./services/category.service";
import { CategoryListComponent } from './category/list/list.component';
import { CategoryEditComponent } from './category/edit/edit.component';
import {SortingCategoriesModalComponent} from "./category/list/sortingCategories-modal/sortingCategories-modal.component";
import {DndModule, DragDropConfig, DragDropService, DragDropSortableService} from "ng2-dnd";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    TreeModule.forRoot(),
    DndModule,
  ],
  declarations: [
    CategoryShellComponent,
    CategoryListComponent,
    CategoryEditComponent,
    SortingCategoriesModalComponent
  ],

  exports: [],
  entryComponents: [SortingCategoriesModalComponent],

  providers: [ CategoryService ,  DragDropService,DragDropConfig,DragDropSortableService]
})
export class CategoryModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
        ...services.categoryService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let categoryService = appinjector.get(CategoryService);

    return {
      store,
      categoryService,
    }
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    CategoryStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    CategoryStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.categoryService.preloadData();
  }
}
