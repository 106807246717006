import { Component, OnInit } from '@angular/core';
//models
import { MeasureUnitModel } from '../../models/MeasureUnitModel';
import { KeyValuePair } from '../../../models/KeyValuePair';
//services
import { MeasureUnitService } from '../../services/MeasureUnitService';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class MeasureUnitListComponent extends AuthenticatedComponent implements OnInit {

	constructor(
    protected translate: TranslateService,

    private measureunitService: MeasureUnitService) {
		super();
	}

	public measureunitList: Array<MeasureUnitModel> = [];
	public data: Array<MeasureUnitModel> = [];
	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'name', name: 'name' },

	{ title: 'settings', name: 'settings' },

	];

	ngOnInit() {
    this.measureunitService.getAllMeasureUnits().subscribe(res=>{
      this.measureunitList = res;
    });
		// this.selectControl.valueChanges.subscribe(value => {
		// 	this.ingredientService.getAllByPlace(value).subscribe(res=> {
		// 		this.ingredientList = res;
		// 		this.length = res.length;
		// 	})
		// });


	}


/*
	public filter() {
		this.measureunitService.getAllByName(this.mesFilter).subscribe(res=> {
			this.measureunitList = res;
			this.length =res.length;
		})
	}

	public clear(){
		this.mesFilter = new MesFilter()
	}
*/


	public save(table: MeasureUnitModel ) {
		this.measureunitService.save(table).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.MEASUREUNITUPDATEDSUCCESSFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}
}

