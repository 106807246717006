import { TableGroupModel } from './TableGroupModel';
import { PlaceModel } from '../../place.module/models/PlaceModel';

export class TableModel {
	id: number;
	type: number;
	guId: string;
	name: string;
	number: number;
	placeId: number;
	place: PlaceModel = new PlaceModel();
	tableGroup: TableGroupModel = new TableGroupModel();
	tableGroupId: number;
	nameTranslationId: number = 0;
	isActive: boolean = true;
}
