import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models

//services

//component
import {AuthenticatedComponent} from "../../../../shared/components/AuthenticatedComponent";
import {number} from "ng2-validation/dist/number";
import {BsModalRef} from "ngx-bootstrap";
import {PropertyService} from "../../../services/propery.services";
@Component({
	selector: 'app-section-modal',
	templateUrl: './importProperties-modal.component.html',
	styleUrls: ['./importProperties-modal.component.scss']
})

export class ImportPropertiesModalComponent extends AuthenticatedComponent implements OnInit {

	constructor(public propertyService:PropertyService,public bsModalRef:BsModalRef){
		super();
	}
  @Input() optionId: number;
  file:any = null;
  clicked: boolean=false;
	ngOnInit() {

	}
  public import()
  {
    this.propertyService.Import(this.file,this.optionId).subscribe(data=>
    {
      this.clicked=false;
      if (!data.hasError)
      {
        this.alertService.showSuccess(this.translate.instant("alerts.IMPORTEDSUCCESSFULLY"));
        this.bsModalRef.hide();
      }
      else{
        let errors = data.errors.map(r => {return r.message});
        this.alertService.showError(errors);
      }
    })
  }
}
