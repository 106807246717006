import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
//components
import { OptionShellComponent } from './option-shell.component';
import { OptionListComponent } from './option/list/list.component';
import { OptionEditComponent } from './option/edit/edit.component';

export const option = [

     // {
     //    path: "list",
     //    component: OptionListComponent,
     //    canActivate: [RedirectToLoginGuard],
     //    data: {
     //      mustHave: {
     //        permissions: []
     //      }
     //    }
     //  },
      {
        path: "list",
        component: OptionListComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "add/:menuItemId",
        component: OptionEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "edit/:id",
        component: OptionEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      }
]
