import { Injectable } from "@angular/core";
import { DataStore } from "../shared/models/DataStore";

@Injectable()
export class IngredientStore extends DataStore {
    constructor() {
        super();
    }

    public initializeStore() {
        this.set('ingredient', null);
    }
}