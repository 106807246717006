import { SortingOptionsModalComponent } from './sortingOptions-modal/sortingOptions-modal.component';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { OptionModel } from '../../models/OptionModel';
//services
import { OptionService } from '../../services/option.service';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
import {ImportMenuItemsModalComponent} from "../../../menu.module/menu/edit/sections/importMenuItems-modal/importMenuItems-modal.component";
import {saveAs} from "file-saver";
import {MenuItemService, MenuService} from "../../../menu.module/services/menu.service";
import {ImportOptionsModalComponent} from "../../../menu.module/menu/edit/menu-item/importOptions-modal/importOptions-modal.component";
import {ModalsService} from "../../../bootstrap-components.module/modals/modals.service";
@Component({
  selector: 'app-option-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class OptionListComponent extends AuthenticatedComponent implements OnChanges {

	constructor(
		private activeRoute: ActivatedRoute,
		protected translate:TranslateService,
		private menuItemService:MenuItemService,
		private modalService:ModalsService,
		private optionService: OptionService) {
		super();
	}

	@Input() menuItemId:number = null;
	public optionList: Array<OptionModel> = [];
	public data: Array<OptionModel> = [];
	public modalRef:any= null;
	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'name', name: 'name' },
	{ title: 'settings', name: 'settings' },

	];

	ngOnChanges() {
		this.optionService.getAllByMenuItem(this.menuItemId).subscribe(data => {
			this.optionList = data;
		});
	}

  public import()
  {
    this.modalRef = this.modalService.simpleLg("Import Options", ImportOptionsModalComponent, {
      menuItemId:this.menuItemId
    });
  }

  public export()
  {
    this.menuItemService.Export(this.menuItemId).subscribe(data=>
    {
      var blob = new Blob([data],{type:`text/csv`});
      saveAs(blob,"Options.csv");
    })
  }
  public openSortmodal()
  {
    this.modalRef = this.modalService.simpleLg("Sort Options", SortingOptionsModalComponent, {
      menuItemId:this.menuItemId,
    });
  }
	public save(option: OptionModel ) {
		this.optionService.save(option).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.OPTIONUPDATEDSUCCESSFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}
}
