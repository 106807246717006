import { Component, Input, forwardRef } from '@angular/core';
import { TranslationsProvider } from '../utils/translation-directive/translations.provider';

@Component({
    selector: 'card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.css'],
})
export class CardComponent {

    @Input() collapsable: boolean = false;
    @Input() cardOpen: boolean = true;
    private _title: string;
    @Input() get title(): string {
        return this._title;
    }
    set title(v: string) {
        this._title = this.translateProvider.getTranslationFor(v) || v;
        this._title = v ;
    }

    constructor(protected translateProvider: TranslationsProvider) {
    }

    toggleCard() {
        if (this.collapsable)
            this.cardOpen = !this.cardOpen;
    }

}
