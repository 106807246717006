import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { MenuModel } from '../../../models/MenuModel';
import { MenuItemModel } from '../../../models/MenuItemModel';
import { IngredientModel } from '../../../models/MenuItemModel';
import { MenuItemInsertModel } from '../../../models/MenuItemModel';
import { MeasureUnitModel } from '../../../../settings.module/models/MeasureUnitModel';

//services
import { MenuItemService } from "../../../services/menu.service";
import { MeasureUnitService } from "src/app/settings.module/services/MeasureUnitService";

import { ModalsService } from '../../../../bootstrap-components.module/modals/modals.service';
//components
import { MenuItemModalComponent } from './menu-item-modal/menu-item-modal.component';
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
import {ImportMenuItemsModalComponent} from "../sections/importMenuItems-modal/importMenuItems-modal.component";
import {saveAs} from "file-saver";
@Component({
	selector: 'app-menu-item',
	templateUrl: './menu-item.component.html',
	styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private modalService: ModalsService,
		protected translate:TranslateService,
		private menuItemService: MenuItemService) {
		super();
	}

	private modalRef: any = null;
	public menuItemList: Array<MenuItemModel> = [];
	public data: Array<MenuItemModel> = [];

	@Input() menu: MenuModel = new MenuModel();

	public columns: Array<any> = [
	{ title: 'id', name: 'id' },
	{ title: 'image', name: 'image' },
	{ title: 'name', name: 'name' },
	{ title: 'settings', name: 'settings' },
	];

	ngOnInit() {
		if (this.menu.id) {
			this.menuItemService.getAllMenuItems(this.menu.id).subscribe(data => {
				this.menuItemList = data;
			});
		}
	}
	public setIsActive(isActive, menuitemId)
	{
		this.menuItemService.setIsActive(isActive, menuitemId).subscribe((data) => {
		  if (!data.hasError) {
			this.alertService.showSuccess(
			  this.translate.instant('alerts.MENUITEMUPDATEDSUCCESSFULLY')
			);
		  } else {
			const errors = data.errors.map((r) => r.message);
			this.alertService.showError(errors);
		  }
		});
	}

	ngOnChanges() {

		// this.store.$("menuitem").subscribe(c => {
		// 	setTimeout(() => {
		// 		this.menuItemList = c;
		// 	}, 50);
		// 	this.hideModal();
		// })
    this.store.$("menuitem").subscribe(c => {
      setTimeout(() => {
        this.menuItemList = c;
      }, 50);
    })

    if (this.menu.id ) {
      this.menuItemService.getAllMenuItems(this.menu.id).subscribe(data => {
        this.menuItemList = data;
      });
    }
	}

	// public addMenuItem(id:number = null) {
	// 	this.modalRef = this.modalService.simpleLg(!!id ? 'Update Menu Item' : 'Add Menu Item', MenuItemModalComponent, {
	// 		menu: this.menu,
	// 		menuItemId: id
	// 	});
	// }

	// public hideModal() {
	// 	if (this.modalRef) {
	// 		setTimeout(() => {
	// 			this.modalRef.ref.hide();
	// 		}, 10);
	// 	}
	// }


	createInsertModel(menuItem: MenuItemModel): MenuItemInsertModel {

		let insertModel: MenuItemInsertModel = new MenuItemInsertModel();
		insertModel.id = menuItem.id;
		insertModel.name = menuItem.name;
		insertModel.menuId = menuItem.menuId;
		insertModel.price = menuItem.price;
		insertModel.weight = menuItem.weight;
		insertModel.weightMeasureUnitId = menuItem.weightMeasureUnitId;
		insertModel.calories = menuItem.calories;
		insertModel.caloriesMeasureUnitId = menuItem.caloriesMeasureUnitId;
		insertModel.sectionId = menuItem.sectionId;
		insertModel.averageCount = menuItem.averageCount;
		insertModel.averageRate = menuItem.averageRate;
		insertModel.imageIds = menuItem.imageIds;
		insertModel.ingredientIds = menuItem.ingredientIds;
		insertModel.nameTranslationId = menuItem.nameTranslationId;
		insertModel.sortOrder = menuItem.sortOrder;
		insertModel.isActive = menuItem.isActive;

		return insertModel;
	}

	public save(menuItem: MenuItemModel ) {

		let insertModel = this.createInsertModel(menuItem);

		this.menuItemService.save(insertModel).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.MENUITEMUPDATEDSUCCESSFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}
}
