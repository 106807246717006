import { BsModalRef } from "ngx-bootstrap";
import { UpdateModel } from "./../../../models/OrderModel";
import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
// models

// services

// component
import { AuthenticatedComponent } from "../../../../shared/components/AuthenticatedComponent";
import {
  MenuItemOrderRefGroupModel,
  MenuItemOrderRefModel,
} from "../../../models/OrderModel";
import { number } from "ng2-validation/dist/number";
import { LoadingService } from "src/app/shared/services/loading.service";
import { OrderService } from "src/app/order.module/services/order.service";
@Component({
  selector: "app-section-modal",
  templateUrl: "./Declineorder-modal.component.html",
  styleUrls: ["./Declineorder-modal.component.scss"],
})
export class DeclineOrderModalComponent
  extends AuthenticatedComponent
  implements OnInit
{
  constructor(
    private loadingService: LoadingService,
    public bsModalRef: BsModalRef,
    private orderService: OrderService
  ) {
    super();
  }
  reject = this.translate.instant("decline.NOTAVALABLE");
  reason;
  @Input() update: UpdateModel;
  @Input() transactions: Array<any>;
  ngOnInit() {}
  submit() {
    if (this.reject == this.translate.instant("decline.OTHER")) {
      this.reject = this.reason;
    }
    this.update.reject.text = this.reject;
    this.loadingService.startLoading();
    this.orderService.UpdateStatus(this.update).subscribe((data) => {
      if (!data.hasError) {
        this.alertService.showSuccess(
          this.translate.instant("alerts.STATUSUPDATEDSUCCESSFULLY")
        );
        this.bsModalRef.hide();
      } else {
        const errors = data.errors.map((r) => r.message);
        this.alertService.showError(errors);
      }
    });
  }
}
