import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {FormControl} from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';

//models
import { MenuModel } from '../../../../models/MenuModel';
import { SectionModel } from '../../../../models/SectionModel';
import { MenuItemModel } from '../../../../models/MenuItemModel';
import { KeyValuePair } from '../../../../../models/KeyValuePair';
import { IngredientModel } from '../../../../models/MenuItemModel';
import { MenuItemInsertModel } from '../../../../models/MenuItemModel';

//services
import { SectionService } from "../../../../services/menu.service";
import { MenuItemService } from "../../../../services/menu.service";
import { MeasureUnitService } from "src/app/settings.module/services/MeasureUnitService";

import { IngredientService } from "../../../../../ingredient.module/services/ingredient.service";

import { ModalsService } from '../../../../../bootstrap-components.module/modals/modals.service';
//components
import { AuthenticatedComponent } from '../../../../../shared/components/AuthenticatedComponent';
import {MeasureUnitModel} from "../../../../../settings.module/models/MeasureUnitModel";
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-menu-item-modal',
  templateUrl: './menu-item-modal.component.html',
  styleUrls: ['./menu-item-modal.component.scss']
})
export class MenuItemModalComponent extends AuthenticatedComponent implements OnInit {

  constructor(
    private activeRoute: ActivatedRoute,
    private menuItemService: MenuItemService,
    private ingredientService: IngredientService,
    private sectionService: SectionService,
    protected translate:TranslateService,
    private measureUnitService: MeasureUnitService) {
    super();
  }

  file: any = null;
  imageChangedEvent: any = '';
  formId: string = 'menu-item-form';
  ingredientList: Array<any> = [];
  sectionList: Array<KeyValuePair> = [];
  menuItemId: number = null;
  menuItem: MenuItemModel = new MenuItemModel();
  mesunits:Array<KeyValuePair>=[];
  croppedImage: any = '';
  myfile:any='';
  clicked:boolean=false;


  public selectControl = new FormControl();

  ngOnInit() {
    this.menuItemId = this.activeRoute.snapshot.params['id'];

    if (!!this.isEdit) {
      this.menuItemService.get(this.menuItemId).subscribe(res => {
        if (!!res) {
          this.menuItem = res
          this.sectionService.getAllSections(this.menuItem.menuId).subscribe(res => {
            this.sectionList = res.map(k => new KeyValuePair(k.id, k.name));
          });
        }else {
          this.alertService.showError(this.translate.instant("alerts.SOMETHINGWENTWRONG"));
        }
      });
    }
    else {
      this.menuItem.menuId = this.activeRoute.snapshot.params['menuId'];
      this.sectionService.getAllSections(this.menuItem.menuId).subscribe(res => {
        this.sectionList = res.map(k => new KeyValuePair(k.id, k.name));
      });
    }


    this.ingredientService.getAll().subscribe(res => {
      this.ingredientList = res;
    });
    this.measureUnitService.getAllMeasureUnits().subscribe(res => {
      this.mesunits = res.map(k => new KeyValuePair(k.id, k.name));
    });



    // this.selectControl.valueChanges.subscribe(value => {this.menuItem.ingredientIds = value});
  }

  delete(url) {
    this.menuItemService.deleteFile(url).subscribe(res => {
      if (res) {
        this.menuItemService.updateData({id:this.menuItem.id}).subscribe(res => {
          this.menuItem = res.data;
          this.alertService.showSuccess(this.translate.instant("alerts.IMAGEISDELETED"));
        });
      }
    })
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  dataURItoBlob(dataURI) {
    const byteString = dataURI;
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }
  base64ToFile(data, filename) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while(n--){

      u8arr[n] = bstr.charCodeAt(n);
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.file = this.base64ToFile(
      event.base64,
      "menuimage.png"
    )
	this.croppedImage = event.base64;
    this.file = this.base64ToFile(
		event.base64,
		"menuimage.png"
	  )
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  public get isEdit() {
    return this.activeRoute.snapshot.url.join('').includes("edit");
  }

  public createInsertModel(menuItem: MenuItemModel): MenuItemInsertModel {

    let insertModel: MenuItemInsertModel = new MenuItemInsertModel();
    insertModel.id = menuItem.id;
    insertModel.name = menuItem.name;
    insertModel.menuId = menuItem.menuId;
    insertModel.price = menuItem.price;
    insertModel.weight = menuItem.weight;
    insertModel.weightMeasureUnitId=menuItem.weightMeasureUnitId;
    insertModel.pieces = menuItem.pieces;
    insertModel.piecesMeasureUnitId=menuItem.piecesMeasureUnitId
    insertModel.imageIds = menuItem.imageIds;
    insertModel.calories = menuItem.calories;
    insertModel.caloriesMeasureUnitId=menuItem.caloriesMeasureUnitId;
    insertModel.sectionId = menuItem.sectionId;
    insertModel.averageRate = menuItem.averageRate;
    insertModel.averageCount = menuItem.averageCount;
    insertModel.description = menuItem.description;
    insertModel.ingredientIds = menuItem.ingredientIds;
    insertModel.nameTranslationId = menuItem.nameTranslationId;
    insertModel.descriptionTranslationId = menuItem.descriptionTranslationId;
    insertModel.sortOrder = menuItem.sortOrder;
    insertModel.isActive = menuItem.isActive;

    return insertModel;
  }

  public imagesUpload():Promise<Array<number>> {

    return new Promise((resolve, reject) => {
      if (this.file) {
        this.menuItemService.uploadFile(this.file).subscribe(res => {
          resolve([...res.data.map(item => { return item.id })]);
        });
      } else {
        resolve();
      }
    });
  }


  public save() {
    if (!this.isFormValid())
    {
      return;
    }

    let insertModel = this.createInsertModel(this.menuItem);

    if (!this.menuItem.id) {
      this.clicked=true;
      this.imagesUpload().then(imageIds => {

        if (!!imageIds) { insertModel.imageIds = [ ...imageIds] }

          this.menuItemService.save(insertModel).subscribe(d => {
            if (!d.hasError) {
              this.menuItemService.updateData({id:d.data}).subscribe(res => {
                this.alertService.showSuccess(this.translate.instant("alerts.MENUITEMUPDATEDSUCCESSFULLY"));
                this.back();
              })
            } else {
              let errors = d.errors.map(r => {return r.message});
              this.alertService.showError(errors);
              this.clicked=false;
            }
          });

      })
    } else {
      this.clicked=true;
      this.imagesUpload().then(imageIds => {

        if (!!imageIds) { insertModel.imageIds = [ ...imageIds] }

        // if (insertModel.imageIds.length > 0 ) {

        this.menuItemService.save(insertModel).subscribe(d => {
          this.clicked=false;
          if (!d.hasError) {
            this.menuItemService.updateData({id:insertModel.id}).subscribe(res => {
              this.alertService.showSuccess(this.translate.instant("alerts.MENUITEMUPDATEDSUCCESSFULLY"));
              this.back();
            })
          } else {
            let errors = d.errors.map(r => {return r.message});
            this.alertService.showError(errors);
          }
        });
        // } else {
        //                 this.alertService.showError('Menu Item Image is required!');
        // }
      })
    }
  }

  public isFormValid() {
    if (!super.isFormValid()) {
      this.alertService.showError(this.translate.instant("alerts.INVALID"));
      return false;
    }
    return true;
  }

  public getIngredients(event) {
    this.menuItem.ingredientIds = event.map(i => i.key);
  }

}
