import { ImageModel } from '../../models/ImageModel';
import { ContactModel } from '../../models/ContactModel';
import { WifiSettingModel } from '../../models/WifiSettingModel';
import { AddressLocationModel } from '../../models/AddressLocationModel';
import { OrganisationModel } from '../../Organisation.module/models/OrganisationModel';

export class PlaceModel{
	id: number;
	organisationId:	number;
	parentId: number;
	sortOrder:number;
	wifiId: number;
	availabilityRadius: number;
	serviceFeeInPercent: number;
	averageCount: number;
	isNew: boolean = false;
	isParent: boolean = false;
	averageRate: number;
	name: string;
	description: string;
	openHour: string;
	closeHour: string;
	addressDisplayName: string;
	currencySymbol: string;
	addressDisplayNameTranslationId: number = 0;
	wifi: WifiSettingModel = new WifiSettingModel();
	addressLocation: AddressLocationModel = new AddressLocationModel();
	contact: ContactModel = new ContactModel();
	parent: object;
	imageIds: Array<any> = [];
	logoIds: Array<any> = [];
	coverIds: Array<any> = [];
	logoUrls: Array<ImageModel> = [];
	coverUrls: Array<ImageModel> = [];
	imageUrls: Array<ImageModel> = [];
	categoryIds: Array<any>;
	additionalInfoIds: Array<any>;
	menus: Array<any>;
	tables: Array<any>;
	children: Array<any>;
	discounts: Array<any>;
	organisation:any;
	categories: Array<any>;
	tableGroups: Array<any>;
	additionalInfos: Array<any>;
	nameTranslationId: number = 0;
	descriptionTranslationId: number = 0;
  childrenIds:Array<any> =[];
	isActive: boolean = true;
}


export class PlaceInsertModel{
	id: number;
	organisationId: number;
	parentId: number;
	wifiId: number;
	sortOrder:number;
	availabilityRadius: number;
	serviceFeeInPercent: number;
	averageCount: number;
	isNew: boolean;
	isParent: boolean;
	averageRate: number;
	name: string;
	description: string;
	openHour: string;
	closeHour: string;
	addressDisplayName: string;
	addressDisplayNameTranslationId: number;
	wifi: WifiSettingModel = new WifiSettingModel();
	addressLocation: AddressLocationModel = new AddressLocationModel();
	contact: ContactModel = new ContactModel();
	imageIds: Array<any> = [];
	children: Array<any> = [];
	logoIds: Array<any> = [];
	coverIds: Array<any> = [];
	categoryIds: Array<any>
	additionalInfoIds: Array<any>;
	nameTranslationId: number;
	descriptionTranslationId: number;
	isActive: boolean;
}
