import {Injectable} from "@angular/core";
import {CrudService} from "../../shared/services/crud.service";
import {MeasureUnitModel} from "../models/MeasureUnitModel";
import {AppStore} from "../../shared/services/store.service";
import {map} from "rxjs/operators";


@Injectable()
export class MeasureUnitService extends CrudService<MeasureUnitModel> {
  protected serviceItemsKey = 'measureunit';

  constructor(protected store: AppStore) {
    super('MeasureUnit', null);
  }

  public getAllMeasureUnits() {
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetAll'))
      .pipe(
        map(i => i.data),
      );
  }
  public getAllMes(page = 1, pagesize = 10) {
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAll?page=${page}&pagesize=${pagesize}`))
      .pipe(
        map(i => i.data),
        // tap(d => this.appStore.set(this.serviceItemsKey, d))
      );
  }
}
