import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { KeyValuePair } from '../../../models/KeyValuePair';
import { IngredientModele } from '../../models/IngredientModele';
//services
import { IngredientService } from "../../services/ingredient.service";
import { PlaceService } from '../../../place.module/services/place.service';
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class IngredientEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private placeService: PlaceService,
		private activeRoute: ActivatedRoute,
		protected translate:TranslateService,
		private ingredientService: IngredientService) {
		super();
	}

	public file: any = null;
	public ingredient: IngredientModele = new IngredientModele();
	public placeList: Array<any> = [];
  clicked:boolean=false;

  public typelist: Array<any> = [

	new KeyValuePair(0, 'Removable'),
	new KeyValuePair(1, 'Additional'),

	];

	ngOnInit() {
		if (this.isEdit) {
			let ingredientId = this.activeRoute.snapshot.params['id'];
			this.ingredientService.get(ingredientId).subscribe(res => {
				if (!!res) {
					this.ingredient = res;
				}else {
					this.alertService.showError(this.translate.instant("alerts.SOMETHINGWENTWRONG"));
					this.back();
				}
			});
		}

		this.placeService.getAllSimplified().subscribe(res => {
			this.placeList = res;
		});
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public isFormValid() {

		if (!super.isFormValid()) {
			this.alertService.showError(this.translate.instant("alerts.INVALID"));
			return false;
		}
		return true;
	}

	// public back() {
	// 	this.router.navigateByUrl("ingredient/list");
	// }

	public save() {

		if (!this.isFormValid()) return;

		if (!this.ingredient.id ) {
      this.clicked=true;
			this.ingredientService.save(this.ingredient).subscribe(data => {
			  this.clicked=false;
				if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.INGREDIENTADDEDSUCCESSFULLY"));
					this.ingredientService.updateData({id:data.data}).subscribe(res => {
						this.back();
					})
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});

		} else {
      this.clicked=true;
			this.ingredientService.save(this.ingredient).subscribe(data => {
        this.clicked=false
        if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.INGREDIENTUPDATEDSUCCESSFULLY"));
					this.ingredientService.updateData({id:this.ingredient.id}).subscribe(res => {
						this.back();
					})
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});
		}
	}

}
