
export class UserProfileModel {
  id: number;
  userName: string;
  username: string;
  email: string;
  password: string;
  gender:number=0;
  confirmPassword: string;
  imageUrl: string;
  phoneNumber: string;
  role: string;
  roles: Array<any> = [];
  countryId: number=0;
  organisation: string;
  lastName: string;
  firstName: string;
  isActive: boolean;
}
