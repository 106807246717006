import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//services
import { RoleService } from "../../services/notfication.service";
import { PlaceService } from "../../../place.module/services/place.service";
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss']
})
export class RoleEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private activeRoute: ActivatedRoute,
		protected translate:TranslateService,
		private placeService: PlaceService,
		private roleService: RoleService) {
		super();
	}

	role: RoleModel = new RoleModel();
	place: any = null;
  clicked:boolean=false;


  ngOnInit() {

		let placeId = this.activeRoute.snapshot.params['placeId'];

		if (!!placeId) {
			this.role.placeId = Number(placeId);
			this.placeService.get(placeId).subscribe(res => {
				this.place = res.name;
			});
		}
		if (this.isEdit) {
			let id = this.activeRoute.snapshot.params['id'];
			this.roleService.get(id).subscribe(res => {
				if (!!res) {
					this.role = res;
					this.placeService.get(res.placeId).subscribe(res => {
						this.place = res.name;
					});
				}else {
					this.alertService.showError(this.translate.instant("alerts.SOMETHINGWENTWRONG"));
					this.back();
				}
			});
		}
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public isFormValid() {

		if (!super.isFormValid()) {
			this.alertService.showError(this.translate.instant("alerts.INVALID"));
			return false;
		}
		return true;
	}

	public save() {

		if (!this.isFormValid()) return;

		delete this.role.place;

		if (!this.role.id) {
        this.clicked=true;
			this.roleService.save(this.role).subscribe(data => {
        this.clicked=false;
				if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.ROLEADDEDSUCCESSFULLY"));
						this.back();
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});
		} else {
		  this.clicked=true;
			this.roleService.save(this.role).subscribe(data => {
        this.clicked=false;
        if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.ROLEUPDATEDSUCCESSFULLY"));
					this.back();
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});
		}
	}

}

export class RoleModel{
	id: number;
	name: string;
	placeId: number;
	place: any;
	isActive: boolean = true;
}
