export class RequestModel {
  id: number;
  name: string;
  tin: string;
  numberOfEmployees: number;
  email: string;
  country: string;
  city: string;
  addressLine1: string;
  addressLine2: string;
  numberOfPlaces: number;
  type: number;
  ownerId: number;
  isActive: boolean;
}
