import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// models
import { PropertyModele } from '../../models/PropertyModele';
import { KeyValuePair } from '../../../models/KeyValuePair';
// services
import { PropertyService } from '../../services/propery.services';
import { OptionService } from '../../../option.module/services/option.service';
// components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class PropertyEditComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private activeRoute: ActivatedRoute,
    protected translate: TranslateService,

    private optionService: OptionService,
    private propertyService: PropertyService
  ) {
    super();
  }

  public property: PropertyModele = new PropertyModele();
  public option: string = null;
  public typelist: Array<KeyValuePair> = [
    new KeyValuePair(0, this.translate.instant('property.TOTAL')),
    new KeyValuePair(1, this.translate.instant('property.WITHSOME')),
    new KeyValuePair(2, this.translate.instant('property.NONE')),
  ];
  croppedImage: any = '';
  myfile: any = '';
  file: any = null;
  imageChangedEvent: any = '';
  clicked = false;

  ngOnInit() {
    this.property.optionId = this.activeRoute.snapshot.params.optionId;

    if (this.isEdit) {
      const propertyId = this.activeRoute.snapshot.params.id;
      this.propertyService.get(propertyId).subscribe((res) => {
        if (!!res) {
          this.property = res;
          this.optionService.get(this.property.optionId).subscribe((r) => {
            this.option = r.title;
          });
        } else {
          this.alertService.showError(
            this.translate.instant('alerts.SOMETHINGWENTWRONG')
          );
          this.back();
        }
      });
    } else {
      this.optionService.get(this.property.optionId).subscribe((res) => {
        this.option = res.title;
      });
    }
  }

  delete(url) {
    this.propertyService.deleteFile(url).subscribe((res) => {
      if (res) {
        this.propertyService
          .updateData({ id: this.property.id })
          .subscribe((res) => {
            this.property = res.data;
            this.alertService.showSuccess(
              this.translate.instant('alerts.IMAGEISDELETED')
            );
          });
      }
    });
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  dataURItoBlob(dataURI) {
    const byteString = dataURI;
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }
  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.file = this.base64ToFile(event.base64, 'menuimage.png');
    this.croppedImage = event.base64;
    this.file = this.base64ToFile(event.base64, 'menuimage.png');
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  public get isEdit() {
    return this.activeRoute.snapshot.url.join('').includes('edit');
  }
  public imagesUpload(): Promise<Array<number>> {
    return new Promise((resolve, reject) => {
      if (this.file) {
        this.propertyService.uploadFile(this.file).subscribe((res) => {
          resolve([
            ...res.data.map((item) => {
              return item.id;
            }),
          ]);
        });
      } else {
        resolve();
      }
    });
  }

  public isFormValid() {
    if (!super.isFormValid()) {
      this.alertService.showError(this.translate.instant('alerts.INVALID'));
      return false;
    }
    return true;
  }

  // public back() {
  // 	this.router.navigateByUrl("property/list");
  // }

  public save() {
    if (!this.isFormValid()) { return; }

    if (!this.property.id) {
      this.clicked = true;
      this.imagesUpload().then((imageIds) => {
        if (!!imageIds) {
          this.property.imageIds = [...imageIds];
        }
        this.propertyService.save(this.property).subscribe((d) => {
          if (!d.hasError) {
            this.propertyService.updateData({ id: d.data }).subscribe((res) => {
              this.alertService.showSuccess(
                this.translate.instant('alerts.PROPERTYUPDATEDSUCCESSFULLY')
              );
              this.back();
            });
          } else {
            const errors = d.errors.map((r) => {
              return r.message;
            });
            this.alertService.showError(errors);
            this.clicked = false;
          }
        });
      });
    } else {
      this.clicked = true;
      this.imagesUpload().then((imageIds) => {
        if (!!imageIds) {
          this.property.imageIds = [...imageIds];
        }
        this.property.imageUrls = [];
        // if (insertModel.imageIds.length > 0 ) {

        this.propertyService.save(this.property).subscribe((d) => {
          this.clicked = false;
          if (!d.hasError) {
            this.propertyService
              .updateData({ id: this.property.id })
              .subscribe((res) => {
                this.alertService.showSuccess(
                  this.translate.instant('alerts.PROPERTYUPDATEDSUCCESSFULLY')
                );
                this.back();
              });
          } else {
            const errors = d.errors.map((r) => {
              return r.message;
            });
            this.alertService.showError(errors);
          }
        });
        // } else {
        //                 this.alertService.showError('Menu Item Image is required!');
        // }
      });
    }
  }
}
