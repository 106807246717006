import { ResetModel } from './../recover/recover.component';
import { Injectable } from '@angular/core';
import { LoginModel } from '../models/LoginModel';
import {
  Response,
  RequestOptions,
  Headers,
  URLSearchParams,
} from '@angular/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, finalize, tap } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { AppStore } from '../../../shared/services/store.service';
import { BaseService } from '../../../shared/services/BaseService';
import { HttpClient } from '../../../shared/services/HttpClient';
import { Router } from '@angular/router';
import { ApiResponseModel } from '../../../shared/models/ApiResponseModel';
import { TranslatorService } from '../../../core/translator/translator.service';
import DateTimeFormat = Intl.DateTimeFormat;
import { NotificationService } from '../../../notification.module/services/notfication.service';

@Injectable()
export class AuthenticationService extends BaseService {
  constructor(
    private store: AppStore,
    private activatedRoute: ActivatedRoute,
    private translator: TranslatorService,
    private notificationService: NotificationService
  ) {
    super();
    this.loadTranslations();
  }

  public loadTranslations() {
    const language =
      this.storage.get(this.storage.Keys.Languages) ||
      this.activatedRoute.snapshot.queryParams.l ||
      'en-US';
    this.translator.useLanguage(language);
  }

  public login(loginModel: LoginModel): Observable<any> {
    return this.anonymousrequest(
      'post',
      this.apiCallTo('Account/Login'),
      null,
      loginModel
    ).pipe(
      map((r) => this.getApiResponseModel(r)),
      tap((r) => {
        if (r.data) {
          this.storeToken(
            r.data.accessToken,
            r.data.refreshToken,
            r.data.expiry
          );
        }
      })
    );
  }
  public code(phoneNumber, isReset): Observable<any> {
    return this.anonymousrequest(
      'get',
      this.apiCallTo(
        `Account/Code?phoneNumber=${encodeURIComponent(
          phoneNumber
        )}&isReset=${isReset}`
      )
    ).pipe(map((r) => this.getApiResponseModel(r)));
  }
  public verify(phoneNumber, code, isReset): Observable<any> {
    return this.anonymousrequest(
      'get',
      this.apiCallTo(
        `Account/VerifyNumber?phoneNumber=${encodeURIComponent(
          phoneNumber
        )}&code=${code}&reset=${isReset}`
      )
    ).pipe(map((r) => this.getApiResponseModel(r)));
  }
  public register(userModel): Observable<any> {
    return this.anonymousrequest(
      'post',
      this.apiCallTo('Account/Register'),
      null,
      userModel
    ).pipe(
      map((r) => this.getApiResponseModel(r)),
      tap((r) => {
        if (r.data) {
          this.storeToken(
            r.data.accessToken,
            r.data.refreshToken,
            r.data.expiry
          );
        }
      })
    );
  }
  public resetPassword(resetModel): Observable<any> {
    return this.anonymousrequest(
      'post',
      this.apiCallTo('Account/ResetPassword'),
      null,
      resetModel
    ).pipe(
      map((r) => this.getApiResponseModel(r)),
    );
  }

  public getPlaces(loginModel: LoginModel): Observable<any> {
    return this.anonymousrequest(
      'post',
      this.apiCallTo('Account/Places'),
      null,
      loginModel
    ).pipe();
  }
  public Countries(): Observable<any> {
    return this.anonymousrequest(
      'get',
      this.apiCallTo('Account/Countries'),
      null
    ).pipe(map((i) => i.data));
  }
  private storeToken(
    token: string,
    refreshtoken: string,
    expire: DateTimeFormat,
    rememberme?: boolean
  ) {
    this.storage.save(this.storage.Keys.Token, token || null);
    this.storage.save(this.storage.Keys.RefreshToken, refreshtoken || null);
    this.storage.save(this.storage.Keys.Expire, expire || null);

    // this.storage.save(this.storage.Keys.RememberMe, rememberme || false);
  }

  public logout() {
    this.notificationService
      .deactivateToken(this.storage.get(this.storage.Keys.DevToken))
      .subscribe();
    for (const property in this.storage.Keys) {
      if (
        this.storage.Keys.hasOwnProperty(property) &&
        property !== 'Languages'
      ) {
        this.storage.delete(this.storage.Keys[property]);
      }
    }
    this.store.initializeStore();
    this.store.set('account.loggedUser', null);
  }
}
