import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { OrganisationModel } from '../../../models/OrganisationModel';
//services
import { OrganisationService } from "../../../services/organisation.service";
//components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
@Component({
    selector: 'app-organisation-images',
    templateUrl: './organisation-images.component.html',
    styleUrls: ['./organisation-images.component.scss']
})
export class OrganisationImagesComponent extends AuthenticatedComponent implements OnInit {

    constructor(
        private activeRoute: ActivatedRoute,
        public orgService :OrganisationService) {
        super(); 
    }

    @Input() organisation: OrganisationModel = new OrganisationModel();
    @Output() valueChange = new EventEmitter();

    ngOnInit() {
    }

    delete(url) {
      this.orgService.deleteFile(url).subscribe(res=> {
        if (res) {
          this.orgService.updateData({id:this.organisation.id}).subscribe(res => {
            this.organisation = res.data;
            this.alertService.showSuccess('The image is Deleted!');
          });
        }
      })
    }

    imageChange(key,value){
        this.valueChange.emit({key:key,value:value});
    }
}