import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";
import { CategoryService } from "./services/category.service";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class CategoryShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private categoryService: CategoryService) {
        super();
    }

    ngOnInit() {
        // if (!this.moduleDataLoaded) {
        //     this.categoryService.loadData().subscribe(
        //         r => {
        //             this.alertService.showInfo(`Categories data has been loaded!`);
        //             this.goToUrl('/category/list');
        //         },
        //         e => {
        //             this.alertService.showError(`Loading Categories data had encounter error.`);
        //             this.goToUrl('/dashboard');
        //         });
        // } else {
        //     this.alertService.showInfo(`Categories data has been loaded!`);
        //     this.goToUrl('/category/list');
        // } 
    }

    get moduleDataLoaded() {
        const storeKeysToVerify = ['category'];
        return storeKeysToVerify.map(k => !!this.store._(k)).
            reduce((acc, val) => {
                return acc && val;
            }, true)
    }
}