import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DiscountModel } from '../models/DiscountModel';

@Injectable()
export class DiscountService extends CrudService<DiscountModel> {

    protected serviceItemsKey = 'discount';

    constructor(protected store: AppStore) {
        super('Discount', null);
    }

    public preloadData() {
        return [
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

    public getAllDiscount(placeId: number) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAll?placeId=${placeId}`))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

    public GetUsersByDate(placeId: number ,startDate: any, expireDate: any) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetUsersByDate?placeId=${placeId}&startDate=${startDate}&expireDate=${expireDate}`))
            .pipe(
                map(i => i.data),
            );
    }
}