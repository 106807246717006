import { AlertsService } from "./../../../shared/services/alerts.service";
import { Component, OnInit } from "@angular/core";
//models
import { LogModel } from "../../models/LogModel";
//services
import { LogService } from "../../services/log.service";
//componts
import { AuthenticatedComponent } from "../../../shared/components/AuthenticatedComponent";
@Component({
  selector: "status-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class LogListComponent implements OnInit {
  constructor(
    private logService: LogService,
    private alertService: AlertsService
  ) {}
  public logList: Array<any> = [];
  public data: Array<any> = [];
  ngOnInit() {
    this.logService.getTotal().subscribe((data) => {
      this.length = data;
    });
    this.onChangeTable(this.config);
  }
  public rows: Array<any> = [];
  public columns: Array<any> = [
    { title: "id", name: "id" },
    { title: "message", name: "message" },
    { title: "source", name: "source" },
    { title: "settings", name: "settings" },
  ];
  public page: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    className: ["table-striped", "table-bordered", "mb-0", "d-table-fixed"],
  };

  public onChangeTable(
    config: any,
    page: any = { page: this.page, itemsPerPage: this.itemsPerPage }
  ): any {
    this.logService
      .getAllLogs(page.page, this.itemsPerPage)
      .subscribe((res) => {
        this.logList = res;
      });
  }
  public toggle(log: LogModel) {
    this.logService.toggle(log.id, log.hasResolved).subscribe((data) => {
      if (!data.hasError) {
        this.alertService.showSuccess("Log updated successfully.");
      } else {
        let errors = data.errors.map((r) => {
          return r.message;
        });
        this.alertService.showError(errors);
      }
    });
  }
}
