import { Component, OnInit } from '@angular/core';
//services
import { PlaceService } from "../../services/place.service";
//models
import { PlaceModel } from '../../models/PlaceModel';
import { PlaceInsertModel } from '../../models/PlaceModel';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
import {SortingSectionsModalComponent} from "../../../menu.module/menu/edit/menu/sortingSections-modal/sortingSections-modal.component";
import {SortingPlacesModalComponent} from "./sortingPlaces-modal/sortingPlaces-modal.component";
import {ModalsService} from "../../../bootstrap-components.module/modals/modals.service";

@Component({
  selector: 'place-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class PlaceListComponent extends AuthenticatedComponent implements OnInit {

  constructor(
    private placeService: PlaceService,
    private modalService: ModalsService,
    protected translate:TranslateService) {
    super();
  }

  public placeList: Array<PlaceModel> = [];
  public data: Array<PlaceModel> = [];
  private modalRef: any = null;

  filter: string = null;

  ngOnInit() {
    this.onChangeTable(this.config);
    this.placeService.getTotal().subscribe(res=> {
      this.length = res;
    });
  }

  placeFilter() {
    this.placeService.getAllPlaces(this.page , this.filter).subscribe(res=>{
      this.placeList = res;
      this.length = res.length;
    });
  }

  public openSortmodal()
  {
    this.modalRef = this.modalService.simpleLg("Sort Places", SortingPlacesModalComponent,);
  }
  createInsertModel(place:PlaceModel): PlaceInsertModel {
    let insertModel: PlaceInsertModel = new PlaceInsertModel();
    insertModel.id = place.id;
    insertModel.organisationId = place.organisationId;
    insertModel.parentId = place.parentId;
    insertModel.wifiId = place.wifiId;
    insertModel.availabilityRadius = place.availabilityRadius;
    insertModel.serviceFeeInPercent = place.serviceFeeInPercent;
    insertModel.averageCount = place.averageCount;
    insertModel.isNew = place.isNew;
    insertModel.isParent = place.isParent;
    insertModel.averageRate = place.averageRate;
    insertModel.name = place.name;
    insertModel.description = place.description;
    insertModel.openHour = place.openHour;
    insertModel.closeHour = place.closeHour;
    insertModel.wifi = place.wifi;
    insertModel.addressLocation = place.addressLocation;
    insertModel.contact = place.contact;
    insertModel.imageIds = place.imageIds;
    insertModel.logoIds = place.logoIds;
    insertModel.coverIds = place.coverIds;
    insertModel.categoryIds = place.categoryIds;
    insertModel.additionalInfoIds = place.additionalInfoIds;
    insertModel.nameTranslationId = place.nameTranslationId;
    insertModel.descriptionTranslationId = place.descriptionTranslationId;
    insertModel.addressDisplayNameTranslationId = place.addressDisplayNameTranslationId;
    insertModel.isActive = place.isActive;

    return insertModel;
  }

  public save(place: PlaceModel ) {


    this.placeService.updateIsActive(place.id,place.isActive).subscribe(data=>{
      if(!data.hasError ) {
        this.alertService.showSuccess(this.translate.instant("alerts.PLACEUPDATEDSUCCESSFULLY"));
      } else {
        let errors = data.errors.map(r => {return r.message});
        this.alertService.showError(errors);
      }
    })
  }

  public rows: Array<any> = [];
  public columns: Array<any> = [

    { title: 'id', name: 'id' },
    { title: 'logo', name: 'logo' },
    { title: 'name', name: 'name' },
    { title: 'settings', name: 'settings' },

  ];

  public page: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered', 'mb-0', 'd-table-fixed']
  };

  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {

    this.placeService.getAllPlaces(page.page ).subscribe(res=>{
      this.placeList = res;
    });
  }
}
