import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { AuthorizeGuard } from '../shared/services/security/authorizeGuard';
import { RequestEditComponent } from './request/edit/edit.component';
import { RequestListComponent } from './request/list/list.component';
// components

export const requests = [
  {
    path: 'list',
    component: RequestListComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: 'edit/:id',
    component: RequestEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  }
];
