import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
import { OrganisationShellComponent } from './organisation-shell.component';
import { OrganisationListComponent } from './organisation/list/list.component';
import { OrganisationEditComponent } from './organisation/edit/edit.component';
import { OrganisationUsersEditComponent } from './organisation/edit/organisation-users/edit/edit.component';

export const organisations = [
  {
    path: "list",
    component: OrganisationListComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "add",
    component: OrganisationEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "edit/:id",
    component: OrganisationEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "user/add/:organisationId",
    component: OrganisationUsersEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "user/edit/:orgId/:userId",
    component: OrganisationUsersEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  }

]
