import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { PlaceModel } from '../../../models/PlaceModel';
//services
import { PlaceService } from "../../../services/place.service";
//components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
@Component({
  selector: 'app-place-tables',
  templateUrl: './place-tables.component.html',
  styleUrls: ['./place-tables.component.scss']
})
export class PlaceTablesComponent extends AuthenticatedComponent implements OnInit {

	@Input() place: PlaceModel = new PlaceModel();

	constructor(
		private activeRoute: ActivatedRoute,
		public placeService :PlaceService) {
		super(); 
	}

	ngOnInit() {
	}

}
