import {Component, OnInit, ViewChild, Injector, ChangeDetectorRef, ApplicationRef} from '@angular/core';
import { Router } from '@angular/router';

const screenfull = require('screenfull');

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { TranslatorService } from '../../core/translator/translator.service';

import { AuthenticationService } from '../../routes/pages/services/authentication.service';
import { AuthenticatedComponent } from '../../shared/components/AuthenticatedComponent';
import {map, tap} from "rxjs/operators";
import {NotificationService} from "../../notification.module/services/notfication.service";
import {SignalRService} from "../../shared/services/signal-r.service";
import App = firebase.app.App;
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends AuthenticatedComponent implements OnInit {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    router: Router;
    notCount:number;
    selectedLanguage: string;
    notList:Array<any>;
    isNavSearchVisible: boolean;
    @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button

    constructor(
        public menu: MenuService,
        public authService: AuthenticationService,
        public userblockService: UserblockService,
        public translator: TranslatorService,
        public signalRService:SignalRService,
        public settings: SettingsService,
        public notificationService:NotificationService,
        public injector: Injector) {
        super();
        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout

    }

    ngOnInit() {
      this.loadNotifs();
      this.signalRService.connection.on('NotificationSent', () => {
        this.loadNotifs();
      });
      this.isNavSearchVisible = false;
        this.selectedLanguage = this.translator.getselectedLanguage();

        var ua = window.navigator.userAgent;
        if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }

        // Switch fullscreen icon indicator
        const el = this.fsbutton.nativeElement.firstElementChild;
        screenfull.on('change', () => {
            if (el)
                el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
        });

        this.router = this.injector.get(Router);

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe((val) => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close collapse menu
            this.navCollapsed = true;
        });

    }
    loadNotifs()
    {
      this.notificationService.getNotCount().subscribe(data=>
      {
        this.notCount=data.otherNotificationCount;
        //document.getElementById("count").innerText=this.notCount.toString();
      });

      this.notificationService.notifs(1,3).subscribe(data=>{
        this.notList=data;
      });
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }
  resetCount() {
    this.notificationService.resetCount().subscribe(()=>this.loadNotifs());
  }
    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
      //this.initializeApp(this.router.routerState.snapshot.url);    
      window.location.reload();
    }

    logout(){
        this.authService.logout();
        this.goToUrl("/login");
    }
}
