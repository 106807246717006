import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
//components
import { SettingsShellComponent } from './settings-shell.component';
import { PrivacyEditComponent } from './settings/privacy/edit/edit.component';
import { PrivacyListComponent } from './settings/privacy/list/list.component';
import { AboutEditComponent } from './settings/about/edit/edit.component';
import { AboutListComponent } from './settings/about/list/list.component';
import { TermsEditComponent } from './settings/terms/edit/edit.component';
import { TermsListComponent } from './settings/terms/list/list.component';
import { MeasureUnitListComponent } from './measureunits/list/list.component';
import { MeasureunitEditComponent } from './measureunits/edit/edit.component';



export const setting = [
     {
       path:"privacy",
       component:SettingsShellComponent,
       canActivate: [RedirectToLoginGuard],
       data: {
         mustHave: {
           permissions: []
         }
       },children: [
         {
        path: "list",
        component: PrivacyListComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "add",
        component: PrivacyEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "edit/:id",
        component: PrivacyEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      }
      ]},
      {
        path: "about-us",
        component: SettingsShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        },
        children: [
          {
            path: "list",
            component: AboutListComponent,
            canActivate: [RedirectToLoginGuard],
            data: {
              mustHave: {
                permissions: []
              }
            }
          },
          {
            path: "add",
            component: AboutEditComponent,
            canActivate: [RedirectToLoginGuard],
            data: {
              mustHave: {
                permissions: []
              }
            }
          },
          {
            path: "edit/:id",
            component: AboutEditComponent,
            canActivate: [RedirectToLoginGuard],
            data: {
              mustHave: {
                permissions: []
              }
            }
          }
        ]
      },
      {
        path: "terms-of-use",
        component: SettingsShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        },
        children: [
          {
            path: "list",
            component: TermsListComponent,
            canActivate: [RedirectToLoginGuard],
            data: {
              mustHave: {
                permissions: []
              }
            }
          },
          {
            path: "add",
            component: TermsEditComponent,
            canActivate: [RedirectToLoginGuard],
            data: {
              mustHave: {
                permissions: []
              }
            }
          },
          {
            path: "edit/:id",
            component: TermsEditComponent,
            canActivate: [RedirectToLoginGuard],
            data: {
              mustHave: {
                permissions: []
              }
            }
          }
        ]
      },
      {
        path: "measureunit",
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        },
        children: [
          {
            path: "list",
            component: MeasureUnitListComponent,
            canActivate: [RedirectToLoginGuard],
            data: {
              mustHave: {
                permissions: []
              }
            }
          },
          {
            path: "add",
            component: MeasureunitEditComponent,
            canActivate: [RedirectToLoginGuard],
            data: {
              mustHave: {
                permissions: []
              }
            }
          },
          {
            path: "edit/:id",
            component: MeasureunitEditComponent,
            canActivate: [RedirectToLoginGuard],
            data: {
              mustHave: {
                permissions: []
              }
            }
          }
        ]
      }
]
