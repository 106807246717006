import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxQRCodeModule } from "ngx-qrcode2";
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { LogStore } from "./log.store";
import { appInjector } from "../bootstrap-components.module/utils/appInjector";
import { AppStore } from "../shared/services/store.service";

import { LogService } from "./services/log.service";
import { LogShellComponent } from "./log-shell.component";
import { LogListComponent } from "./log/list/list.component";
import { LogEditComponent } from "./log/edit/edit.component";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    NgxQRCodeModule,
  ],
  declarations: [LogShellComponent, LogListComponent, LogEditComponent],
  exports: [],
  entryComponents: [],

  providers: [LogService],
})
export class LogModule {
  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [...services.statusService.preloadData()],
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let statusService = appinjector.get(LogService);

    return {
      store,
      statusService,
    };
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    LogStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    LogStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.statusService.preloadData();
  }
}
