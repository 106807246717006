import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";
import { LogService } from "./services/log.service";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class LogShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private statusService: LogService) {
        super();
    }

    ngOnInit() { }
}
