import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalsService } from '../modals/modals.service';
@Component({
  selector: 'app-image',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit {

  constructor(
        private modalService: ModalsService,
    ) { }

  ngOnInit() {
  }

  @Input() src: string;
  @Input() id: number = null;
  @Input() height:number = 250;
  @Output() fileChanged = new EventEmitter();

  delete(id: number) {
        this.modalService.confirm("",
        "Do you want to Delete this Image?")
        .option.subscribe(option => {
            if (option == true) {
                this.fileChanged.emit(id);
            }
        });
  }

}
