import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// models
import { NotificationModel } from '../../models/NotificationModel';
import { KeyValuePair } from '../../../models/KeyValuePair';
// services
import { NotificationService } from '../../services/notfication.service';
import { OptionService } from '../../../option.module/services/option.service';
// components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'notification-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class NotificationEditComponent extends AuthenticatedComponent implements OnInit {


constructor(
private activeRoute: ActivatedRoute,
protected translate: TranslateService,
private notService: NotificationService) {
super();
}

public notification: NotificationModel = new NotificationModel();
public prioritylist: Array<KeyValuePair> = [

new KeyValuePair(0, 'hight'),
new KeyValuePair(1, 'Normal'),

];
  clicked = false;
public typelist: Array<KeyValuePair> = [

new KeyValuePair(0, 'ReceiveSale'),
new KeyValuePair(1, 'ReceiveCoupon'),
new KeyValuePair(2, 'ReceiveBonusCard'),
new KeyValuePair(3, 'FiveDaysBeforeExpire'),
new KeyValuePair(4, 'OneDayBeforeExpire'),
new KeyValuePair(5, 'QRScanned'),
new KeyValuePair(6, 'DeclineOrder'),
new KeyValuePair(7, 'CompleteOrder'),
new KeyValuePair(8, 'TableScanned'),
new KeyValuePair(9, 'NewOrder'),
new KeyValuePair(10, 'RequestBill'),
new KeyValuePair(11, 'RequestWaiter'),
new KeyValuePair(12, 'StatusChanged'),
new KeyValuePair(13, 'ProceedToCheckout'),
new KeyValuePair(14, 'OrderStatusChanged'),
new KeyValuePair(15, 'TryToLeave'),
new KeyValuePair(16, 'Left')
];

ngOnInit() {

 if (this.isEdit) {
   const notId = this.activeRoute.snapshot.params.id;
   this.notService.get(notId).subscribe(res => {
 if (!!res) {
 this.notification = res;
 } else {
  this.alertService.showError(this.translate.instant('alerts.SOMETHINGWENTWRONG'));
  this.back();
   }
  });
 }
}

public get isEdit() {
return this.activeRoute.snapshot.url.join('').includes('edit');
}

public isFormValid() {

if (!super.isFormValid()) {
this.alertService.showError(this.translate.instant('alerts.INVALID'));
return false;
}
return true;
}

// public back() {
// 	this.router.navigateByUrl("notification/list");
// }

public save() {

if (!this.isFormValid()) { return; }
this.clicked = true;
this.notService.save(this.notification).subscribe(data => {
this.clicked = false;
if (!data.hasError) {
this.alertService.showSuccess(this.translate.instant('alerts.NOTIFICATIONUPDATEDSUCCESSFULLY'));
this.notService.updateData({id: this.notification.id}).subscribe(res => {
this.back();
});
} else {
const errors = data.errors.map(r => r.message);
this.alertService.showError(errors);
  }
 });
}
}
