import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { AppStore } from '../store.service';

@Injectable()
export class RedirectToInitializeGuard implements CanActivate {
  constructor(private router: Router,
              private appStore: AppStore
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    if (!this.appStore._('app.initialized')) {
      // if user is authenticated redirect to dashboard
      if(state.url!=''&&state.url!='/') {
        this.router.navigate(['/initialize'], {queryParams: {returnurl: state.url}});
      }
      else
      {
        this.router.navigate(['/initialize']);

      }
      return false;
    }
    return true;
  };
}
