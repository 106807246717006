import { MenuModel } from './MenuModel';

export class RoleModel {
id: number;
name: string;
active:	boolean;
sections: any = [];
isActive = true;
}

export class SectionModel {
id: number;
name: string;
menuId: number;
roleIds: Array<number> = [];
menu: MenuModel = new MenuModel();
role: RoleModel = new RoleModel();
nameTranslationId = 0;
sortOrder = 0;
menuItems: any = [];
isActive = true;
}

export class SectionInsertModel {
id: number;
name: string;
menuId: number;
roleIds: Array<number> = [];
nameTranslationId = 0;
sortOrder = 0;
isActive = true;
}


