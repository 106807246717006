import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { PlaceModel } from '../../../models/PlaceModel';
import { KeyValuePair } from '../../../../models/KeyValuePair';
import { UserAffiliationRefModel } from '../../../models/UserAffiliationRefModel';
import { UserAffiliationRefInsertModel } from '../../../models/UserAffiliationRefModel';
//services
import { PlaceService } from "../../../services/place.service";
import { RoleService } from "../../../../notification.module/services/notfication.service";
import { PlaceUsersService } from "../../../services/placeUsers.service";
//components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
	selector: 'app-place-personal',
	templateUrl: './place-personal.component.html',
	styleUrls: ['./place-personal.component.scss']
})
export class PlacePersonalComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private activeRoute: ActivatedRoute,
		public roleService: RoleService,
		protected translate:TranslateService,
		public placeService :PlaceService,
		private usersService: PlaceUsersService) {
		super();
	}

	public userList: Array<UserAffiliationRefModel> = [];
	public data: Array<UserAffiliationRefModel> = [];
	placeId: number = null;
	roleList: Array<any> = [];
	roledata: Array<any> = [];
	@Input() place: PlaceModel = new PlaceModel();
	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'userLogin', name: 'user Login' },
	{ title: 'userRole', name: 'user Role' },
	{ title: 'settings', name: 'settings' },

	];

	public columnsForRole: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'name', name: 'role Name' },
	{ title: 'settings', name: 'settings' },

	];

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	ngOnInit() {

		if ( this.isEdit) {
			this.placeId = this.activeRoute.snapshot.params['id'];
			this.usersService.getAllUSers(this.placeId).subscribe(data => {
				this.userList = data;
			});
			this.roleService.getAllExternalForPlace(this.placeId).subscribe(res => {
				this.roleList = res;

      })
		}
	}

	// public back() {
	// 	this.router.navigateByUrl("place/list");
	// }

	private createInsertModel(user: UserAffiliationRefModel): UserAffiliationRefInsertModel {
		let insertModel: UserAffiliationRefInsertModel = new UserAffiliationRefInsertModel();
		insertModel.id = user.id;
		insertModel.roleId = user.roleId;
		insertModel.placeId = user.placeId;
		insertModel.userLogin = user.userLogin;
		insertModel.organisationId = user.organisationId;
		insertModel.isActive = user.isActive;

		return insertModel;
	}

	public save(user: UserAffiliationRefModel ) {

		let insertModel = this.createInsertModel(user);

		this.usersService.save(insertModel).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.USERUPDATEDSUCCESFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}
}
