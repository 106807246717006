import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { NotificationModel } from '../../models/NotificationModel';
import { KeyValuePair } from '../../../models/KeyValuePair';
//services
import { NotificationService } from "../../services/notfication.service";
import { RoleService } from "../../services/notfication.service";
import { NotificationRoleService } from "../../services/notfication.service";
import { SectionService } from "../../../menu.module/services/menu.service";
import { PlaceService } from "../../../place.module/services/place.service";
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private activeRoute: ActivatedRoute,
		private roleService: RoleService,
		private sectionService: SectionService,
		private placeService: PlaceService,
		protected translate:TranslateService,
		private notRoleService: NotificationRoleService,
		private notService: NotificationService) {
		super();
	}

	public notification: NotificationRoleRef = new NotificationRoleRef();
	public roleList: Array<any> = [];
	public notificationList: Array<any> = [];
	public placeList: Array<any> = [];
	public placeId: number = null;
  clicked:boolean=false;

  ngOnInit() {
		this.notService.getAll().subscribe(res => {
			this.notificationList = res.map(item => new NotListClass(item.id, item.title));
		})

		this.placeService.getAllSimplified().subscribe(res => {
			this.placeList = res.map(item => new KeyValuePair(item.id, item.name));
		})
	}

	getRoles(event){
		console.log(event.key)
		this.roleService.getAllPersonalForPlace(event.key).subscribe(res => {
			this.roleList = res.map(item => new KeyValuePair(item.id,item.name));
		})
	}

	public isFormValid() {

		if (!super.isFormValid()) {
			this.alertService.showError(this.translate.instant("alerts.INVALID"));
			return false;
		}
		return true;
	}

	// public back() {
	// 	this.router.navigateByUrl("notification/role/list");
	// }

	public save() {

		if (!this.isFormValid()) return;
    this.clicked=true;
		this.notRoleService.save(this.notification).subscribe(data => {
		  this.clicked=false;
			if(!data.hasError) {
				if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.NOTIFICATIONROLEADDEDSUCCESSFULLY"));
					this.notRoleService.getAllNotificationRoleRef().subscribe(res => {
						this.back();
					})
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			}
		});
	}

	public getnotifications(event) {
		this.notification.notificationIds = event.map(i => i.key);
	}
}


export class NotificationRoleRef {
  id:number ;
  roleId: number ;
  notificationIds: Array<number> = [];
  isActive: boolean = true;
}

export class NotListClass {

	constructor(id, name) {
		this.id = id;
		this.name = name;
	}

	id:number ;
	name: string;
}
