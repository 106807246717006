import { DndModule } from 'ng2-dnd';
import { SortingPropertiesModalComponent } from './property/list/sortingProperties-modal/sortingProperties-modal.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { PropertyStore } from './property.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';

import { PropertyService } from './services/propery.services';
import { PropertyShellComponent } from './property-shell.component';
import { PropertyListComponent } from './property/list/list.component';
import { PropertyEditComponent } from './property/edit/edit.component';
import {ImportPropertiesModalComponent} from './property/list/importProperties-modal/importProperties-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';


@NgModule({
  imports: [
  CommonModule,
  BrowserModule,
  SharedModule,
  RouterModule,
  ImageCropperModule,
  DndModule
  ],
  declarations: [
  PropertyShellComponent,
  PropertyListComponent,
  PropertyEditComponent,
    ImportPropertiesModalComponent,
    SortingPropertiesModalComponent
  ],

    exports: [
        PropertyListComponent
    ],
  entryComponents: [ImportPropertiesModalComponent, SortingPropertiesModalComponent],

  providers: [ PropertyService ]
})
export class PropertyModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
      ...services.propertyService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    const appinjector = appInjector.injector();
    const store = appinjector.get(AppStore);
    const propertyService = appinjector.get(PropertyService);

    return {
      store,
      propertyService,
    };
  }

  static initializeModuleData() {
    const injector = appInjector.injector();
    const store = injector.get(AppStore);
    PropertyStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    PropertyStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.propertyService.preloadData();
  }

}
