import { ModalsService } from './../../../../bootstrap-components.module/modals/modals.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// models
import { OrganisationModel } from '../../../models/OrganisationModel';
// services
import { OrganisationService } from '../../../services/organisation.service';
// components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
import { KeyValuePair } from '../../../../models/KeyValuePair';
import { OrderService } from 'src/app/order.module/services/order.service';
@Component({
  selector: 'app-organisation-transactions',
  templateUrl: './organisation-transactions.component.html',
  styleUrls: ['./organisation-transactions.component.scss'],
})
export class OrganisationTransactionsComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private activeRoute: ActivatedRoute,
    private orderService: OrderService,
    private modalsService: ModalsService,
    public orgService: OrganisationService
  ) {
    super();
  }

  public organisationId: number;
  public transactionList: Array<any> = [];
  public data: Array<any> = [];

  public columns: Array<any> = [
    { title: 'id', name: 'id' },
    { title: 'user', name: 'user' },
    { title: 'orderId', name: 'orderId' },
    { title: 'place', name: 'place' },
    { title: 'amount', name: 'amount' },
    { title: 'date', name: 'date' },
    { title: 'paymentType', name: 'paymentType' },
  ];
  public typelist: Array<KeyValuePair> = [
    new KeyValuePair(0, 'payment.Withoutcardbinding'),
    new KeyValuePair(1, 'payment.Wiyhcardbinding'),
    new KeyValuePair(2, 'payment.Cash'),
    new KeyValuePair(3, 'payment.Withbingingcard'),
    new KeyValuePair(4, 'payment.Idram'),
    new KeyValuePair(5, 'payment.Reversed'),
  ];
  ngOnInit() {
    this.organisationId = this.activeRoute.snapshot.params.id;
    if (!!this.organisationId) {
      this.orgService
        .getAllTransactions(this.organisationId)
        .subscribe((res) => {
          this.transactionList = res;
        });
    }
  }
  public reverse(id: number) {
    this.modalsService.confirm(this.translate.instant('transaction.REVERSE'),
      this.translate.instant('transaction.AREYOUREALLYWANTTOREVERSE'))
      .option.subscribe(option => {
      if (option === true) {
        this.orderService.ReverseTransaction(id).subscribe(data => {
          if (!data.hasError) {
            this.alertService.showSuccess(this.translate.instant('alerts.TRANSACTIONREVERSEDSUCCESSFULLY'));
       } else {
         const errors = data.errors.map(r => r.message);
         this.alertService.showError(errors);
      }
        });
      }
    });
  }
}
