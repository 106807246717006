import { Component, OnInit } from "@angular/core";
import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class NotificationShellComponent extends AuthenticatedComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit() {
    }
}