import { Component, OnDestroy, Input } from '@angular/core';

@Component({
    selector: 'app-busy-screen',
    templateUrl: './busy-screen.component.html',
    styleUrls: ['./busy-screen.component.css']
})

export class BusyScreenComponent {
    private currentTimeout: any;
    public isDelayedRunning: boolean = false;

    @Input()
    public delay: number = 10;

    @Input()
    public set isRunning(value: boolean) {
        ////console.log(value);
        if (!value) {
            this.cancelTimeout();
            this.isDelayedRunning = false;
            return;
        }

        if (this.currentTimeout) {
            return;
        }

        this.currentTimeout = setTimeout(() => {
            this.isDelayedRunning = value;
            this.cancelTimeout();
        }, this.delay);
    }

    private cancelTimeout(): void {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = undefined;
    }

    ngOnDestroy(): any {
        this.cancelTimeout();
    }
}
