import { PublicComponent } from 'src/app/shared/components/PublicComponent';
import { AlertsService } from './../../../shared/services/alerts.service';
import { AuthenticationService } from './../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
})
export class RecoverComponent extends PublicComponent implements OnInit {
  valForm: FormGroup;
  resetForm: FormGroup;
  token;
  code;
  constructor(
    public settings: SettingsService,
    fb: FormBuilder,
    private authService: AuthenticationService,
    private alertsService: AlertsService
  ) {
    super();
    const password = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$'),
      ])
    );
    const certainPassword = new FormControl('', [
      Validators.required,
      CustomValidators.equalTo(password),
    ]);
    this.valForm = fb.group({
      phone: [null, Validators.compose([Validators.required])],
    });
    this.resetForm = fb.group({
      password,
      confirmPassword: certainPassword,
    });
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.getcode();
    }
  }
  getcode() {
    this.authService.code(this.valForm.value.phone, true).subscribe((data) => {
      if (!data.data) {
        const errors = data.errors.map((r) => r.message);
        this.alertService.showError(errors);
      } else {
        document.getElementById('ph').hidden = true;
        document.getElementById('code').hidden = false;
        const element = document.getElementById('resesnd') as HTMLInputElement;
        element.hidden = true;
        const element1 = document.getElementById(
          'resendlabel'
        ) as HTMLInputElement;
        element1.hidden = false;

        setTimeout(this.enable, 30000);
      }
    });
  }
  enable() {
    const element = document.getElementById('resesnd') as HTMLInputElement;
    element.hidden = false;
    const element1 = document.getElementById('resendlabel') as HTMLInputElement;
    element1.hidden = true;
  }
  sendCode() {
    this.authService.code(this.valForm.value.phone, true).subscribe((data) => {
      if (!data.data) {
        const errors = data.errors.map((r) => r.message);
        this.alertsService.showError(errors);
      } else {
        const element = document.getElementById('resesnd') as HTMLInputElement;
        element.hidden = true;
        const element1 = document.getElementById(
          'resendlabel'
        ) as HTMLInputElement;
        element1.hidden = false;

        setTimeout(this.enable, 30000);
      }
    });
  }
  verify() {
    this.authService
      .verify(this.valForm.value.phone, this.code, true)
      .subscribe((data) => {
        if (!data.data) {
          const errors = data.errors.map((r) => r.message);
          this.alertsService.showError(errors);
        } else {
          this.token = data.data.token;
          const element = document.getElementById('code') as HTMLInputElement;
          element.hidden = true;
          const element1 = document.getElementById('reset') as HTMLInputElement;
          element1.hidden = false;
        }
      });
  }
  resetPassword($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.resetForm.controls) {
      this.resetForm.controls[c].markAsTouched();
    }
    if (this.resetForm.valid) {
      const resetModel = new ResetModel();
      resetModel.login = this.valForm.value.phone;
      resetModel.password = this.resetForm.value.password;
      resetModel.confirmPassword = this.resetForm.value.comfirmPassword;
      resetModel.token = this.token;
      this.authService.resetPassword(resetModel).subscribe((data) => {
        if (!data.data) {
          const errors = data.errors.map((r) => r.message);
          this.alertsService.showError(errors);
        } else {
          this.alertsService.showSuccess(
            this.translate.instant("alerts.PASSWORDUPDATEDSUCCESSFULLY")
          );
          this.goToUrl("/login/");
        }
      });
    }
  }
  ngOnInit() {}
}
export class ResetModel {
  login: string;
  password: string;
  confirmPassword: string;
  token: string;
}
