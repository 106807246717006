import { PlaceModel } from '../models/PlaceModel'
import { OrganisationModel } from '../../Organisation.module/models/OrganisationModel'

export class UserAffiliationRefModel {
	id: number;
	organisationId: number;
	placeId: number;
	roleId: number;
	userLogin: string;
	place: PlaceModel = new PlaceModel();
	organisation: OrganisationModel = new OrganisationModel();
	isActive: boolean = true;
}


export class UserAffiliationRefInsertModel {
	id: number;
	organisationId: number;
	placeId: number;
	roleId: number;
	userLogin: string;
	isActive: boolean = true;
}
