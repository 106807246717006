import { PlaceModel } from '../../place.module/models/PlaceModel';

export class IngredientModele{
	id: number;
	name: string;
	price: number;
	placeId: number;
	type: number = 0;
	calories: number;
	place:PlaceModel = new PlaceModel(); 
	nameTranslationId: number = 0;
	isActive: boolean = true;
}