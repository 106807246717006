import {
  Component,
  HostBinding,
  Injector,
  ViewContainerRef,
  OnInit,
} from '@angular/core';
import { appInjector } from './bootstrap-components.module/utils/appInjector';
import { AppStore } from './shared/services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from './core/settings/settings.service';
import { ConnectionService } from 'ng-connection-service';
import { MessagingService } from './shared/services/messaging.service';
import {ToasterConfig} from 'angular2-toaster';
// import * as signalR from '@microsoft/signalr';
// import {HttpClient} from "./shared/services/HttpClient";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class.layout-fixed') get isFixed() {
    return this.settings.getLayoutSetting('isFixed');
  }
  @HostBinding('class.aside-collapsed') get isCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed');
  }
  @HostBinding('class.layout-boxed') get isBoxed() {
    return this.settings.getLayoutSetting('isBoxed');
  }
  @HostBinding('class.layout-fs') get useFullLayout() {
    return this.settings.getLayoutSetting('useFullLayout');
  }
  @HostBinding('class.hidden-footer') get hiddenFooter() {
    return this.settings.getLayoutSetting('hiddenFooter');
  }
  @HostBinding('class.layout-h') get horizontal() {
    return this.settings.getLayoutSetting('horizontal');
  }
  @HostBinding('class.aside-float') get isFloat() {
    return this.settings.getLayoutSetting('isFloat');
  }
  @HostBinding('class.offsidebar-open') get offsidebarOpen() {
    return this.settings.getLayoutSetting('offsidebarOpen');
  }
  @HostBinding('class.aside-toggled') get asideToggled() {
    return this.settings.getLayoutSetting('asideToggled');
  }
  @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }
  constructor(
    private injector: Injector,
    private vRef: ViewContainerRef,
    public settings: SettingsService,
    // private messagingService: MessagingService,
    private connectionService: ConnectionService,
    // public httpClient:HttpClient,
    public appStore: AppStore,
    public translate: TranslateService
  ) {
    appInjector.injector(this.injector);

    appInjector.viewContainerRef(this.vRef);
    translate.addLangs(['en-US', 'ru-RU', 'hy-AM']);
    translate.setDefaultLang('en-US');
    const browserLang = translate.getBrowserLang();
    translate.use(
      browserLang.match(/en-US|ru-RU|hy-AM/) ? browserLang : 'en-US'
    );
    // this.connectionService.monitor().subscribe(isConnected => {
    //   this.isConnected = isConnected;
    // })

    // const connection = new signalR.HubConnectionBuilder()
    //   .configureLogging(signalR.LogLevel.Information)
    //   .withUrl('https://api.inmenu.am/chat')
    //   .build();

    // connection.start().then(()=> {
    //   httpClient.get(`https://api.inmenu.am/api/Account/SetConnectionId?connectionId=${connection.connectionId}`).subscribe();

    // }).catch(function (err) {
    //   return console.error(err.toString());
    // });

    // connection.on("NewOrder",(data) => {
    //   console.log(data);
    // });
  }
  // message;
  toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-right',
    showCloseButton: true
  });
  busy = false;
  // isConnected = true;
  ngOnInit() {
    // this.messagingService.requestPermission();
    // this.messagingService.receiveMessage();
    // this.message = this.messagingService.currentMessage;
    window['$appStore'] = this.appStore;
  }
  ngAfterViewInit() {
    this.appStore.$('ui.loading').subscribe((b) => {
      setTimeout(() => {
        this.busy = b;
      }, 0);
    });
  }
}
