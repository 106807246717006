import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TableModel } from '../models/TableModel';


export class TakeTableModel {
    tableIds: Array<number>;
}

@Injectable()
export class TablesService extends CrudService<TableModel> {

    protected serviceItemsKey = 'table';

    constructor(protected store: AppStore) {
        super('Table', null);
    }

    public preloadData() {
        return [
            this.getAllTables(),
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

    public getAllTables() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetAll'))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }
    public tableGroups() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/TableGroups'))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }
    public takeTables(tableIds) {
        const model = new TakeTableModel();
        model.tableIds = tableIds;
        return this.apirequest('post', this.apiCallTo(this.serviceApiUrl + '/TakeTables'), null, model)
            .pipe(
                map(i => this.getApiResponseModel(i)),

            );
    }
}
