import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { KeyValuePair } from '../../../models/KeyValuePair';
import { OptionModel } from '../../models/OptionModel';
import { OptionInsertModel } from '../../models/OptionModel';
//services
import { OptionService } from "../../services/option.service";
import { MenuService } from "../../../menu.module/services/menu.service";
import { MenuItemService } from "../../../menu.module/services/menu.service";
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
	selector: 'app-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss']
})
export class OptionEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private activeRoute: ActivatedRoute,
		private menuItemService: MenuItemService,
		private menuService: MenuService,
		protected translate:TranslateService,
		private optionService: OptionService) {
		super();
	}

	public file: any = null;
	// public menuId: number = 1;
	public menu: string = ""
	public menuitem: string = ""
	public menuList: Array<KeyValuePair> = [];
	public optionId:number;
	public menuItemList: Array<KeyValuePair> = [];
	public option: OptionModel = new OptionModel();
  clicked:boolean=false;
  public typelist: Array<any> = [

	new KeyValuePair(0, 'Removable'),
	new KeyValuePair(1, 'Additional'),

	];

	ngOnInit() {

		this.option.menuItemId = this.activeRoute.snapshot.params['menuItemId'];

		// this.menuService.getAll().subscribe(res => {
		// 	this.menuList = res.map(k => new KeyValuePair(k.id, k.name));
		// });
		if(!! this.option.menuItemId ) {

			this.menuItemService.get(this.option.menuItemId).subscribe(resp=> {
				// this.menuId = resp.menuId;
				this.menuitem = resp.name;
					this.menuService.get(resp.menuId).subscribe(r => {
						this.option.placeId = r.placeId;
						this.menu = r.name;
					});
			})
		}

		// this.menuItemService.getAllMenuItems(this.menuId).subscribe(res => {
		// 	this.menuItemList = res.map(k => new KeyValuePair(k.id, k.name));
		// });

		if (this.isEdit) {
			 this.optionId = this.activeRoute.snapshot.params['id'];
			this.optionService.get(this.optionId).subscribe(resp => {
				if (!!resp) {
					this.option = resp;
					this.menuItemService.get(resp.menuItemId).subscribe(res=> {
						// this.menuId = res.menuId;
						this.menuitem = res.name;
							this.menuService.get(res.menuId).subscribe(r => {
								this.menu = r.name;
								this.option.placeId = r.placeId;
							});
					})
				}else {
					this.alertService.showError(this.translate.instant("alerts.SOMETHINGWENTWRONG"));
					this.back();
				}
			});
		}
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public isFormValid() {

		if (!super.isFormValid()) {
			this.alertService.showError(this.translate.instant("alerts.INVALID"));
			return false;
		}
		return true;
	}

	public changeMenuItemList(event) {
		this.menuItemService.getAllMenuItems(event).subscribe(res => {
			this.menuItemList = res.map(k => new KeyValuePair(k.id, k.name));
		});
	}

	public createInsertModel(option: OptionModel): OptionInsertModel {

		let insertModel: OptionInsertModel = new OptionInsertModel();

		insertModel.id = option.id;
		insertModel.type = option.type;
		insertModel.title = option.title;
		insertModel.placeId = option.placeId;
		insertModel.menuItemId = option.menuItemId;
		insertModel.description = option.description;
		insertModel.titleTranslationId = option.titleTranslationId;
		insertModel.descriptionTranslationId = option.descriptionTranslationId;
		insertModel.isActive = option.isActive;

		return insertModel;
	}

	public save() {

		if (!this.isFormValid()) return;

		let insertModel = this.createInsertModel(this.option);

		if (!this.option.id ) {
      this.clicked=true;
			this.optionService.save(insertModel).subscribe(data => {
			  this.clicked=false;
				if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.OPTIONADDEDSUCCESSFULLY"));
					this.optionService.updateData({id:data.data}).subscribe(res => {
						this.back();
					})
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});

		} else {
      this.clicked=true;
			this.optionService.save(insertModel).subscribe(data => {
        this.clicked=false;
        if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.OPTIONUPDATEDSUCCESFULLY"));
					this.optionService.updateData({id:insertModel.id}).subscribe(res => {
						this.back();
					})
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});
		}
	}

}
