import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IntroModel } from '../models/IntroModel';

@Injectable()
export class IntroService extends CrudService<IntroModel> {

    protected serviceItemsKey = 'intro';

    constructor(protected store: AppStore) {
        super('Intro', null);
    }

     public preloadData() {
        return [
            this.getAllIntro(),
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

    public getAllIntro() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetAll'))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

}