import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OrderModel, UpdateModel } from '../models/OrderModel';

@Injectable()
export class OrderService extends CrudService<OrderModel> {
  protected serviceItemsKey = 'order';

  constructor(protected store: AppStore) {
    super('Order', null);
  }

  public preloadData() {
    return [this.getAllOrders()];
  }

  public loadData() {
    return forkJoin(this.preloadData());
  }

  public getAllOrders(page = 1, pagesize = 10) {
    return this.apirequest(
      'get',
      this.apiCallTo(
        this.serviceApiUrl + `/GetAll?page=${page}&pagesize=${pagesize}`
      )
    ).pipe(
      map((i) => i.data),
      tap((d) => this.appStore.set(this.serviceItemsKey, d))
    );
  }

  public get(id) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + '/Get?id=' + id)
    ).pipe(map((r) => r.data));
  }

  public setIsActive(isActive: boolean, orderId: number) {
    return this.apirequest(
      'get',
      this.apiCallTo(
        this.serviceApiUrl +
          `/SetIsActive?isActive=${isActive}&orderId=` +
          orderId
      )
    ).pipe(map((r) => r.data));
  }

  public getFiltered(filter: any) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl + `/GetFiltered`),
      null,
      JSON.stringify(filter)
    ).pipe(map((i) => i.data));
  }


  public closeTheBill(orderId: number, serveAll: boolean) {
    return this.apirequest(
      'get',
      this.apiCallTo(
        this.serviceApiUrl +
          `/CloseTheBill?orderId=${orderId}&serveAll=${serveAll}`
      )
    ).pipe(map((i) => i.data));
  }
  public UpdateStatus(model: UpdateModel) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl + `/UpdateStatus`),
      null,
      JSON.stringify(model)
    ).pipe(map((i) => i.data));
  }
  public ReverseTransaction(id: number) {
    return this.apirequest(
      'get',
      this.apiCallTo(`/Transaction/ReverseTransaction?id=${id}`)
    ).pipe(map((i) => i.data));
  }
}
