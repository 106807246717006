﻿import { urls } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Response, URLSearchParams } from '@angular/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, finalize, tap } from 'rxjs/operators';
import { appInjector } from '../../bootstrap-components.module/utils/appInjector';

// services
import { HttpClient } from './HttpClient';
import { LoadingService } from './loading.service';
import { StorageService } from './storage.service';
import { AppStore } from './store.service';
import { AlertsService } from './alerts.service';
import { ModalsService } from '../../bootstrap-components.module/modals/modals.service';

// models
const modals: any = {};

import { ApiResponseModel } from '../models/ApiResponseModel';
import { Console } from 'console';

export class BaseService {
  protected apiBaseUrl;
  protected router: Router;
  protected appStore: AppStore;
  private httpClient: HttpClient;
  public storage: StorageService;
  private loading: LoadingService;
  private modalsService: ModalsService;
  protected alertsService: AlertsService;

  constructor() {
    const injector = appInjector.injector();
    this.apiBaseUrl = this.getApiBaseUrl();
    this.httpClient = injector.get(HttpClient);
    this.storage = injector.get(StorageService);
    this.alertsService = injector.get(AlertsService);
    this.modalsService = injector.get(ModalsService);
    this.loading = injector.get(LoadingService);
    this.router = injector.get(Router);
    this.appStore = injector.get(AppStore);
  }

  get isAuthenticated() {
    let token = this.storage.get(this.storage.Keys.Token);
    return (token != undefined && token != null && token != '');
  }

  public anonymousrequest(method: string, url: string, options?, body?, errorHandler?) {
    let request = new Observable<Response | ApiResponseModel>();
    switch (method.toLowerCase()) {
      case 'get': {
        request = this.httpClient.anonymousGet(url, options);
        break;
      }
      case 'post': {
        request = this.httpClient.anonymousPost(url, body, options);
        break;
      }
      default: break;
    }

    return request.pipe(
      map(response => {
        return this.getApiResponseModel(response);
      }),
      tap(data => {
      }),
      catchError((error) => {

        if (errorHandler) {
          errorHandler(error);
        } else {
          this.handleError(error);
        }
        return Observable.throw(error);
      }),
      finalize(() => {
      }));
  }

  private handleError(error) {
    if (error) {
      switch (error.status) {
        case 401: {
          this.showModal401();
          break;
        }
        case 403: {
          this.showModal403();
          break;
        }
        case 404: {
          this.alertsService.showError('The resource was not found. Please try again later or contact administrator!');
          break;
        }
        default: {
          let apiResponse = this.getErrorApiResponseModel(error);
          //// console.log(error);
          //// console.log(apiResponse);

          // if don't have rights to access this resource navigate to  dashboard
          if (apiResponse.statusCode === 9002) {
            this.alertsService.showWarning(apiResponse.errors.join(' '));
            this.router.navigateByUrl('/order/list');
          } else
          if (apiResponse.statusCode === 9001) {
            // this.showSelectCompany();
            // this.appStore.dispatchAction("change-company", true);
            this.alertsService.showInfo('You don\'t have any company selected.<br/> You can select one by pressing on button on top right corner!');
          } else {
            this.alertsService.addApiResponse(apiResponse);
          }
          break;
        }
      }
    }
  }

  private showModal403() {
    if (modals['403']) { return; }

    modals['403'] = 'on';
    this.modalsService.confirm('Access Forbiden',
      'Your are not authorized to view this resource. Do you want to login with other credentials!')
      .option.subscribe(option => {
      if (option == true) {
        this.router.navigateByUrl('/logout');
      }
      // apiHttpCodeResponse["403"] = false;
      delete modals['403'];
    });
  }

  private showModal401() {
    if (modals['401']) { return; }
    modals['401'] = true;
    // user is unauthorized
    if (this.storage.get(this.storage.Keys.RememberMe) == 'true') {
      // add login to renew token
      this.alertsService.showInfo('Token was renewed');
    } else {
      this.modalsService.confirm('Not Authorized',
        'Your session is no longer available. Do you want to login again!')
        .option.subscribe(option => {
        if (option == true) {
          this.router.navigateByUrl('/logout');
        }
        // apiHttpCodeResponse["401"] = false;
        delete modals['401'];
      });
    }
  }
  public refreshtoken() {
    return this.apirequest('post', this.apiCallTo(this.apiBaseUrl +'/Account/RefreshToken'), null, this.storage.get(this.storage.Keys.RefreshToken))
      .pipe(
        map(i => i.data)
      );
  }
  public apirequest(method: string, url: string, options?, body?, hasFiles = false, handleError = true): Observable<any> {
    let request = new Observable<Response | ApiResponseModel>();
    switch (method.toLowerCase()) {
      case 'get': {
        request = this.httpClient.get(url, options);
        break;
      }
      case 'post': {
        this.loading.startLoading();
        request = this.httpClient.post(url, body, options, hasFiles);
        break;
      }
      case 'put': {
        request = this.httpClient.put(url, body, options, hasFiles);
        break;
      }
      case 'delete': {
        request = this.httpClient.delete(url, options);
        break;
      }
      default:
        Observable.throw(new Error('Http method not allowed!'));
        break;
    }
    return request.pipe(
      map(response => {
        // this.loading.stopLoading();
        return this.getApiResponseModel(response);
      }),
      // tap(d => this.loading.stopLoading()),
      catchError((error) => {
        // this.loading.stopLoading();
        if (handleError) {
          this.handleError(error);
        }
        return throwError(error);
      }),
      // tap(d => {
      //     //console.log(d)
      //     if (method.toLowerCase() != "get") {
      //         //console.log(d)
      //         this.appStore.set("app.actions", new AppAction("load-logs"))
      //     }
      // }),
      finalize(() => {
        this.loading.stopLoading();
      }));
  }

  protected createErrorResponse(messages: Array<any>) {
    let response = new ApiResponseModel();
    response.errors = messages;
    response.statusCode = 400;
    return of(response);
  }

  protected createSuccessResponse(messages: Array<any>) {
    let response = new ApiResponseModel();
    response.errors = messages;
    response.statusCode = 400;
    return Observable.create(response);
  }

  protected createSuccessDataResponse(data: any) {
    let response = new ApiResponseModel();
    response.errors = [];
    response.data = data;
    response.statusCode = 400;
    return of(response);
  }

  protected createSuccessObservable(obj: any) {
    return of(obj);
  }

  public getErrorApiResponseModel(errorResponse): ApiResponseModel {
    return ApiResponseModel.fromResponse(errorResponse);
  }

  public getApiResponseModel(response): ApiResponseModel {
    // let responseResult = new ApiResponseModel();
    // return responseResult.create(response);)
    return ApiResponseModel.fromResponse(response);
  }

  public apiCallFor(...args: string[]) {
    return `${this.apiBaseUrl}/${this.createUrl(...args)}`;
  }

  public apiCallTo(url: string) {
    if (url.startsWith('/')) {
      url = url.slice(1, url.length);
    }
    return this.apiBaseUrl + '/' + url;
  }

  createUrl(...args: string[]) {
    const fragments = args.map((a: string) => {
      let param = a;
      if (param.startsWith('/')) {
        param = param.slice(1, param.length);
      }
      if (param.endsWith('/')) {
        param = param.slice(0, param.length - 1);
      }
      return param;
    });
    return fragments.join('/');
  }

  private getApiBaseUrl() {
    return urls.apiBaseUrl;
  }

  urlEncode(obj: Object): string {
    const urlSearchParams = new URLSearchParams();
    for (const key in obj) {
      urlSearchParams.append(key, obj[key]);
    }
    return urlSearchParams.toString();
  }

  public addInitMessage(message) {
    //// console.log(message);
    // this.appStore.set(SharedStoreKeys.app_load_messages, message);
  }
}
