import { ImageModel } from '../../models/ImageModel';
import { ContactModel } from '../../models/ContactModel';
import { PlaceModel } from '../../place.module/models/PlaceModel';
import { AddressLocationModel } from '../../models/AddressLocationModel';
import { UserProfileModel } from '../../account.module/models/UserProfileModel';

export class OrganisationModel {
	id: number;
	name: string;
	description: string;
	ownerId: number;
	type: number;
	tin: string;
	balance: number;
	bank: string;
	bankAccount: string;
	addressLocation: AddressLocationModel = new AddressLocationModel();
	contact: ContactModel = new ContactModel();
	imageIds: Array<number>;
	logoId: number;
	coverId: number;
	logoUrl: ImageModel = new ImageModel();
	coverUrl: ImageModel = new ImageModel();
	imageUrls: Array<ImageModel> = [];
	places: Array<PlaceModel>;
	bankAccounts: Array<any>;
	transactions: any;
	admins: Array<any>;
	owner: UserProfileModel = new UserProfileModel();
	isActive: boolean = true;
}

export class OrganisatioInsertnModel {
	id: number;
	name: string;
	description: string;
	ownerId: number;
	tin: string;
	balance: number;
	bank: string;
	bankAccount: string;
	contact: ContactModel = new ContactModel();
	imageIds: Array<number> = [];
	logoId: number;
	coverId: number;
	isActive: boolean = true;
}