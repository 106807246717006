import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
//components
import { IngredientShellComponent } from './ingredient-shell.component';
import { IngredientListComponent } from './ingredient/list/list.component';
import { IngredientEditComponent } from './ingredient/edit/edit.component';

export const ingredient = [
  {
    path: "list",
    component: IngredientListComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "add",
    component: IngredientEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "edit/:id",
    component: IngredientEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  }
]
