import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
//components
import { IntroShellComponent } from './intro-shell.component';
import { IntroEditComponent } from './intro/edit/edit.component';
import { IntroListComponent } from './intro/list/list.component';

export const intro = [

     {
        path: "list",
        component: IntroListComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "add",
        component: IntroEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "edit/:id",
        component: IntroEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      }
]
