import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";
import { IngredientService } from "./services/ingredient.service";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class IngredientShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private ingredientService: IngredientService) {
        super();
    }

    ngOnInit() {
        // if (!this.moduleDataLoaded) {
        //     this.ingredientService.loadData().subscribe(
        //         r => {
        //             this.alertService.showInfo(`Ingredient data has been loaded!`);
        //             this.goToUrl('/ingredient/list');
        //         },
        //         e => {
        //             this.alertService.showError(`Loading Ingredient data had encounter error.`);
        //             this.goToUrl('/dashboard');
        //         });
        // } else {
        //     this.alertService.showInfo(`Ingredient data has been loaded!`);
        //     this.goToUrl('/ingredient/list');
        // } 
    }

    get moduleDataLoaded() {
        const storeKeysToVerify = ['ingredient'];
        return storeKeysToVerify.map(k => !!this.store._(k)).
        reduce((acc, val) => {
            return acc && val;
        }, true)
    }
}