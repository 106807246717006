import { SortingPropertiesModalComponent } from './sortingProperties-modal/sortingProperties-modal.component';
import {Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { PropertyModele } from '../../models/PropertyModele';
//services
import { PropertyService } from '../../services/propery.services';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {ImportOptionsModalComponent} from "../../../menu.module/menu/edit/menu-item/importOptions-modal/importOptions-modal.component";
import {saveAs} from "file-saver";
import {ImportPropertiesModalComponent} from "./importProperties-modal/importProperties-modal.component";
import {ModalsService} from "../../../bootstrap-components.module/modals/modals.service";
@Component({
  selector: 'app-property-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class PropertyListComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private activeRoute: ActivatedRoute,
		private modalService:ModalsService,
		private propertyService: PropertyService) {
		super();
	}

	public propertyList: Array<PropertyModele> = [];
	public data: Array<PropertyModele> = [];
	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'name', name: 'name' },
	{ title: 'settings', name: 'settings' },

	];
	private modalRef:any = null;
  @Input() optionId:number;
	ngOnInit() {

		this.propertyService.GetAllByOptionId(this.optionId).subscribe(data => {
			this.propertyList = data;
		});
	}
  public import()
  {
    this.modalRef = this.modalService.simpleLg("Import Properties", ImportPropertiesModalComponent, {
      optionId:this.optionId
    });
  }
  public export()
  {
    this.propertyService.Export(this.optionId).subscribe(data=>
    {
      var blob = new Blob([data],{type:`text/csv`});
      saveAs(blob,"Properties.csv");
    })
  }
  public openSortmodal()
  {
    this.modalRef = this.modalService.simpleLg("Sort Properties", SortingPropertiesModalComponent, {
      optionId:this.optionId,
    });
  }
	public save(property: PropertyModele ) {
		this.propertyService.save(property).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess('Property updated successfully.');
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}
}
