import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { IntroModel } from '../../models/IntroModel';
//services
import { IntroService } from "../../services/intro.service";
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
	selector: 'intro-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss']
})
export class IntroEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		public introService :IntroService,
		protected translate:TranslateService,
		private activeRoute: ActivatedRoute) {
		super();
	}

	image;
	intro: IntroModel = new IntroModel();
  clicked:boolean=false;


  ngOnInit() {

		if (this.isEdit) {

			let introId = this.activeRoute.snapshot.params['id'];
			this.introService.get(introId).subscribe(res => {
				if (!!res) {
					this.intro = res;
				}else {
					this.alertService.showError(this.translate.instant("alerts.SOMETHINGWENTWRONG"));
					this.back();
				}
			});
		}

	}

    delete(url) {
      this.introService.deleteFile(url).subscribe(res=> {
        if (res) {
          this.introService.updateData({id:this.intro.id}).subscribe(res => {
            this.intro = res.data;
            this.alertService.showSuccess(this.translate.instant("alerts.IMAGEISDELETED"));
          });
        }
      })
    }

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public imageUpload(): Promise<any> {
		return new Promise((resolve, reject) => {
			if(this.image) {
				this.introService.multipleUploadFile(this.image).subscribe(res => {
					resolve(res.data[0]);
				});
			} else {
				resolve();
			}
		});
	}

	public save() {

		if (!this.isFormValid()) return;

		if (!this.intro.id) {
      this.clicked=true;
      this.imageUpload().then(image => {

				if (!!image) { this.intro.imageId = image.id; this.intro.imageUrl = image.url; }

				if(!!this.intro.imageId ) {

					this.introService.save(this.intro).subscribe(d => {
            this.clicked=false;
						if(!d.hasError) {
							this.introService.updateData({id:d.data}).subscribe(res => {
								this.alertService.showSuccess(this.translate.instant("alerts.INTROADDEDSUCCSESSFULLY"));
								this.back();
							})
						} else {
							let errors = d.errors.map(r => {return r.message});
							this.alertService.showError(errors);
						}
					});
				} else {
          this.clicked=false;
          this.alertService.showError(this.translate.instant("alerts.IMAGEFIELDISEMPTY"));
				}
			});
		} else {
      this.clicked=true;
      this.imageUpload().then(image => {

				if (!!image) { this.intro.imageId = image.id; this.intro.imageUrl = image.url; }

				this.introService.save(this.intro).subscribe(d => {
          this.clicked=false
          if(!d.hasError) {
						this.introService.updateData({id:this.intro.id}).subscribe(res => {
							this.alertService.showSuccess('Intro updated successfully.');
							this.back();
						})
					} else {
						let errors = d.errors.map(r => {return r.message});
						this.alertService.showError(errors);
					}
				});
			});
		}
	}

	public isFormValid() {
		if (!super.isFormValid()) {
			this.alertService.showError("The form is not valid");
			return false;
		}
		return true;
	}

	public back() {
		this.router.navigateByUrl("intro/list");
	}
}
