import { Injectable } from "@angular/core";

@Injectable()
export class TranslationsProvider {
    private translations = {};
    constructor() {
        //console.log("->>->>->>->>Default TranslationsProvider");
        this.refreshTranslations();
    }

    getTranslationFor(text: string): string {
        if (localStorage.getItem("save-translations-keys") === "true" && text) {
            let translationsKeys = {};
            try {
                translationsKeys = JSON.parse(localStorage.getItem("translations-keys"));
            } catch (e) {
            }
            translationsKeys = translationsKeys || {};
            translationsKeys[text] = text;
            localStorage.setItem("translations-keys", JSON.stringify(translationsKeys));
        }

        text = text || '';
        return this.storageTranslations[text.trim()] || text;
    }

    private get storageTranslations() {
        const storageTrasnlations = localStorage.getItem("languageTranslations");

        let translations = {};
        if (storageTrasnlations) {
            try {
                translations = JSON.parse(storageTrasnlations);
            } catch (e) {
                //console.log('Cannot parse storage translations:', storageTrasnlations);
            }
        }
        return translations || {};

    }

    refreshTranslations() {
        //localStorage.setItem("translations", JSON.stringify(trans));
        //const storageTrasnlations = localStorage.getItem("translations");
        const storageTrasnlations = localStorage.getItem("languageTranslations");

        if (storageTrasnlations) {
            try {
                this.translations = JSON.parse(storageTrasnlations);
            } catch (e) {
                //console.log('Cannot parse storage translations:', storageTrasnlations);
            }
        }

        this.translations = this.translations || {};
        //console.log("Translations:", this.translations);
    }
}