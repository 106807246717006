import { ReflectiveInjector, ViewContainerRef, OnInit, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { AlertsService } from "../services/alerts.service";
import { ApiResponseModel } from "../models/ApiResponseModel";
import { appInjector } from "../../bootstrap-components.module/utils/appInjector";
import { CrudService } from '../services/crud.service';
import { AppStore } from '../services/store.service';
import { StorageService } from '../services/storage.service';
import { urls } from '../../../environments/environment';
import {Location} from '@angular/common';
import {TranslateService} from "@ngx-translate/core";


declare var moment;
export abstract class BaseComponent implements OnDestroy {

  protected alertService: AlertsService;
  //    protected Auth: AuthenticationService;
  protected router: Router;
  protected translate:TranslateService;
  protected activatedRoute: ActivatedRoute;

  protected isBusy = false;
  protected vRef: ViewContainerRef;
  protected storageService: StorageService;
  public store: AppStore;
  public formId: string = "form";
  protected _location: Location;


  constructor() {
    let injector = appInjector.injector();

    this.alertService = injector.get(AlertsService);
    this.router = injector.get(Router);
    this.vRef = injector.get(ViewContainerRef);
    this.translate = injector.get(TranslateService);
    this.store = injector.get(AppStore);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.storageService = injector.get(StorageService);
    this._location = injector.get(Location);

  }


    public get isUserAuthenticated() {
        const tokenExistance = this.storageService.get(this.storageService.Keys.Token);
        //const loggedUser = this.storageService.getAsObject(this.storageService.Keys.LoggedUserInfo);
        return !!tokenExistance;
    }

    public back() {
        this._location.back();
    }

    public getApiResponseModel(response): ApiResponseModel {
        // let responseResult = new ApiResponseModel();
        // return responseResult.create(response);
        return ApiResponseModel.fromResponse(response);
    }

    protected MakeCopy(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    public refreshData(service: CrudService<any>) {
        service.getAll(true);
    }

    updateUrlParams(obj) {
        if (!obj) return;

        if (!this.activatedRoute.snapshot.queryParams || !obj ||
            JSON.stringify(this.activatedRoute.snapshot.queryParams) === JSON.stringify(obj)) return;

        let queryParams = { ...this.activatedRoute.snapshot.queryParams, ...obj };
        // navigate to same route with params changed
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                ...queryParams
            }
        });
    }



    public goToUrl(url, returnUrl: string = null, params: object = null, clearQueryParams = false) {
        if (clearQueryParams === true) {
            this.router.navigateByUrl(url);
        } else {
            let queryParams = returnUrl ? { returnUrl: returnUrl } : null;
            queryParams = params == null ? queryParams : { ...queryParams, ...params };
            this.router.navigate([url], { queryParamsHandling: "merge", queryParams });
        }
    }

    goToHomeUrl(returnUrl = "") {
        window.location.href = urls.appBaseUrl + (returnUrl != "" ? "/#/" + returnUrl : '');
    }

    goToDashboard() {
        this.router.navigateByUrl("/order/list");
    }

    onKeyUp($event) {

        $event.preventDefault();
        if ($event.keyCode === 13) {
            event.preventDefault()
            const inputs = Array.prototype.slice.call(document.querySelectorAll("input,select,textarea,button"))
            const index = (inputs.indexOf(document.activeElement) + 1) % inputs.length
            const input = inputs[index]
            input.focus();
        }
    }

    disableForm(disable = true, otherformId = null) {
        // const formId = otherformId || "main-content";
        // const formSelector = formId.indexOf("#") == 1 ? formId : "#" + formId;
        //$(formSelector).find("input,textarea").attr("disabled", disable)

        const _formId = this.getFormId(otherformId);
        const inputs = Array.prototype.slice.call(document.getElementById(_formId).querySelectorAll("input,select,textarea,.select"))
        inputs.forEach(input => {
            input.disabled = true;
        });

        // const index = (inputs.indexOf(document.activeElement) + 1) % inputs.length
        // const input = inputs[index]
        // input.disable();
    }


    private getFormId(formId) {
        let _formId: string = formId || this.formId;
        return _formId.replace('#', '');
    }

    public isFormValid(formId: string = null, showWarning = false) {
        const _formId = this.getFormId(formId);
        let nodes = document.getElementById(_formId).querySelectorAll('.input, .dropdown');
        let fields: Array<any> = Array.prototype.slice.call(nodes);
        const invalidFields = fields.filter(f => f.getAttribute("isvalid") === "false");
        const result = invalidFields.length <= 0;
        setTimeout(() => {
            invalidFields.reverse().forEach(f => {
                //f.setAttribute('pristine', 'false');
                let input = f.querySelector('input, button');
                if (input) {
                    input.focus();
                }
                //console.log(`Form ${_formId} is not valid. Invalid Fields:`, invalidFields)
                if (showWarning) {
                    this.alertService.showError(this.translate.instant("alerts.INVALID"));
                }
            })
        });
        return result;
    }

    notAValidForm(formId: string = null) {
        if (!this.isFormValid(formId)) {
            this.alertService.showError("");
            return true;
        }
        return false;
    }

  initializeApp(returl:string) {
    //this.goToUrl("/initialize")
    this.router.navigate(['/initialize'],{queryParams:{returnurl:returl}})
  }
    reloadApp() {
        this.store.dispatchAction("app.reload", true);
    }

    reloadComponent() {
        this.store.dispatchAction("reloadComponent", "true");
    }

    // get userPreferences() {
    //     return this.storageService.getAsObject(this.storageService.Keys.UserPreferences) || {};
    // }

    get accountRights(): Array<string> {
        return this.store._("account").rights || { rights: [] };
    }

    get currentUser() {
        return {
            isAuthenticated: this.isUserAuthenticated,
            profile: this.store._('account.loggedUser')
        };
    }

    ngOnDestroy() {
        ////console.log("[Component Destoyed:] ", typeof (this));
    }

    get moment() {
        return {
            fromNowInDays: (date) => {
                return (moment().diff(date, 'days') || 0) + 1;
            }
        };
    }

    fix2(number) {
        return Number.parseFloat(number).toFixed(2);
    }
}
