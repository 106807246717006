import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
// models
import { CreateUserModel } from '../models/CreateUserModel';
import { UserProfileModel } from '../models/UserProfileModel';
import { ChangePasswordModel } from '../models/ChangePasswordModel';
// services
import { AppStore } from '../../shared/services/store.service';
import { BaseService } from '../../shared/services/BaseService';
import { CrudService } from './../../shared/services/crud.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AccountService extends CrudService<UserProfileModel> {
  private accountApi = 'Account/';

  constructor(private store: AppStore, protected translate: TranslateService) {
    super('Account', null);
  }

  public preloadData(): Array<Observable<any>> {
    return [this.loadUserProfile];
  }

  public loadData() {
    forkJoin(this.preloadData()).subscribe(() => {
      this.alertsService.showInfo(
        this.translate.instant('alerts.ACCOUNTDATALOADED')
      );
    });
  }

  get loadUserProfile() {
    return this.apirequest('get', this.apiCallTo('Account/Info')).pipe(
      map((r) => r.data),
      map((d) => {
        const result = new UserProfileModel();
        result.userName = d.username;
        result.email = d.email;
        result.password = d.password;
        result.confirmPassword = d.confirmPassword;
        result.phoneNumber = d.phoneNumber;
        result.role = d.role;
        result.roles = d.roles;
        result.lastName = d.lastName;
        result.firstName = d.firstName;
        result.countryId = d.countryId;
        result.organisation = d.organisation;
        result.imageUrl = d.imageUrl;
        result.isActive = d.isActive;

        return { ...d, userProfile: result };
      }),
      tap((d) => {
        this.store.set('account.loggedUser', d);
      }),
      tap((d) => {
        this.storage.save(this.storage.Keys.LoggedUserInfo, d.userProfile);
      })
    );
  }

  get userData() {
    let loggedUser = this.storage.getAsObject(this.storage.Keys.LoggedUserInfo);
    loggedUser = loggedUser || {};
    return loggedUser.userProfile || {};
  }

  public getAllOrganisationsUser(id: number) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.accountApi + `getorganisationusers?orgid=${id}`)
    ).pipe(
      map((i) => i.data)
      // tap(d => this.appStore.set(this.serviceItemsKey, d))
    );
  }
  public getUser(orgId, userId) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.accountApi + `GetByUserId?orgId=${orgId}&userId=${userId}`)
    ).pipe(
      map((i) => i.data)
      // tap(d => this.appStore.set(this.serviceItemsKey, d))
    );
  }

  public createUser(user: CreateUserModel) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.accountApi + 'CreateUser'),
      null,
      JSON.stringify(user)
    );
  }

  public updateProfile(profile) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.accountApi + 'EditWithoutPassword'),
      null,
      JSON.stringify(profile)
    ).pipe(tap((r) => this.store.set('account.loggedUser', r.data)));
  }

  public changePassword(model: ChangePasswordModel) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.accountApi + 'changepassword'),
      null,
      JSON.stringify(model)
    )
      .pipe
      // tap(r => this.store.set('account.loggedUser', r.data))
      ();
  }

  public deleteAccount() {
    return this.apirequest(
      'delete',
      this.apiCallTo(this.accountApi + 'deleteaccount')
    ).pipe(
      tap(() => this.store.set('account.loggedUser', undefined)),
      tap(() => this.router.navigateByUrl('/auth/logout'))
    );
  }

  public deactivate(id) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.accountApi + `deactivate?id=${id}`)
    ).pipe(map((i) => i.data));
  }

  public activate(id) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.accountApi + `ActivateUser?id=${id}`)
    ).pipe(map((i) => i.data));
  }
}
