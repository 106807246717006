// import { routes } from './account.routes';
import { RouterModule } from '@angular/router';
import { AccountService } from './services/account.service';
import { SharedModule } from '../shared/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { AccountStore } from './services/account.store';
import { DataStore } from '../shared/models/DataStore';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';
import { ProfileComponent } from './profile/profile.component';

@NgModule({
    imports: [
        SharedModule.forRoot(),
        RouterModule
    ],
    declarations: [
        // AccountProfileComponent,
    ProfileComponent],
    exports: [
        // Providers, Components, directive, pipes
        // AccountProfileComponent,
    ],
    providers: [AccountService]
})
export class AccountModule {

    static initializeModuleData() {
        const injector = appInjector.injector();
        const store = injector.get(AppStore);
        // this.loadRoutes(store);
        this.loadData(store);
    }

    // static routerRoutes() {
    //     return [
    //         {
    //             path: "account",
    //             children: routes,
    //         }
    //     ];
    // }

    private static moduleServices() {
        const accountService = appInjector.injector().get(AccountService);
        return {
            accountService
        };
    }


    static preloadData() {
        const services = this.moduleServices();
        return {
            routes: [],
            resources: [
                ...services.accountService.preloadData()
            ]
        };
    }

    private static loadData(store) {
        const services = this.moduleServices();
        AccountStore.prototype.initializeStore.call(store);
        services.accountService.loadData();
    }
}
