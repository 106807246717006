import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/account.module/services/account.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { KeyValuePair } from 'src/app/models/KeyValuePair';
import { AuthenticationService } from 'src/app/routes/pages/services/authentication.service';
import { AuthenticatedComponent } from 'src/app/shared/components/AuthenticatedComponent';
import { AlertsService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-profil',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends AuthenticatedComponent implements OnInit {
  countries;
  valForm: FormGroup;
  genders: Array<KeyValuePair> = [
    new KeyValuePair(0, 'male'),
    new KeyValuePair(1, 'female'),
    new KeyValuePair(2, 'other'),
  ];
  constructor(
    public settings: SettingsService,
    fb: FormBuilder,
    private authService: AuthenticationService,
    private accountService: AccountService,
    private alertsService: AlertsService
  ) {
    super();
    this.valForm = fb.group({
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email]),
      ],
      phoneNumber: [null, Validators.required],
      countryId: [1366, Validators.required],
      gender: ['', Validators.required],
      birthDate: [new Date(), Validators.required],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
    });
  }
  submitForm(value: any) {
    this.accountService.updateProfile(this.valForm.value).subscribe((data) => {
      if (!data.data) {
        const errors = data.errors.map((r) => {
          return r.message;
        });
        this.alertService.showError(errors);
      } else {
        this.alertService.showSuccess(
          this.translate.instant('account.PROFILEUPDATEDSUCCESSFULLY')
        );
        const user = this.accountService.loadUserProfile.subscribe();
      }
    });
  }
  ngOnInit() {
    const user = this.store.getObjectFromLocalStorage(
      this.storageService.Keys.LoggedUserInfo
    );
    this.valForm.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNUmber: user.phoneNumber,
      email: user.email,
      gender: user.gender,
      birthDate: user.birthDate,
      countryId: user.countryId,
    });
    this.authService.Countries().subscribe((data) => {
      this.countries = data;
    });
  }
  enableForm() {
    (document.getElementById('first') as HTMLInputElement).disabled = false;
    (document.getElementById('last') as HTMLInputElement).disabled = false;
    (document.getElementById('date') as HTMLInputElement).disabled = false;
    (document.getElementById('gender') as HTMLInputElement).disabled = false;
    (document.getElementById('country') as HTMLInputElement).disabled = false;
    (document.getElementById('submit') as HTMLInputElement).hidden = false;
    (document.getElementById('edit') as HTMLInputElement).hidden = true;
  }
}
