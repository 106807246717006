import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";

import { CategoryShellComponent } from './category-shell.component';
import { CategoryListComponent } from './category/list/list.component';
import { CategoryEditComponent } from './category/edit/edit.component';

export const category = [
     {
        path: "list",
        component: CategoryListComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "add",
        component: CategoryEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "edit/:id",
        component: CategoryEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "child/add/:parentId",
        component: CategoryEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      }
]
