import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";

import { AdditionalShellComponent } from './additional-shell.component';
import { AdditionalListComponent } from './additional/list/list.component';
import { AdditionalEditComponent } from './additional/edit/edit.component';

export const additional = [

     {
        path: "list",
        component: AdditionalListComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "add",
        component: AdditionalEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "edit/:id",
        component: AdditionalEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      }

]
