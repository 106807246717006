import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { SimpleInputComponent } from '../_input-component/input.component';

@Component({
    selector: 'checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css'],
})
export class CheckboxComponent extends SimpleInputComponent {
    @Input() switch: boolean = false;

    @Output() valueChange = new EventEmitter();
    @Output() clicked = new EventEmitter();

    private __value: boolean;
    @Input() get value() {
        return this.__value;
    }

    set value(val) {
        this.__value = !!val;
        this.valueChange.emit(this.__value);
        this.clicked.emit(this.__value);
    }

    constructor() {
        super();
    }
}
