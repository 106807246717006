import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
//models
import { PrivacyPolicyModel } from '../../../models/PrivacyPolicyModel';
//services
import { SettingsService } from "../../../services/settings.service";
//component
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
@Component({
	selector: 'about-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss']
})
export class AboutEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private activeRoute: ActivatedRoute,
		private settingsService: SettingsService) {
		super();
	}
  clicked:boolean=false;
	settings: PrivacyPolicyModel = new PrivacyPolicyModel();

	ngOnInit() {
		let id = this.activeRoute.snapshot.params['id'];
		if (this.isEdit) {
			this.settingsService.getAll().subscribe(res => {
				this.settings = res.filter(item => item.id==id)[0];
			})
		}
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}


	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '500px',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		placeholder: 'Enter text here...',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
		{class: 'arial', name: 'Arial'},
		{class: 'times-new-roman', name: 'Times New Roman'},
		{class: 'calibri', name: 'Calibri'},
		{class: 'comic-sans-ms', name: 'Comic Sans MS'}
		],
		customClasses: [
		{
			name: 'quote',
			class: 'quote',
		},
		{
			name: 'redText',
			class: 'redText'
		},
		{
			name: 'titleText',
			class: 'titleText',
			tag: 'h1',
		},
		],
		uploadUrl: 'v1/image',
		uploadWithCredentials: false,
		sanitize: true,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [
		['bold', 'italic'],
		['fontSize']
		]
	};

	public save() {

		if (!this.settings.id ) {
      this.clicked=true;
			this.settingsService.save(this.settings).subscribe(data => {
			  this.clicked=false;
				if(!data.hasError ) {
					this.alertService.showSuccess('About Us added successfully.');
					// this.settingsService.updateData({id:data.data}).subscribe(res => {
						this.back();
					// })
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			})
		} else {
      this.clicked=true;
      this.settingsService.save(this.settings).subscribe(data => {
        this.clicked=false;
        if(!data.hasError ) {
					this.alertService.showSuccess('About Us updated successfully.');
					// this.settingsService.updateData({id:data.data}).subscribe(res => {
						this.back();
					// })
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			})
		}
	}

	// public back() {
	// 	this.router.navigateByUrl("settings/about-us/list");
	// }

}
