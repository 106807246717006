import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";
import { IntroService } from "./services/intro.service";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class IntroShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private introService: IntroService) {
        super();
    }

    ngOnInit() {
    }

    get moduleDataLoaded() {
        const storeKeysToVerify = ['intro'];
        return storeKeysToVerify.map(k => !!this.store._(k)).
            reduce((acc, val) => {
                return acc && val;
            }, true)
    }
}