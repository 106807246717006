import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";
import { PropertyService } from "./services/propery.services";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class PropertyShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private propertyService: PropertyService) {
        super();
    }

    ngOnInit() {
        // if (!this.moduleDataLoaded) {
        //     this.propertyService.loadData().subscribe(
        //         r => {
        //             this.alertService.showInfo(`Property data has been loaded!`);
        //             this.goToUrl('/property/list');
        //         },
        //         e => {
        //             this.alertService.showError(`Loading Property data had encounter error.`);
        //             this.goToUrl('/dashboard');
        //         });
        // } else {
        //     this.alertService.showInfo(`Property data has been loaded!`);
        //     this.goToUrl('/property/list');
        // } 
    }

    get moduleDataLoaded() {
        const storeKeysToVerify = ['property'];
        return storeKeysToVerify.map(k => !!this.store._(k)).
        reduce((acc, val) => {
            return acc && val;
        }, true)
    }
}