import { requests } from './../request.module/request.routes';
import { RequestShellComponent } from './../request.module/request-shell.component';
import { LoginPartnerComponent } from './pages/login-partner/login-partner.component';
import { LayoutComponent } from '../layout/layout.component';
// guard
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { RedirectToDashboardGuard } from '../shared/services/security/redirectToDashboardGuard';
import { RedirectToInitializeGuard } from '../shared/services/security/redirectToInitializeGuard';
// component
import { LockComponent } from './pages/lock/lock.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { RegisterComponent } from './pages/register/register.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Dashboardv1Component } from './dashboard/dashboardv1/dashboardv1.component';
import { InitializeAppComponent } from '../initialize-app/initialize-app.component';
// module
import { DashboardModule } from './dashboard/dashboard.module';
import { MenuModule } from '../menu.module/menu.module';
import { PlaceModule } from '../place.module/place.module';
import { TableModule } from '../tables.module/tables.module';
import { OrderModule } from '../order.module/order.module';
import { IntroModule } from '../intro.module/intro.module';
import { StatusModule } from '../status.module/status.module';
import { ClientModule } from '../client.module/client.module';
import { OptionModule } from '../option.module/option.module';
import { AccountModule } from '../account.module/account.module';
import { SettingsModule } from '../settings.module/settings.module';
import { CategoryModule } from '../category.module/category.module';
import { PropertyModule } from '../property.module/property.module';
import { DiscountModule } from '../discount.module/discount.module';
import { AdditionalModule } from '../additional.module/additional.module';
import { IngredientModule } from '../ingredient.module/ingredient.module';
import { NotificationModule } from '../notification.module/notification.module';
import { OrganisationModule } from '../Organisation.module/organisation.module';
import { LogModule } from '../log.module/log.module';
import { account } from '../account.module/account.routes';
import { OrganisationShellComponent } from '../Organisation.module/organisation-shell.component';
import { organisations } from '../Organisation.module/organisation.routes';
import { Dashboardv2Component } from './dashboard/dashboardv2/dashboardv2.component';
import { Dashboardv3Component } from './dashboard/dashboardv3/dashboardv3.component';
import { PlaceShellComponent } from '../place.module/place-shell.component';
import { place } from '../place.module/place.routes';
import { MenuShellComponent } from '../menu.module/menu-shell.component';
import { menus } from '../menu.module/menu.routes';
import { table } from '../tables.module/tables.routes';
import { TablesShellComponent } from '../tables.module/table-shell.component';
import { CategoryShellComponent } from '../category.module/category-shell.component';
import { category } from '../category.module/category.routes';
import { IngredientShellComponent } from '../ingredient.module/ingredient-shell.component';
import { ingredient } from '../ingredient.module/ingredient.routes';
import { AdditionalShellComponent } from '../additional.module/additional-shell.component';
import { additional } from '../additional.module/additional.routes';
import { OptionShellComponent } from '../option.module/option-shell.component';
import { option } from '../option.module/option.routes';
import { PropertyShellComponent } from '../property.module/property-shell.component';
import { property } from '../property.module/property.routes';
import { OrderShellComponent } from '../order.module/order-shell.component';
import { order } from '../order.module/order.routes';
import { StatusShellComponent } from '../status.module/status-shell.component';
import { status } from '../status.module/status.routes';
import { IntroShellComponent } from '../intro.module/intro-shell.component';
import { intro } from '../intro.module/intro.routes';
import { ClientShellComponent } from '../client.module/client-shell.component';
import { client } from '../client.module/client.routes';
import { DiscountShellComponent } from '../discount.module/discount-shell.component';
import { discount } from '../discount.module/discount.routes';
import { setting } from '../settings.module/settings.routes';
import { NotificationShellComponent } from '../notification.module/notification-shell.component';
import { notification } from '../notification.module/notification.routes';
import { LogShellComponent } from '../log.module/log-shell.component';
import { logs } from '../log.module/log.routes';
import { setFullYear } from 'ngx-bootstrap/chronos/utils/date-setters';

export const routes = [
  // {
  //     path: '',
  //     component: LayoutComponent,
  //     children: [
  //     { path: '', redirectTo: 'home', pathMatch: 'full' },
  //     { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) , canActivate: [RedirectToLoginGuard] },
  //     { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  //     { path: 'widgets', loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule) },
  //     { path: 'elements', loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
  //     { path: 'forms', loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule) },
  //     { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule) },
  //     { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
  //     { path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
  //     { path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
  //     { path: 'ecommerce', loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
  //     { path: 'extras', loadChildren: () => import('./extras/extras.module').then(m => m.ExtrasModule) }
  //     ]
  // },
  {
    path: 'initialize',
    component: InitializeAppComponent,
    canActivate: [RedirectToLoginGuard],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [RedirectToLoginGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/order/list' },
      // ...AccountModule.routerRoutes(),
      {
        path: 'account',
        children: account,
      },
      {
        path: 'organisations',
        component: OrganisationShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: organisations,
      },
      // ...OrganisationModule.routerRoutes(),
      // ...DashboardModule.routerRoutes(),
      // ...PlaceModule.routerRoutes(),
      {
        path: 'place',
        component: PlaceShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: place,
      },
      // ...MenuModule.routerRoutes(),
      {
        path: 'menu',
        component: MenuShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: menus,
      },
      // ...TableModule.routerRoutes(),
      {
        path: 'tables',
        component: TablesShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: table,
      },
      // ...CategoryModule.routerRoutes(),
      {
        path: 'category',
        component: CategoryShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: category,
      },
      // ...IngredientModule.routerRoutes(),
      {
        path: 'ingredient',
        component: IngredientShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: ingredient,
      },
      // ...AdditionalModule.routerRoutes(),
      {
        path: 'additional-info',
        component: AdditionalShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: additional,
      },
      //  ...OptionModule.routerRoutes(),
      {
        path: 'option',
        component: OptionShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: option,
      },
      {
        path: 'request',
        component: RequestShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: requests,
      },
      // ...PropertyModule.routerRoutes(),
      {
        path: 'property',
        component: PropertyShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: property,
      },
      // ...StatusModule.routerRoutes(),
      {
        path: 'status',
        component: StatusShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: status,
      },
      // ...OrderModule.routerRoutes(),
      {
        path: 'order',
        component: OrderShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: order,
      },
      // ...IntroModule.routerRoutes(),
      {
        path: 'intro',
        component: IntroShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: intro,
      },
      // ...ClientModule.routerRoutes(),
      {
        path: 'client',
        component: ClientShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: client,
      },
      // ...DiscountModule.routerRoutes(),
      {
        path: 'discount',
        component: DiscountShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: discount,
      },
      // ...SettingsModule.routerRoutes(),
      {
        path: 'settings',
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: setting,
      },
      // ...NotificationModule.routerRoutes(),
      {
        path: 'notification',
        component: NotificationShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: notification,
      },
      // ...LogModule.routerRoutes(),
      {
        path: 'log',
        component: LogShellComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: logs,
      },
    ],
  },
  // Not lazy-loaded routes
  // { path: 'dashboard', component: Dashboardv1Component },
  // { path: 'v2', component: Dashboardv2Component },
  // { path: 'v3', component: Dashboardv3Component },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [RedirectToDashboardGuard],
  },
  {
    path: 'partner-login',
    component: LoginPartnerComponent,
    canActivate: [RedirectToDashboardGuard],
  },
  { path: 'logout', component: LogoutComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'recover', component: RecoverComponent },
  { path: 'lock', component: LockComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: '404', component: Error404Component },
  { path: '500', component: Error500Component },
  // Not found
  { path: '**', redirectTo: 'order/list' },
];
