import { BehaviorSubject, Observable } from 'rxjs';
import { UserProfileModel } from '../models/UserProfileModel';
import { } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataStore } from '../../shared/models/DataStore';

@Injectable()
export class AccountStore extends DataStore {
    constructor() {
        super();
    }

    public initializeStore() {

        this.set('account', {
            loggedUser: this.getLoggedUser,
            isAuthenticated: () => {
                const tokenExistance = localStorage.getItem('Token');
                return !!tokenExistance;
            }
        });
    }

    getLoggedUser() {
        const storageData = this.getObjectFromLocalStorage('LoggedUserInfo');
        if (storageData) {
            return storageData.userData;
        } else {
            return { name: 'No User' };
        }
    }

}


