import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { KeyValuePair } from '../../../../../models/KeyValuePair';
import { UserAffiliationRefModel } from '../../../../models/UserAffiliationRefModel';
import { UserAffiliationRefInsertModel } from '../../../../models/UserAffiliationRefModel';
//services
import { PlaceUsersService } from "../../../../services/placeUsers.service";
import { SectionService } from "../../../../../menu.module/services/menu.service";
import { RoleService } from "../../../../../notification.module/services/notfication.service";

//components
import { AuthenticatedComponent } from '../../../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
	selector: 'personal-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss']
})
export class PlacePersonalEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private activeRoute: ActivatedRoute,
		private roleService: RoleService,
		private sectionService: SectionService,
		protected translate:TranslateService,
		private placeUsersService: PlaceUsersService) {
		super();
	}

	public user: UserAffiliationRefModel = new UserAffiliationRefModel();
	public roleList: Array<KeyValuePair> = [];
  clicked:boolean=false;

	ngOnInit() {

		let orgId = this.activeRoute.snapshot.params['orgId'];
		let placeId = this.activeRoute.snapshot.params['placeId'];

		if (!!placeId) {
			this.user.placeId = Number(placeId);
			this.roleService.getAllExternalForPlace(placeId).subscribe(res => {
				this.roleList = res.map(k => new KeyValuePair(k.id, k.name));
			});
		}

		if (!!orgId) {
			this.user.organisationId = Number(orgId);
		}

		if (this.isEdit) {
			let userId = this.activeRoute.snapshot.params['id'];
			this.placeUsersService.get(userId).subscribe(res => {
				if (!!res) {
					this.user = res;
					this.roleService.getAllPersonalForPlace(res.placeId).subscribe(res => {
						this.roleList = res.map(k => new KeyValuePair(k.id, k.name));
					});
				}else {
					this.alertService.showError(this.translate.instant("alerts.SOMETHINGWENTWRONG"));
					this.back();
				}
			});
		}
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public isFormValid() {

		if (!super.isFormValid()) {
			this.alertService.showError(this.translate.instant("alerts.INVALID"));
			return false;
		}
		return true;
	}

	private createInsertModel(user: UserAffiliationRefModel): UserAffiliationRefInsertModel {
		let insertModel: UserAffiliationRefInsertModel = new UserAffiliationRefInsertModel();
		insertModel.id = user.id;
		insertModel.roleId = user.roleId;
		insertModel.placeId = user.placeId;
		insertModel.userLogin = user.userLogin;
		insertModel.organisationId = user.organisationId;
		insertModel.isActive = user.isActive;

		return insertModel;
	}

	public save() {

		if (!this.isFormValid()) return;

		let insertModel = this.createInsertModel(this.user);

		if (!this.user.id ) {
      this.clicked=true;
			this.placeUsersService.save(insertModel).subscribe(data => {
			  this.clicked=false;
				if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.USERADDEDSUCCESSFULLY"));
					this.placeUsersService.updateData({id:data.data}).subscribe(res => {
						this.back();
					})
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});

		} else {
      this.clicked=true;
			this.placeUsersService.save(insertModel).subscribe(data => {
        this.clicked=false;
        if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.USERUPDATEDSUCESSFULLY"));
					this.placeUsersService.updateData({id:insertModel.id}).subscribe(res => {
						this.back();
					})
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});
		}
	}
}
