﻿
import { BaseComponent } from './BaseComponent';
export abstract class AuthenticatedComponent extends BaseComponent {

    constructor() {
        super();

        if (!this.isUserAuthenticated) {
            //console.log("User is not logged in")
            this.goToUrl('auth/login', null, null, true);
        }
    }

    protected aaa() {

    }
}