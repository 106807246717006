import { Component, OnInit, Input } from '@angular/core';
//models
import { PlaceModel } from '../../../models/PlaceModel';
//components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
@Component({
	selector: 'app-place-menu',
	templateUrl: './place-menu.component.html',
	styleUrls: ['./place-menu.component.scss']
})
export class PlaceMenuComponent extends AuthenticatedComponent implements OnInit {

	constructor() {
		super();
	}

	@Input() place: PlaceModel = new PlaceModel();
	@Input() copyList: Array<any> = null;
	ngOnInit() {
	}
}
