import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// models
import { OrderModel } from '../../models/OrderModel';
import { KeyValuePair } from '../../../models/KeyValuePair';
// services
import { OrderService } from '../../services/order.service';
// componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import { TranslateService } from '@ngx-translate/core';
import { SignalRService } from '../../../shared/services/signal-r.service';
import { LoadingService } from '../../../shared/services/loading.service';
declare var moment;

@Component({
  selector: 'order-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderListComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private orderService: OrderService,
    private signalRSevice: SignalRService,
    private loadingService: LoadingService,
    protected translate: TranslateService
  ) {
    super();
  }

  public page = 1;
  public itemsPerPage = 10;
  public maxSize = 5;
  public numPages = 1;
  public length = 0;

  public orderFilter: OrderFilter = new OrderFilter();
  public orderList: Array<OrderModel> = [];
  public data: Array<OrderModel> = [];
  public statusList: Array<KeyValuePair> = [
    new KeyValuePair(0, 'statuses.NEW'),
    new KeyValuePair(1, 'statuses.COMPLETED'),
    new KeyValuePair(2, 'statuses.REQUESTED'),
    new KeyValuePair(3, 'statuses.LEFT'),
    new KeyValuePair(4, 'statuses.LEFT'),
    new KeyValuePair(5, 'statuses.CANCELED'),
  ];
  bsConfig = {
    containerClass: 'theme-angle',
  };
  public columns: Array<any> = [
    { title: 'ID', name: 'id' },
    { title: 'PLACENAME', name: 'Place Name' },
    { title: 'TABLENAME', name: 'Table Name' },
    { title: 'USERNAME', name: 'User Name' },
    { title: 'PRICE', name: 'Amount' },
    { title: 'STATUS', name: 'Receipt Status' },
    { title: 'OPENDATE', name: 'Open Date' },
    { title: 'OPENTIME', name: 'Open Time' },
    { title: 'SETTINGS', name: 'Settings' },
  ];

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered', 'mb-0', 'd-table-fixed'],
  };

  ngOnInit() {
    this.signalRSevice.connection.on('UpdateOrderStatus', () => {
      this.onChangeTable(this.config);
    });
    this.signalRSevice.connection.on('OrderClosed', () => {
      this.onChangeTable(this.config);
    });
    this.onChangeTable(this.config);
    this.orderService.getTotal().subscribe((res) => {
      this.length = res;
    });
  }

  getdate(event) {
    this.orderFilter.startDate = event[0];
    this.orderFilter.endDate = event[1];
  }

  public filter() {
    this.orderFilter.amount = +this.orderFilter.amount;
    this.orderService.getFiltered(this.orderFilter).subscribe((res) => {
      this.orderList = res;
    });
  }

  public closeTheBill(id, serveAll: boolean) {
    this.orderService.closeTheBill(id, serveAll).subscribe((data) => {
      if (!data.hasError) {
        this.onChangeTable(this.config);
        this.alertService.showSuccess(
          this.translate.instant('alerts.BILLCLOSEDSUCCESSFULLY')
        );
      } else {
        const errors = data.errors.map((r) => r.message);
        this.alertService.showError(errors);
      }
    });
  }

  public onChangeTable(
    config: any,
    page: any = { page: this.orderFilter.page, itemsPerPage: this.itemsPerPage }
  ): any {
    this.orderService.getAllOrders(page.page).subscribe((res) => {
      this.orderList = res;
    });
  }

  public setIsActive(isActive, orderId)
  {
      this.orderService.setIsActive(isActive, orderId).subscribe((data) => {
        if (!data.hasError) {
          this.alertService.showSuccess(
            this.translate.instant('alerts.ORDERUPDATEDSUCCESSFULLY')
          );
        } else {
          const errors = data.errors.map((r) => r.message);
          this.alertService.showError(errors);
        }
      });
  }

  public save(order: OrderModel) {
    this.orderService.save(order).subscribe((data) => {
      if (!data.hasError) {
        this.alertService.showSuccess(
          this.translate.instant('alerts.ORDERUPDATEDSUCCESSFULLY')
        );
      } else {
        const errors = data.errors.map((r) => r.message);
        this.alertService.showError(errors);
      }
    });
  }
}

export class OrderFilter {
  placeName: string;
  tableName: string;
  userName: string;
  amount: number;
  startDate: Date;
  endDate: Date;
  openTime: string;
  page = 1;
  pageSize = 10;
  isActive = true;
}
