import { TableModel } from '../../tables.module/models/TableModel';
import { StatusModel } from '../../status.module/models/StatusModel';
import { MenuItemModel } from '../../menu.module/models/MenuItemModel';
import { DiscountModel } from '../../discount.module/models/DiscountModel';
import { UserProfileModel } from '../../account.module/models/UserProfileModel';

export class OrderModel {
id: number;
price: number;
paid: number;
userId: number;
tableId: number;
discountId: number;
transactionId: number;
transactions: Array<any> = [];
isStatusChanged: boolean;
amount: any;
status: number;
menuItemOrderRefGroups: Array<MenuItemOrderRefGroupModel> = [];
discount: DiscountModel = new DiscountModel();
transaction: any;
createdDate: string;
updatedDate: string;
updatedBy: string;
user: UserProfileModel = new UserProfileModel();
lastUpdatedBy: UserProfileModel = new UserProfileModel();
table: TableModel = new TableModel();
hasPaid = false;
isActive = true;
    get parnerRoll() { return this.user.roles.filter(i => i !== 'BasicUser' && i !== 'Partner'); }
    get closedByRoll() { return this.lastUpdatedBy.roles.filter(i => i !== 'BasicUser' && i !== 'Partner'); }

    public fromObject(obj: object) {

        if (obj) {
            for (const propName in obj) {
                try {
                    this[propName] = obj[propName];
                } catch (e) {
                    // console.log(`Cannot set property ${propName}`, e)
                }
            }
        }
        return this;
    }

}

export class CommentModel {
  id: number;
  userId: number;
  text: string;
  isActive: boolean;
  }



export class MenuItemOrderRefModel {
id: number;
userId: number;
orderId: number;
statusId: number;
menuItemId: number;
price: number;
isStatusChanged: boolean;
createdDate: string;
updatedDate: string;
propertySummaryPrice: number;
menuItem: MenuItemModel = new MenuItemModel();
order: object;
status: StatusModel = new StatusModel();
user: UserProfileModel = new UserProfileModel();
comment: CommentModel = new CommentModel();
properties: Array<PropertyModel> = [];
  statusHystories: Array<StatuHistoryModel> = [];
  isPartner = false;
  isActive = true;
}

export class MenuItemOrderRefGroupModel {
  menuItemOrderRef: MenuItemOrderRefModel = new MenuItemOrderRefModel();
  quantity: number;
  menuItemOrderRefIds: Array<number>;
  isChecked = true;
  isPartner = false;
  isActive = true;
  }

export class RejectModel {
    text: string;
    isActive = true;
  }

export class UpdateModel {
  menuItemOrderRefIds: Array<number> = [];
  statusId: number;
  reject: RejectModel = new RejectModel();
  isActive = true;
}




export  class StatuHistoryModel {
  id: number;
  menuItemOrderRefId: number;
  createdDate: string;
  comment: string;
  status: StatusModel = new StatusModel();
  user: UserProfileModel = new UserProfileModel();
  isActive: boolean;
  reject: any;
}
export class OptionModel {
  id: number;
  title: string;
}
export  class PropertyModel {
  id: number;
  text: string;
  price: number;
  option: OptionModel = new OptionModel();
}

