import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { AuthorizeGuard } from '../shared/services/security/authorizeGuard';
// components
import { OrderShellComponent } from './order-shell.component';
import { OrderListComponent } from './order/list/list.component';
import { OrderEditComponent } from './order/edit/edit.component';

export const order = [
     {
        path: 'list',
        component: OrderListComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: 'edit/:id',
        component: OrderEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      }
];
