import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { MenuModel } from '../../models/MenuModel';
//services
import { MenuService } from "../../services/menu.service";
import { SectionService } from "../../services/menu.service";
import { MenuItemService } from "../../services/menu.service";
import { appInjector } from '../../../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../../../shared/services/store.service';
import { MenuDataStore } from '../../menu.store';

//components
import { BaseComponent } from '../../../shared/components/BaseComponent';
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
import {RoleService} from "../../../notification.module/services/notfication.service";
@Component({
	selector: 'menu-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss']
})
export class MenuEditComponent extends AuthenticatedComponent implements OnInit {

    constructor(
        private menuService: MenuService,
        private sectionService: SectionService,
        protected translate: TranslateService,
        private menuItemService: MenuItemService,
        private activeRoute: ActivatedRoute) {
    	super();
    }

    public menu: MenuModel = new MenuModel();
    public placeId: string = null;

    ngOnInit() {

        this.placeId = this.activeRoute.snapshot.params['placeId'];

        if ( !!this.placeId ) {
            this.menu.placeId = Number(this.placeId);
        }
        if (this.isEdit) {
            let menuId = this.activeRoute.snapshot.params['id'];
            this.menuService.getMenu(menuId).subscribe(res => {
                if (!!res) {
                    this.menu = res;
                    let injector = appInjector.injector();
                    let store = injector.get(AppStore);
                    MenuDataStore.prototype.initializeStore.call(store);
                }else {
                    this.alertService.showError(this.translate.instant("alerts.INVALID"));
                    this.back();
                }
            });
        }
    }

    public get isEdit() {
        return this.activeRoute.snapshot.url.join('').includes("edit");
    }
}
