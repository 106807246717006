import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { CrudService } from './crud.service';
import { BaseService } from './BaseService';
import { map } from 'rxjs/operators';
import { urls } from '../../../environments/environment';

@Injectable()
export class SignalRService extends BaseService {
  public connection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Information)
    .withUrl(urls.signalRUrl)
    .build();
  constructor() {
    super();
    const startSignalRConnection = (connection) =>
      connection
        .start()
        .then(() => {
          this.setConnectionId(this.connection.connectionId).subscribe();
        })
        .catch(function(err) {
          return console.error(err.toString());
        });
    startSignalRConnection(this.connection);
    this.connection.onclose(() =>
      setTimeout(startSignalRConnection(this.connection), 1000)
    );
  }
  setConnectionId(connectionId) {
    if (this.storage.get(this.storage.Keys.Token)) {
      return this.apirequest(
        'get',
        this.apiCallTo(`/Account/SetConnectionId?connectionId=${connectionId}`)
      ).pipe(map((i) => i.data));
    }
  }
}
