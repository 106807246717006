import { MenuModel } from './MenuModel';
import { ImageModel } from '../../models/ImageModel';
import { SectionModel } from './SectionModel';
import { MeasureUnitModel } from '../../settings.module/models/MeasureUnitModel';


export class MenuItemModel {
  id: number;
  name: string;
  menuId: number;
  price: number;
  weight: number;
  weightMeasureUnitId = 1;
  weightMeasureUnit: MeasureUnitModel = new MeasureUnitModel();
  pieces: number;
  piecesMeasureUnitId = 5;
  piecesMeasureUnit: MeasureUnitModel = new MeasureUnitModel();
  calories: number;
  caloriesMeasureUnitId = 3;
  caloriesMeasureUnit: MeasureUnitModel = new MeasureUnitModel();
  description: string;
  sectionId: number;
  averageCount: number;
  averageRate: number;
  section: SectionModel = new SectionModel();
  menu: MenuModel = new MenuModel();
  imageIds: Array<number> = [];
  ingredientIds: Array<number> = [];
  imageUrl: string;
  options: Array<OptionModel>;
  ingredients: Array<IngredientModel>;
  nameTranslationId = 0;
  descriptionTranslationId = 0;
  sortOrder = 0;
  isActive = true;
}

export class MenuItemInsertModel {
id: number;
name: string;
menuId: number;
price: number;
weight: number;
weightMeasureUnitId: number;
pieces: number;
piecesMeasureUnitId: number;
calories: number;
caloriesMeasureUnitId: number;
sectionId: number;
description: string;
averageCount: number;
averageRate: number;
imageIds: Array<number>;
ingredientIds: Array<number>;
nameTranslationId = 0;
descriptionTranslationId = 0;
sortOrder = 0;
isActive = true;
}

export class OptionModel {
id: number;
title: string;
menuItemId: number;
description: string;
type: number;
menuItem: any;
properties: any;
titleTranslationId = 0;
descriptionTranslationId = 0;
isActive = true;
}

export class IngredientModel {
id: number;
name: string;
price: number;
calories: number;
type: number;
nameTranslationId: number;
isActive = true;
}
