import { Route } from '@angular/router';
// import { AccountProfileComponent } from './profile/profile.component';
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { AuthorizeGuard } from '../shared/services/security/authorizeGuard';
import { ProfileComponent } from './profile/profile.component';

export const account: Route[] = [
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [RedirectToLoginGuard]
    }
    // {
    //     path: 'my-profile',
    //     component: AccountProfileComponent,
    //     canActivate: [RedirectToLoginGuard]
    // },
];
