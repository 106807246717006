import { MenuItemModel } from '../../menu.module/models/MenuItemModel'

export class OptionModel {
	id: number;
	placeId: number
	menuItemId: number;
	title: string;
	description: string;
	type: number = 0;
	menuItem: MenuItemModel = new MenuItemModel();
	properties: Array<PropertyModel> = [];
	titleTranslationId: number = 0;
	descriptionTranslationId: number = 0;
	isActive: boolean = true;
}

export class OptionInsertModel {
	id: number;
	placeId: number
	menuItemId: number;
	title: string;
	description: string;
	type: number;
	titleTranslationId: number = 0;
	descriptionTranslationId: number = 0;
	isActive: boolean = true;
}

export class PropertyModel {
	id: number;
	text: string;
	state: boolean;
	price: number;
	optionId: number;
	textTranslationId: number = 0;
	isActive: boolean = true;
}