import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { DataStore } from '../shared/models/DataStore';
import { PlaceStore } from './place.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';
// import { routes } from './place.routes';
import { AgmCoreModule } from '@agm/core';

import { MenuModule } from '../menu.module/menu.module';
import { PlaceService } from "./services/place.service";
import { TableModule } from '../tables.module/tables.module';
import { DiscountModule } from '../discount.module/discount.module';
import { PlaceUsersService } from "./services/placeUsers.service";
import { PlaceShellComponent } from './place-shell.component';
import { PlaceListComponent } from './places/list/list.component';
import { PlaceEditComponent } from './places/edit/edit.component';
import { PlaceInfoComponent } from './places/edit/place-info/place-info.component';
import { PlaceImagesComponent } from './places/edit/place-images/place-images.component';
import { PlaceTablesComponent } from './places/edit/place-tables/place-tables.component';
import { PlaceMenuComponent } from './places/edit/place-menu/place-menu.component';
import { PlaceSettingsComponent } from './places/edit/place-settings/place-settings.component';
import { PlacePersonalComponent } from './places/edit/place-personal/place-personal.component';
import { PlaceContactComponent } from './places/edit/place-contact/place-contact.component';
import { PlacePersonalEditComponent } from './places/edit/place-personal/edit/edit.component';
import { PlaceDiscountComponent } from './places/edit/place-discount/place-discount.component';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex'
import { PlaceNotifroleComponent } from './places/edit/place-notifrole/place-notifrole.component';
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {SortingPlacesModalComponent} from "./places/list/sortingPlaces-modal/sortingPlaces-modal.component";
import {DndModule, DragDropConfig, DragDropService, DragDropSortableService} from "ng2-dnd";

const CustomSelectOptions: any = { // Check the interface for more options
    optionValueField: 'id',
    optionTextField: 'name'
};

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    TableModule,
    MenuModule,
    DiscountModule,
    NgxSelectModule.forRoot(CustomSelectOptions),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDu3om19wXJ5AuAzqt3zXidKdZVNqm3D7E',
      language: 'en'
    }),
    NgxMaterialTimepickerModule,
    DndModule,
  ],
  declarations: [
    PlaceShellComponent,
    PlaceListComponent,
    PlaceEditComponent,
    PlaceInfoComponent,
    PlaceImagesComponent,
    PlaceTablesComponent,
    PlaceMenuComponent,
    PlaceSettingsComponent,
    PlacePersonalComponent,
    PlaceContactComponent,
    PlacePersonalEditComponent,
    PlaceDiscountComponent,
    PlaceNotifroleComponent,
    SortingPlacesModalComponent
  ],

  exports: [],
  entryComponents: [SortingPlacesModalComponent],

  providers: [ PlaceService, PlaceUsersService, DragDropService,DragDropConfig,DragDropSortableService]
})
export class PlaceModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
        ...services.placeService.preloadData(),
        ...services.placeUsersService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let placeService = appinjector.get(PlaceService);
    let placeUsersService = appinjector.get(PlaceUsersService);

    return {
      store,
      placeService,
      placeUsersService
    }
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    PlaceStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    PlaceStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.placeService.loadData();
    services.placeUsersService.loadData();
  }

}
