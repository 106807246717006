import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { DataStore } from '../shared/models/DataStore';
import { IntroStore } from './intro.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';

import { IntroService } from "./services/intro.service";
import { IntroShellComponent } from './intro-shell.component';
import { IntroEditComponent } from './intro/edit/edit.component';
import { IntroListComponent } from './intro/list/list.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    IntroShellComponent,
    IntroEditComponent,
    IntroListComponent,
  ],

  exports: [],
  entryComponents: [],

  providers: [ IntroService ]
})
export class IntroModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
        ...services.introService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let introService = appinjector.get(IntroService);

    return {
      store,
      introService,
    }
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    IntroStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    IntroStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.introService.preloadData();
  }

}
