import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RequestModel } from '../models/RequestModel';

@Injectable()
export class RequestService extends CrudService<RequestModel> {

    protected serviceItemsKey = 'log';

    constructor(protected store: AppStore) {
        super('OrganisationRequest', null);
    }

    public preloadData() {
        return [
            this.getAllRequests(),
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

    public getAllRequests() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAll`))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }
}
