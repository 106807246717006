import { EventEmitter, ViewChild, OnInit } from '@angular/core';
import { Component, Input, forwardRef, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { SimpleInputComponent } from '../_input-component/input.component';

@Component({
    selector: 'textbox',
    templateUrl: './textbox.component.html',
    styleUrls: ['./textbox.component.css'],
})
export class TextboxComponent extends SimpleInputComponent {
    @Input() size: string;
    @Input() type: string = 'text';
    @Input() defaultForNumber: number = 0;
    @Input() prefix: string;
    @Input() suffix: string;
    @Input() prefixIcon: string;
    @Input() suffixIcon: string;
    @Input() equalTo: string;
    @Input() equalToMessage: string;
    @Input() info: string;
    @Input() error_message : boolean = true ;

    constructor() {
        super();
    }

    get hasPrefix() {
        return this.prefix || this.prefixIcon;
    }

    get hasSuffix() {
        return this.suffix || this.suffixIcon;
    }

    focused = false;
    onFocus() {
        super.onFocus();
        this.toggleZeroNumber(true);
    }

    onBlur() {
        super.onBlur();
        this.toggleZeroNumber(false);
    }

    protected validateField() {
        let errors = super.validateField();

        if (!!this.equalTo && this.equalTo !== this.value) {
            errors.push(this.translationService.getTranslationFor(this.equalToMessage || 'Text is not equal'));
        }

        const emailregexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (this.fieldMustBeChecked && this.type === "email" && !emailregexp.test(this.value)) {
            errors.push(this.translationService.getTranslationFor('Enter a valid e-mail address'));
        }

        return errors;
    }

    private toggleZeroNumber(focus) {
        if (this.type !== 'number') {
            return;
        }

        if (this.defaultForNumber) {
            if (focus == true && this.value == "") {
                this.value = "";
            }
            if (focus == false && this.value == "") {
                this.value = this.defaultForNumber;
            }
        }
    }
}
