import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { PlaceModel } from '../../../models/PlaceModel';
//components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';

@Component({
	selector: 'app-place-discount',
	templateUrl: './place-discount.component.html',
	styleUrls: ['./place-discount.component.scss']
})
export class PlaceDiscountComponent extends AuthenticatedComponent implements OnInit {

	constructor(private activeRoute: ActivatedRoute) {
		super();
	}

	@Input() place: PlaceModel = new PlaceModel();
	placeId = this.activeRoute.snapshot.params['id'];
	ngOnInit() {
	}

}
