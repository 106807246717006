﻿import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppStore } from '../store.service';

@Injectable()
export class RedirectToDashboardGuard implements CanActivate {
    constructor(private router: Router,
        private appStore: AppStore
    ) { }

    canActivate() {
        if (!!this.appStore._('account.isAuthenticated')) {
            // if user is authenticated redirect to dashboard
            this.router.navigate(['/order/list']);
            return false;
        }
        return true;
    }
}