import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { KeyValuePair } from '../../../models/KeyValuePair';
import { CategoryModel } from '../../models/CategoryModel';
import { CategoryInsertModel } from '../../models/CategoryModel';
//services
import { CategoryService } from "../../services/category.service";
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
import {LoadingService} from "../../../shared/services/loading.service";
@Component({
	selector: 'app-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss']
})
export class CategoryEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private activeRoute: ActivatedRoute,
    protected translate: TranslateService,
    private categoryService: CategoryService) {
		super();
	}

	public file: any = null;
	public category: CategoryModel = new CategoryModel();
  private clicked: boolean=false;


  ngOnInit() {
		let parentId = this.activeRoute.snapshot.params['parentId'];

		if (!!parentId) {
			this.category.parentId = Number(parentId);
		}

		if (this.isEdit) {
			let categoryId = this.activeRoute.snapshot.params['id'];
			this.categoryService.get(categoryId).subscribe(res => {
				if (!!res) {
					this.category = res;
				}else {
					this.alertService.showError(this.translate.instant("alerts.SOMETHINGWENTWRONG"));
					this.back();
				}
			});
		}
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public isFormValid() {

		if (!super.isFormValid()) {
			this.alertService.showError(this.translate.instant("alerts.INVALID"));
			return false;
		}
		return true;
	}

	// public back() {
	// 	this.router.navigateByUrl("category/list");
	// }

	public createInsertModel(category: CategoryModel): CategoryInsertModel {

		let insertModel: CategoryInsertModel = new CategoryInsertModel();

		insertModel.id = category.id;
		insertModel.name = category.name;
		insertModel.parentId = category.parentId;
		insertModel.description = category.description;
		insertModel.sortOrder = category.sortOrder;
		insertModel.imageUrl = category.imageUrl;
		insertModel.nameTranslationId = category.nameTranslationId;
		insertModel.descriptionTranslationId = category.descriptionTranslationId;
		insertModel.isActive = category.isActive;

		return insertModel;
	}

	public save() {
		if (!this.isFormValid()) return;

		let insertModel = this.createInsertModel(this.category);

		if (!this.category.id) {

			if (this.file) {
        this.clicked=true;

        this.categoryService.multipleUploadFile(this.file).subscribe(res => {
          this.clicked=false;
					let error = res.errors.map(r => {return r.message});
					this.alertService.showError(error);
					insertModel.imageUrl = res.data[0].url;

					this.categoryService.save(insertModel).subscribe(data => {

						if(!data.hasError) {
							this.alertService.showSuccess(this.translate.instant("alerts.CATEGORYADDEDSUCCESSFULLY"));
							this.categoryService.loadData().subscribe(res => {
								this.back();
							})
						} else {
							let errors = data.errors.map(r => {return r.message});
							this.alertService.showError(errors);
						}
					});
				});
			}else {
				this.alertService.showError(this.translate.instant("alerts.CATEGORYIMAGEISREQUIRED"));

			}
		} else {

			if (this.file) {
				this.categoryService.multipleUploadFile(this.file).subscribe(res => {

					let error = res.errors.map(r => {return r.message});
					this.alertService.showError(error);
					insertModel.imageUrl = res.data[0].url;
          this.clicked=true;
          this.categoryService.save(insertModel).subscribe(data => {
            this.clicked=false;

						if(!data.hasError) {

							this.alertService.showSuccess(this.translate.instant("alerts.CATEGORYUPDATEDSUCCESSFULLY"));
							this.categoryService.loadData().subscribe(res => {
								this.back();
							})

						} else {
							let errors = data.errors.map(r => {return r.message});
							this.alertService.showError(errors);
						}
					});
				});

			} else {

				this.categoryService.save(insertModel).subscribe(data => {
					if(!data.hasError) {
						this.alertService.showSuccess(this.translate.instant("alerts.CATEGORYUPDATEDSUCCESSFULLY"));
						this.categoryService.loadData().subscribe(res => {
							this.back();
						})
					} else {
						let errors = data.errors.map(r => {return r.message});
						this.alertService.showError(errors);
					}
				});
			}
		}
	}
}
