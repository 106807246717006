import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../services/notfication.service";
import { PlaceModel } from "../../place.module/models/PlaceModel";
import { HeaderComponent } from "../../layout/header/header.component";

@Component({
  selector: "app-my-notification",
  templateUrl: "./my-notification.component.html",
  styleUrls: ["./my-notification.component.scss"],
})
export class MyNotificationComponent implements OnInit {
  constructor(
    private notificationService: NotificationService,
    private headerComponent: HeaderComponent
  ) {}
  public notifsList: Array<any> = [];
  public data: Array<any> = [];
  ngOnInit() {
    this.notificationService.GetTotalNotifs().subscribe( data => {
      this.length = data;
    });
    this.onChangeTable(this.config);
  }
  public rows: Array<any> = [];
  public columns: Array<any> = [
    { title: "title", name: "title" },
    { title: "body", name: "body" },
    // { title: 'date', name: 'date' },
  ];
  public page: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    className: ["table-striped", "table-bordered", "mb-0", "d-table-fixed"],
  };

  public onChangeTable(
    config: any,
    page: any = { page: this.page, itemsPerPage: this.itemsPerPage }
  ): any {
    this.notificationService
      .notifs(page.page, this.itemsPerPage)
      .subscribe((res) => {
        this.notifsList = res;
      });
  }
}
