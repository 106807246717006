import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PlaceModel } from '../models/PlaceModel';

@Injectable()
export class PlaceService extends CrudService<PlaceModel> {

    protected serviceItemsKey = 'place';

    constructor(protected store: AppStore) {
        super('Place', null);
    }

    preloadData() {
        return [
            this.getAllPlaces(),
        ];
    }

    public loadData() {
       return forkJoin(this.preloadData());
    }

    public getAllPlaces(page = 1, searchTerm:string = "", pagesize = 10) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAll?page=${page}&pagesize=${pagesize}&searchTerm=${searchTerm}`))
            .pipe(
                map(i => i.data),
                //tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

    public updateIsActive(id:number,isActive:boolean)
    {
      return this.apirequest("post",this.apiCallTo(this.serviceApiUrl + `/UpdateIsActive?placeId=${id}&isActive=${isActive}`))
        .pipe(
          map(i => i.data),
        );
    }

    public getAllSimplified() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetAllSimplified'))
            .pipe(
                map(i => i.data),
            );
    }

    public setParent(data: any){
        return this.apirequest("post", this.apiCallTo(this.serviceApiUrl + '/SetParent'), null, JSON.stringify(data));
    }

  public getSortmenu()
  {
    return this.apirequest("get", this.apiCallTo(this.serviceApiUrl+`/GetAllForSorting`))
      .pipe(
        map(i => i.data),
      );
  }

  public UpdateSortmenu(sortList)
  {
    return this.apirequest("post", this.apiCallTo(this.serviceApiUrl + `/UpdateSortOrder`), null, JSON.stringify(sortList))
      .pipe(
        map(i => i.data),
      );
  }
}
