import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserAffiliationRefModel } from '../models/UserAffiliationRefModel';

@Injectable()
export class PlaceUsersService extends CrudService<UserAffiliationRefModel> {

    protected serviceItemsKey = 'placeUsers';

    constructor(protected store: AppStore) {
        super('UserAffiliationRef', null);
    }

    preloadData() {
        return [
        ];
    }

    public loadData() {
       return forkJoin(this.preloadData());
    }

    public getAllUSers(placeId:number) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAll?placeId=${placeId}`))
            .pipe(
                map(i => i.data),
                tap(d => console.log(d))
            );
    }

}