import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
//components
import { StatusShellComponent } from './status-shell.component';
import { StatusListComponent } from './status/list/list.component';
import { StatusEditComponent } from './status/edit/edit.component';

export const status = [
  {
    path: "list",
    component: StatusListComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "edit/:id",
    component: StatusEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  }
]
