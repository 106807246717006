import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { MenuModel } from '../../../../models/MenuModel';
import { SectionModel } from '../../../../models/SectionModel';
import { KeyValuePair } from '../../../../../models/KeyValuePair';
import { SectionInsertModel } from '../../../../models/SectionModel';
//services
import { SectionService } from "../../../../services/menu.service";
import { RoleService } from "../../../../../notification.module/services/notfication.service";
//component
import { AuthenticatedComponent } from '../../../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
	selector: 'app-section-modal',
	templateUrl: './section-modal.component.html',
	styleUrls: ['./section-modal.component.scss']
})
export class SectionModalComponent extends AuthenticatedComponent implements OnInit, AfterViewInit {

	constructor(
		public roleService: RoleService,
    protected translate: TranslateService,

    private activeRoute: ActivatedRoute,
		private sectionService: SectionService) {
		super();
	}

	formId: string = 'section-form';
	@Input() menu: MenuModel = new MenuModel();
	@Input() sectionId: number = null;
	section: SectionModel = new SectionModel();
	roleList: Array<any> = [];
  clicked:boolean=false;


  ngOnInit() {
		this.roleService.getAllExternalForPlace(this.menu.placeId).subscribe(res => {
			this.roleList = res;
		})

	}

	ngAfterViewInit() {

		if (!!this.sectionId) {
			this.sectionService.get(this.sectionId).subscribe(res => {
				if (!!res) {
					this.section = res;
				}else {
					this.alertService.showError(this.translate.instant("alerts.INVALID"));
				}
			});
		}
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public createInsertModel(section: SectionModel): SectionInsertModel {

		let insertModel: SectionInsertModel = new SectionInsertModel();

		insertModel.id = section.id;
		insertModel.name = section.name;
		insertModel.menuId = section.menuId;
		insertModel.roleIds = section.roleIds;
		insertModel.nameTranslationId = section.nameTranslationId;
		insertModel.sortOrder = section.sortOrder;
		insertModel.isActive = section.isActive;

		return insertModel;
	}

	public save() {

		if (!this.isFormValid()) return;

		this.section.menuId = this.menu.id;
		let insertModel = this.createInsertModel(this.section);

		if (!this.section.id) {
      this.clicked=true;
      this.sectionService.save(insertModel).subscribe(d => {
			  this.clicked=false;
				if(!d.hasError) {
					this.sectionService.updateData({id:d.data}).subscribe(res => {
						this.alertService.showSuccess(this.translate.instant("alerts.SECTIONADDEDSUCCESSFULLY"));
						this.section = new SectionModel();
					})
				} else {
					let errors = d.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});
		} else {
		  this.clicked=true;
      this.sectionService.save(insertModel).subscribe(d => {
        this.clicked=false;
        if(!d.hasError) {
					this.sectionService.updateData({id:insertModel.id}).subscribe(res => {
						this.alertService.showSuccess(this.translate.instant("alerts.SECTIONUPDATEDSUCCESSFULLY"));
					})
				} else {
					let errors = d.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});
		}
	}

	public isFormValid() {
		if (!super.isFormValid()) {
			this.alertService.showError(this.translate.instant("alerts.INVALID"));
			return false;
		}
		return true;
	}
}
