import { EventEmitter } from '@angular/core';
import { Component, Input, forwardRef, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleInputComponent } from '../_input-component/input.component';

@Component({
    selector: 'text-area',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.css'],
})
export class TextAreaComponent extends SimpleInputComponent {
    constructor() {
        super();
    }

    @Input() rows: number = 4;
    //@Input() cols: number = 10;
}
