import { Component, OnInit } from '@angular/core';
//models
import { AdditionalInfoModel } from '../../models/AdditionalInfoModel';
import { AdditionalInfoInsertModel } from '../../models/AdditionalInfoModel';
//services
import { AdditionalService } from '../../services/additional.services';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
@Component({
	selector: 'app-additional-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class AdditionalListComponent extends  AuthenticatedComponent implements OnInit {

	constructor(
		private additionalService: AdditionalService,
    protected translate: TranslateService
  ) {
		super();
	}

	public additionalList: Array<AdditionalInfoModel> = [];
	public data: Array<AdditionalInfoModel> = [];

	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'logo', name: 'logo' },
	{ title: 'name', name: 'name' },
	{ title: 'settings', name: 'settings' },

	];

	ngOnInit() {
		this.additionalService.getAll().subscribe(data => {
			this.additionalList = data;
		});
	}


	createInsertModel(additional: AdditionalInfoModel): AdditionalInfoInsertModel {
		let insertModel: AdditionalInfoInsertModel = new AdditionalInfoInsertModel();

		insertModel.id = additional.id;
		insertModel.name = additional.name;
		insertModel.imageIds = additional.imageUrls.map(item => item.id);
		insertModel.logoId = additional.logoId;
		insertModel.coverId = additional.coverId;
		insertModel.nameTranslationId = additional.nameTranslationId;
		insertModel.isActive = additional.isActive;

		return insertModel;
	}

	public save(additional: AdditionalInfoModel ) {

		let insertModel = this.createInsertModel(additional);

		this.additionalService.save(additional).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.ADDITIONALINFOUPDATEDSUCCSEFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}

}
