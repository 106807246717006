import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//services
import { PlaceService } from "../../../services/place.service";
import { OrganisationService } from "../../../../Organisation.module/services/organisation.service";
import { CategoryService } from "../../../../category.module/services/category.service";
import { AdditionalService } from "../../../../additional.module/services/additional.services";
//models
import { PlaceModel } from '../../../models/PlaceModel';
import { KeyValuePair } from '../../../../models/KeyValuePair';
import { CategoryModel } from '../../../../category.module/models/CategoryModel';
import { AdditionalInfoModel } from '../../../../additional.module/models/AdditionalInfoModel';

//components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
import { NotificationModel } from '../../../../notification.module/models/NotificationModel';
import {
  NotificationRoleService,
  NotificationService, RoleService
} from '../../../../notification.module/services/notfication.service';
@Component({
  selector: 'app-place-notifrole',
  templateUrl: './place-notifrole.component.html',
  styleUrls: ['./place-notifrole.component.scss']
})
export class PlaceNotifroleComponent extends AuthenticatedComponent implements OnInit {

  constructor(
    private notService: NotificationService,
    private notRoleService:NotificationRoleService,
    private roleService:RoleService,
    private activeRoute: ActivatedRoute) {
    super();
  }
  @Input() place: PlaceModel = new PlaceModel();
  public notList: Array<NotificationModel> = [];
  public clientNotifs: Array<NotificationModel> = [];
  public partnerNotifs: Array<NotificationModel> = [];
  public adminNotifs: Array<NotificationModel> = [];
  public partnerRoles:Array<any>=[];
  public adminRoles:Array<any>=[];
  public pairs: Array<KeyValuePair>=[];
 public placeId:number = this.activeRoute.snapshot.params['id'];

  public columns: Array<any> = [

    { title: 'NOTIFICATION', name: 'Notification' },
    { title: 'ISRECIEVE', name: 'Is Recieve' },


  ];
  ngOnInit() {
    this.notService.getAll().subscribe(data => {
      this.notList = data;
      this.clientNotifs=this.notList.filter(
        notif=>notif.isForPlatform
      );
      this.partnerNotifs=this.notList.filter(
        notif=>notif.isForPartner
      );
      this.adminNotifs=this.notList.filter(
        notif=>notif.isForAdmin
      );
    });
    this.roleService.getAllExternalForPlace(this.placeId).subscribe(res => {
      this.partnerRoles = res;
    })
    this.roleService.getAllOrganisational().subscribe(res => {
      this.adminRoles = res;
    })
    this.notRoleService.getPairs(this.placeId).subscribe(data => {
      this.pairs=data.notificationRoleIds;
      console.log(this.pairs);
    })
  }
  isConnected(notif:number,role:number)
  {
    if (this.pairs.filter(obj=>obj.key==notif && obj.value==role).length>0)
    {
      return true;
    }
    return false;
  }

  change(notif,role,event)
  {
    let ref=new NotificationRoleRef();
    ref.placeId=Number(this.placeId);
    ref.roleId=role;
    ref.notificationId=notif;
    console.log(event);
    ref.isActive=event.target.checked;
    this.notRoleService.update(ref).subscribe(data => {
      if(!data.hasError) {
        if(!data.hasError) {
          this.alertService.showSuccess(this.translate.instant("alerts.NOTIFICATIONROLEADDEDSUCCESSFULLY"));
        } else {
          let errors = data.errors.map(r => {return r.message});
          this.alertService.showError(errors);
        }
      }
    });
  }
}
export class NotificationRoleRef {
  placeId:number;
  roleId: number ;
  notificationId:number;
  isActive: boolean=true;
}

