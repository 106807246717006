import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MenuModel } from '../models/MenuModel';
import { SectionModel } from '../models/SectionModel';
import { MeasureUnitModel } from '../../settings.module/models/MeasureUnitModel';
import { MenuItemModel } from '../models/MenuItemModel';

@Injectable()
export class MenuService extends CrudService<MenuModel> {
    protected serviceItemsKey = 'menu';

    constructor(protected store: AppStore) {
        super('Menu', null);
    }

    public preloadData() {
        return [
            this.getAllMenues(),
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

    

  public getSortmenu(placeId)
  {
    return this.apirequest("get", this.apiCallTo(this.serviceApiUrl+`/GetAllForSortingByPlaceId?placeId=${placeId}`))
      .pipe(
        map(i => i.data),
      );
  }

  public UpdateSortmenu(sortList)
  {
    return this.apirequest("post", this.apiCallTo(this.serviceApiUrl + `/UpdateSortOrder`), null, JSON.stringify(sortList))
      .pipe(
        map(i => i.data),
      );
  }

    public Export(menuId)
    {
      return this.apirequest("get",this.apiCallTo(`/Export/Sections?parentId=${menuId}`))
        .pipe(
          map(i=>i.data)
        )
    }

  public ImportMenus(file: any ,placeId:number) {
    var formData: FormData = new FormData();
    formData.append("file", file[0]);
    return this.apirequest("post", this.apiCallTo(`/Import/Menu?placeId=${placeId}`), "undefined", formData);
  }
  public ExportMenus(placeId)
  {
    return this.apirequest("get",this.apiCallTo(`/Export/Menus?parentId=${placeId}`))
      .pipe(
        map(i=>i.data)
      )
  }

  public Import(file: any ,menuId:number) {
    var formData: FormData = new FormData();
    formData.append("file", file[0]);
    return this.apirequest("post", this.apiCallTo(`/Import/Section?menuId=${menuId}`), "undefined", formData);
  }

    public getAllMenues() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetAll'))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }
  public getMenu(id) {
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/Get?id=${id}`))
      .pipe(
        map(i => i.data),
        tap(d => this.appStore.set(this.serviceItemsKey, d))
      );
  }
   public getAllForCopy(orgId) {
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAllForCopy?organizationId=${orgId}`))
      .pipe(
        map(i => i.data),
        tap(d => this.appStore.set(this.serviceItemsKey, d))
      );
  }
  public CopyMenu(copyId,placeId) {
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/CopyMenu?menuId=${copyId}&placeId=${placeId}`))
      .pipe(
        map(i => i.data),
      );
  }
}

@Injectable()
export class SectionService extends CrudService<SectionModel> {
    protected serviceItemsKey = 'section';

    constructor(protected store: AppStore) {
        super('Section', null);
    }

    public getAllSections(id: number) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAll?menuId=${id}`))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

  public getSortmenu(menuId)
  {
    return this.apirequest("get", this.apiCallTo(this.serviceApiUrl+`/GetAllForSorting?menuId=${menuId}`))
      .pipe(
        map(i => i.data),
      );
  }

  public UpdateSortmenu(sortList)
  {
    return this.apirequest("post", this.apiCallTo(this.serviceApiUrl + `/UpdateSortOrder`), null, JSON.stringify(sortList))
      .pipe(
        map(i => i.data),
      );
  }
  public Export(sectionId)
  {
    return this.apirequest("get",this.apiCallTo(`/Export/MenuItems?parentId=${sectionId}`))
      .pipe(
        map(i=>i.data)
      )
  }

  public Import(file: any ,sectionId:number,menuId:number) {
    var formData: FormData = new FormData();
    formData.append("file", file[0]);
    return this.apirequest("post", this.apiCallTo(`/Import/MenuItem?sectionId=${sectionId}&menuId=${menuId}`), "undefined", formData);
  }

  public getAllRoles() {
        return this.apirequest('get', this.apiCallTo('Role/GetAll'))
            .pipe(
                map(i => i.data),
            );
    }
    public getAllPersonal() {
        return this.apirequest('get', this.apiCallTo('Role/GetAllPersonal'))
            .pipe(
                map(i => i.data),
            );
    }
}

@Injectable()
export class MenuItemService extends CrudService<MenuItemModel> {
    protected serviceItemsKey = 'menuitem';

    constructor(protected store: AppStore) {
        super('MenuItem', null);
    }

    public getAllMenuItems(id) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/Getallbymenuid?menuId=${id}`))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

    public getSortmenu(sectionId)
    {
      return this.apirequest("get", this.apiCallTo(this.serviceApiUrl+`/GetAllForSortingBySectionId?sectionId=${sectionId}`))
        .pipe(
          map(i => i.data),
        );
    }

    public setIsActive(isActive: boolean, menuitemId: number) {
      return this.apirequest(
        'get',
        this.apiCallTo(
          this.serviceApiUrl +
            `/SetIsActive?isActive=${isActive}&menuItemId=` +
            menuitemId
        )
      ).pipe(map((r) => r.data));
    }

    public UpdateSortmenu(sortList)
    {
      return this.apirequest("post", this.apiCallTo(this.serviceApiUrl + `/UpdateSortOrder`), null, JSON.stringify(sortList))
        .pipe(
            map(i => i.data),
         );
    }

  public Export(menuItemId)
  {
    return this.apirequest("get",this.apiCallTo(`/Export/Option?parentId=${menuItemId}`))
      .pipe(
        map(i=>i.data)
      )
  }

  public Import(file: any ,menuItemId:number) {
    var formData: FormData = new FormData();
    formData.append("file", file[0]);
    return this.apirequest("post", this.apiCallTo(`/Import/Option?menuItemId=${menuItemId}`), "undefined", formData);
  }

    public deleteMenuItemFile(url:string) {
        return this.apirequest("get", this.apiCallTo('AdminImage/DeactivateMultiple?url='+url)).pipe(
                map(i => i.data),
            );
    }
}
