import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { SectionService } from 'src/app/menu.module/services/menu.service';
import { OptionService } from 'src/app/option.module/services/option.service';
import { AuthenticatedComponent } from 'src/app/shared/components/AuthenticatedComponent';
// models

// services

// component
@Component({
  selector: 'app-options-modal',
  templateUrl: './sortingOptions-modal.component.html',
  styleUrls: ['./sortingOptions-modal.component.scss'],
})
export class SortingOptionsModalComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    public optionService: OptionService,
    public bsModalRef: BsModalRef
  ) {
    super();
  }
  @Input() menuItemId: number;
  sortList: Array<any>;
  clicked = false;
  ngOnInit() {
    this.optionService.getSortmenu(this.menuItemId).subscribe((data) => {
      this.sortList = data.sort((a, b) => a.sortOrder - b.sortOrder);
    });
  }
  
  public save() {
    for (let i = 0; i < this.sortList.length; i++) {
      this.sortList[i].sortOrder = i + 1;
    }
    this.clicked = true;
    this.optionService.UpdateSortmenu(this.sortList).subscribe((d) => {
      this.clicked = false;
      if (!d.hasError) {
        this.alertService.showSuccess(
          this.translate.instant('alerts.SORTORDERUPDATEDSUCCESSFULLY')
        );
        this.bsModalRef.hide();
      } else {
        const errors = d.errors.map((r) => {
          return r.message;
        });
        this.alertService.showError(errors);
      }
    });
  }
}
