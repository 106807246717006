import { PlaceModel } from '../../place.module/models/PlaceModel';
import { ImageModel } from '../../models/ImageModel'
import { SectionModel } from '../../menu.module/models/SectionModel'

export class IntroModel {
	id: number;
	imageUrl: string;
	imageId: number;
	title: string;
	description: string;
	imageUpload: ImageUploadModel = new ImageUploadModel();
	titleTranslationId: number = 0;
	descriptionTranslationId: number = 0;
	isActive: boolean = true;
}

export class ImageUploadModel {
	id: number;
	url: string;
}