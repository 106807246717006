import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { ClientStore } from './client.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';

import { ClientService } from "./services/client.service";
import { ClientShellComponent } from './client-shell.component';
import { ClientListComponent } from './client/list/list.component';
import { ClientEditComponent } from './client/edit/edit.component';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    ClientShellComponent,
    ClientListComponent,
    ClientEditComponent,
  ],

  exports: [],
  entryComponents: [],

  providers: [ ClientService ]
})
export class ClientModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
      ...services.clientService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let clientService = appinjector.get(ClientService);

    return {
      store,
      clientService,
    }
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    ClientStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    ClientStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.clientService.preloadData();
  }

}
