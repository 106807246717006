import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { DataStore } from '../shared/models/DataStore';
import { OrganisationStore } from './organisation.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';
// import { routes } from './organisation.routes';
import { OrganisationShellComponent } from './organisation-shell.component';

import { OrganisationService } from './services/organisation.service';
import { OrganisationListComponent } from './organisation/list/list.component';
import { OrganisationEditComponent } from './organisation/edit/edit.component';
import { OrganisationGeneralComponent } from './organisation/edit/organisation-general/organisation-general.component';
import { OrganisationImagesComponent } from './organisation/edit/organisation-images/organisation-images.component';
import { OrganisationTransactionsComponent } from './organisation/edit/organisation-transactions/organisation-transactions.component';
import { OrganisationPlacesComponent } from './organisation/edit/organisation-places/organisation-places.component';
import { OrganisationUsersComponent } from './organisation/edit/organisation-users/organisation-users.component';
import { OrganisationUsersEditComponent } from './organisation/edit/organisation-users/edit/edit.component';
import {IMaskModule} from 'angular-imask';
import {TextMaskModule} from 'angular2-text-mask';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        SharedModule,
        RouterModule,
        IMaskModule,
        TextMaskModule

    ],
  declarations: [
    OrganisationShellComponent,
    OrganisationListComponent,
    OrganisationEditComponent,
    OrganisationGeneralComponent,
    OrganisationImagesComponent,
    OrganisationTransactionsComponent,
    OrganisationPlacesComponent,
    OrganisationUsersComponent,
    OrganisationUsersEditComponent,
  ],

  exports: [],
  entryComponents: [],

  providers: [OrganisationService]
})
export class OrganisationModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
        ...services.organisationService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    const appinjector = appInjector.injector();
    const store = appinjector.get(AppStore);
    const organisationService = appinjector.get(OrganisationService);

    return {
      store,
      organisationService,
    };
  }

  static initializeModuleData() {
    const injector = appInjector.injector();
    const store = injector.get(AppStore);
    OrganisationStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    OrganisationStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.organisationService.loadData();
  }

}
