import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import {
  ToasterModule,
  ToasterService,
} from "angular2-toaster/angular2-toaster";
import { BootstrapComponentsModule } from "../bootstrap-components.module/bootstrap-components.module";

import { PopoverModule } from "ngx-bootstrap/popover";
import { DatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { HttpModule } from "@angular/http";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { FlotDirective } from "./directives/flot/flot.directive";
import { LoadingService } from "./services/loading.service";
import { SparklineDirective } from "./directives/sparkline/sparkline.directive";
import { EasypiechartDirective } from "./directives/easypiechart/easypiechart.directive";
import { ColorsService } from "./colors/colors.service";
import { CheckallDirective } from "./directives/checkall/checkall.directive";
import { VectormapDirective } from "./directives/vectormap/vectormap.directive";
import { NowDirective } from "./directives/now/now.directive";
import { ScrollableDirective } from "./directives/scrollable/scrollable.directive";
import { JqcloudDirective } from "./directives/jqcloud/jqcloud.directive";
import { BusyScreenComponent } from "./components/busy-screen/busy-screen.component";

import { BaseService } from "./services/BaseService";
import { HttpClient } from "./services/HttpClient";
import { StorageService } from "./services/storage.service";
import { AppStore } from "./services/store.service";
import { AlertsService } from "./services/alerts.service";
import { RedirectToLoginGuard } from "./services/security/redirectToLoginGuard";
import { RedirectToDashboardGuard } from "./services/security/redirectToDashboardGuard";
import { RedirectToInitializeGuard } from "./services/security/redirectToInitializeGuard";
import { AuthorizeGuard } from "./services/security/authorizeGuard";
import { MeasureUnitService } from "../settings.module/services/MeasureUnitService";
import { HttpListener } from "./services/HttpListener";
import { SignalRService } from "./services/signal-r.service";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { CrudService } from "./services/crud.service";
import { environment } from "../../environments/environment";
import { MessagingService } from "./services/messaging.service";
import { NotificationService } from '../notification.module/services/notfication.service';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        DatepickerModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PopoverModule.forRoot(),
        HttpModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        BootstrapComponentsModule,
        NgxSkeletonLoaderModule,
    ],
  providers: [ColorsService],
  declarations: [
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    BusyScreenComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    DatepickerModule,
    BsDatepickerModule,
    PopoverModule,
    ToasterModule,
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    HttpModule,
    BootstrapComponentsModule,
    BusyScreenComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        AppStore,
        BaseService,
        HttpClient,
        SignalRService,
        StorageService,
        AlertsService,
        LoadingService,
        MeasureUnitService,
        RedirectToLoginGuard,
        RedirectToDashboardGuard,
        RedirectToInitializeGuard,
        NotificationService,
        MessagingService,
        ToasterService,
        AuthorizeGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpListener,
          multi: true,
        },
      ],
    };
  }
}
