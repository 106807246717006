import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { MenuModel } from '../../../models/MenuModel';
import { MenuInsertModel } from '../../../models/MenuModel';
import { KeyValuePair } from '../../../../models/KeyValuePair';
//services
import { MenuService } from "../../../services/menu.service";
import { PlaceService } from "../../../../place.module/services/place.service";
import { saveAs } from 'file-saver';

//components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
import { TranslateService } from "@ngx-translate/core";
import { SortingModalComponent } from "../sections/sorting-modal/sorting-modal.component";
import { ModalsService } from "../../../../bootstrap-components.module/modals/modals.service";
import { SortingSectionsModalComponent } from "./sortingSections-modal/sortingSections-modal.component";
@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private menuService: MenuService,
		private placeService: PlaceService,
		private modalService: ModalsService,
		protected translate: TranslateService,
		private activeRoute: ActivatedRoute) {
		super();
	}

	file: any = null;
	@Input() menu: MenuModel = new MenuModel();
	clicked: boolean = false;
	placeList: Array<KeyValuePair> = [];
	private modalRef: any = null;

	ngOnInit() {
		this.placeService.getAllSimplified().subscribe(res => {
			this.placeList = res.map(k => new KeyValuePair(k.id, k.name));
		});
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}


	public isFormValid() {

		if (!super.isFormValid()) {
			this.alertService.showError(this.translate.instant("alerts.INVALID"));
			return false;
		}
		return true;
	}

	// public back() {
	// 	this.router.navigateByUrl("menu/list");
	// }

	delete(url) {
		this.menuService.deleteFile(url).subscribe(res => {
			if (res) {
				this.menuService.updateData({ id: this.menu.id }).subscribe(res => {
					this.menu = res.data;
					this.alertService.showSuccess(this.translate.instant("alerts.IMAGEDELETED"));
				});
			}
		})
	}

	createInsertModel(menu: MenuModel): MenuInsertModel {

		let insertModel: MenuInsertModel = new MenuInsertModel();

		insertModel.id = menu.id;
		insertModel.name = menu.name;
		insertModel.placeId = menu.placeId;
		insertModel.imageIds = menu.imageUrls.map(item => item.id);
		insertModel.nameTranslationId = menu.nameTranslationId;
		insertModel.sortOrder = menu.sortOrder;
		insertModel.isActive = menu.isActive;

		return insertModel;
	}



	public save() {

		if (!this.isFormValid()) return;

		let insertModel = this.createInsertModel(this.menu);

		if (!this.menu.id) {

			if (!!this.file) {
				this.clicked = true;
				this.menuService.multipleUploadFile(this.file).subscribe(res => {
					this.clicked = false;
					let error = res.errors.map(r => { return r.message });
					this.alertService.showError(error);

					insertModel.imageIds.push(...res.data.map(a=>a.id));

					this.menuService.save(insertModel).subscribe(data => {

						if (!data.hasError) {

							this.alertService.showSuccess(this.translate.instant("alerts.MENUADDEDSUCCSESSFULLY"));
							this.menuService.updateData({ id: this.menu.id }).subscribe(res => {
								this.back();
							})

						} else {
							let errors = data.errors.map(r => { return r.message });
							this.alertService.showError(errors);
						}
					});
				});
			} else {
				this.alertService.showError(this.translate.instant("alerts.MENUIMAGEFIELDISREQUIRED"));
			}

		} else {

			if (this.file) {
				this.clicked = true;
				this.menuService.multipleUploadFile(this.file).subscribe(res => {
					this.clicked = false;
					let error = res.errors.map(r => { return r.message });
					this.alertService.showError(error);
					insertModel.imageIds.push(...res.data.map(a=>a.id));

					this.menuService.save(insertModel).subscribe(data => {

						if (!data.hasError) {

							this.alertService.showSuccess(this.translate.instant("alerts.MENUUPDATEDSUCCSESSFULLY"));
							this.menuService.updateData({ id: insertModel.id }).subscribe(res => {
								this.placeService.loadData().subscribe();
								this.back();
							})

						} else {
							let errors = data.errors.map(r => { return r.message });
							this.alertService.showError(errors);
						}
					});
				});

			} else {

				this.menuService.save(insertModel).subscribe(data => {
					if (!data.hasError) {
						this.alertService.showSuccess(this.translate.instant("alerts.MENUUPDATEDSUCCSESSFULLY"));
						this.menuService.updateData({ id: this.menu.id }).subscribe(res => {
							this.placeService.loadData().subscribe();
							this.back();
						})
					} else {
						let errors = data.errors.map(r => { return r.message });
						this.alertService.showError(errors);
					}
				});
			}
		}
	}

}
