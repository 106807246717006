export class PropertyModele {
id: number;
text: string;
state = true;
price: number;
optionId: number;
textTranslationId = 0;
imageIds: Array<number>;
imageUrls: Array<any>;
priceShowType: number;
isActive = true;
}
