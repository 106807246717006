import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../shared/services/storage.service';

@Injectable()
export class TranslatorService {


    private availablelangs = [
        { code: 'en-US', text: 'english' },
        { code: 'ru-RU', text: 'russian' },
        { code: 'hy-AM', text: 'armenian' }
    ];

    constructor(public translate: TranslateService, private storage: StorageService) {

        // if (!translate.getDefaultLang())
        //     translate.setDefaultLang(this.storage.get(this.storage.Keys.Languages));
        //
        // this.useLanguage();

    }

    useLanguage(lang: string = null) {
        this.storage.save(this.storage.Keys.Languages, lang || this.translate.getDefaultLang());
        this.translate.use(lang || this.translate.getDefaultLang());
    }

    getAvailableLanguages() {
        return this.availablelangs;
    }

    getselectedLanguage() {
        return this.storage.get(this.storage.Keys.Languages);
    }

}
