import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { DataStore } from '../shared/models/DataStore';
import { AdditionalStore } from './additional.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';

import { AdditionalService } from "./services/additional.services";
import { AdditionalShellComponent } from './additional-shell.component';
import { AdditionalListComponent } from './additional/list/list.component';
import { AdditionalEditComponent } from './additional/edit/edit.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    AdditionalShellComponent,
    AdditionalListComponent,
    AdditionalEditComponent,
  ],

  exports: [],
  entryComponents: [],

  providers: [ AdditionalService ]
})
export class AdditionalModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
        ...services.additionalService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let additionalService = appinjector.get(AdditionalService);

    return {
      store,
      additionalService,
    }
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    AdditionalStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    AdditionalStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.additionalService.preloadData();
  }
}
