import { EventEmitter, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, IterableDiffers,KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { TranslationsProvider } from '../utils/translation-directive/translations.provider';
import { appInjector } from '../utils/appInjector';


@Component({
    selector: 'dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements OnInit {

    constructor(
        private cd: ChangeDetectorRef,
        private differs: KeyValueDiffers,
        private _iterableDiffers: IterableDiffers) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
        this.keyValueDiffer = this.differs.find({}).create();
    }

    private iterableDiffer: any;
    private keyValueDiffer: KeyValueDiffer<string, any>;

    isPristine = true;
    breakOptionTextMark = '--'
    selectedOption: KeyValuePair;
    id = Math.random() * 10000;
    data: any ;
    valueToDisplay: string = '';
    valToDisplay: string = '';

    @Input() label: string;
    @Input() appended: string;
    @Input() icon: string;
    @Input() disabled: boolean;
    @Input() required: boolean;
    @Input() editable: boolean = false;
    @Input() filtering: boolean = false;

    get translationService(): TranslationsProvider {
        return appInjector.instanceOf(TranslationsProvider) || {};
    }

    private _textboxValue: string;
    public get textboxValue(): string {
        return this._textboxValue;
    }
    public set textboxValue(v: string) {
        this._textboxValue = v;
    }

    private _emptyItemLabel: string;
    @Input() get emptyItemLabel(): string {
        return this._emptyItemLabel;
    }
    set emptyItemLabel(v: string) {
        this._emptyItemLabel = v //this.translationService.getTranslationFor(v) || v;
    }

    @Output() addNewClick = new EventEmitter();
    @Output() itemMouseOver = new EventEmitter();
    @Output() itemMouseLeave = new EventEmitter();

    get _label() {
        return this.label + (this.required ? '*' : '');//(this.translationService.getTranslationFor(this.label) || this.label) + (this.required ? '*' : '');
    }

    ngOnInit(): void {
    }

    _value = "";
    @Input() get value() { return this._value; }
    set value(val) {
        if (this._value !== val) {
            this._value = val;
            this.valueChange.emit(val);
            this.changed.emit(this.selectedOption );
        }
    }

    @Output() valueChange = new EventEmitter();
    @Output() changed = new EventEmitter();

    arrayOptions = [];
    @Input() options:any ;

    ngOnChanges() {
        let changes = null;

        if (this.options instanceof Array) {
            changes = this.iterableDiffer.diff(this.options);
        } else if (this.options instanceof Object) {
            changes = this.keyValueDiffer.diff(this.options);
        }

        if (changes) {
            this.setOptions(this.options);
        }

        if(this.value && this.options && this.options.length > 0) {
            this.setOptions(this.options);
        }
    }

    setOptions(value: any) {
        if (value instanceof Array) {
            this.arrayOptions = this.options || [];
        } else
            if (value instanceof Object) {
                this.arrayOptions = Object.keys(value).map(k => new KeyValuePair(k, value[k]));
            }

        if (!!this.emptyItemLabel && !!this.arrayOptions) {
            this.arrayOptions = [
                new KeyValuePair('0', this.emptyItemLabel),
                ...this.arrayOptions.filter(i => i.key !== '0')]
        }

        this.data = this.arrayOptions;
        this.isPristine = true;
        this.setPredifinedValue();
    }

    get isValid() {
        return (!this.required ||
            (this.selectedOption && this.selectedOption.key != "0"
                && this.selectedOption.key != "")) ? "true" : "false";
    }

    private setValueToDisplay() {
        this.valueToDisplay = (this.selectedOption || { value: '' }).value.split(this.breakOptionTextMark)[0];
    }

    // get valToDisplay() {
    //     return ((this.selectedOption || { value: '' }).value || '').split(this.breakOptionTextMark)[0];
    // }
    
    selectOption(option) {
        this.selectedOption = option;
        this.valToDisplay = (this.selectedOption || { value: '' }).value || '';
        this.value = option.key;
        this.isPristine = false;
    }

    setPredifinedValue() {

        if (this.isPristine == true && !!this.arrayOptions && this.arrayOptions.length > 0) {
            let option: KeyValuePair = this.arrayOptions.find(o => o.key === this.value);
            let so = !!option ? option : this.arrayOptions[0];
            this.selectOption(so);
            this.isPristine = false;
        }
    }

    optionAsHtml(option) {
        return (option.value || '').replace(this.breakOptionTextMark, '<br/>')
    }
 
    onFilterChange(val: string): void {

        if (!val || val == null) {
            this.data = this.arrayOptions;
        } else {
            this.data = this.arrayOptions.filter((item: KeyValuePair) => { 
                        return item.value.indexOf(val) !== -1;
                    });
        }
    }

}

class KeyValuePair {
    key: string;
    value: any;
    /**
     *
     */
    constructor(k, v) {
        this.key = k;
        this.value = v;
    }
}