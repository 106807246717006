import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// services
import { OrganisationService } from '../../../services/organisation.service';
// models
import { OrganisationModel } from '../../../models/OrganisationModel';
// components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
@Component({
selector: 'app-organisation-general',
templateUrl: './organisation-general.component.html',
styleUrls: ['./organisation-general.component.scss']
})
export class OrganisationGeneralComponent extends AuthenticatedComponent implements OnInit {

@Input() organisation: OrganisationModel = new OrganisationModel();

constructor(
private activeRoute: ActivatedRoute,
public orgService: OrganisationService) {
super();
}

ngOnInit() {
}
}
