import { LoginModel } from './../models/LoginModel';
import { TablesService } from './../../../tables.module/services/tables.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { AuthenticationService } from '../services/authentication.service';
import { PublicComponent } from '../../../shared/components/PublicComponent';
import { KeyValuePair } from 'src/app/models/KeyValuePair';

@Component({
  selector: 'app-login',
  templateUrl: './login-partner.component.html',
  styleUrls: ['./login-partner.component.scss'],
})
export class LoginPartnerComponent extends PublicComponent implements OnInit {
  valForm: FormGroup;
  placeList: Array<any>;
  placeId: number;
  value;
  isPhone = true;
  countries: Array<any>;
  tableList: Array<any> = [];
  phoneCode = '+374';
  tableIds: Array<number> = [];
  constructor(
    public fb: FormBuilder,
    public settings: SettingsService,
    private route: ActivatedRoute,
    private tableService: TablesService,
    private authService: AuthenticationService
  ) {
    super();
  }

  ngOnInit() {
    this.valForm = this.fb.group({
      email: [null],
      phone: [null],
      password: [null, Validators.required],
      selectAll: [true],
      placeId: [0],
    });
    this.authService.Countries().subscribe((data) => {
      this.countries = data;
    });
  }

  submitForm($ev, value: any) {
    this.value = value;
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    const loginM = new LoginModel();
    if (this.isPhone) {
        loginM.login = this.phoneCode + value.phone;
      } else {
        loginM.login = value.email;
      }
    loginM.password = value.password;
    this.authService.getPlaces(loginM).subscribe((response) => {
        // var apiResponse = this.getApiResponseModel(response);
        if (!response.data) {
          const errors = response.errors.map((r) => {
            return r.message;
          });
          this.alertService.showError(errors);
        } else {
          // document.body.className = "";
          // this.alertService.showSuccess(this.translate.instant("alerts.LOGINWITHSUCCESFULLY"));
          // this.goToHomeUrl(returnUrl);
          // this.goToDashboard();
          // this.goToUrl("/order/list/");
          this.placeList = response.data.map(
            (k) => new KeyValuePair(k.id, k.name)
          );
          if (this.placeList.length === 1) {
            this.valForm.value.placeId = this.placeList[0].key;
            this.login();
          } else if (this.placeList.length == 0) {
            document.getElementById('noplace').hidden = false;
            document.getElementById('loginform').hidden = true;
          } else {
            document.getElementById('place').hidden = false;
            document.getElementById('loginform').hidden = true;
          }
        }
      });

  }
  login() {
      const loginM = new LoginModel();
      if (this.isPhone) {
        loginM.login = this.phoneCode + this.valForm.value.phone;
      } else {
        loginM.login = this.valForm.value.email;
      }
      loginM.password = this.valForm.value.password;
      loginM.placeId = this.valForm.value.placeId;

      this.authService.login(loginM).subscribe((response) => {
        // var apiResponse = this.getApiResponseModel(response);
        if (!response.data) {
          const errors = response.errors.map((r) => {
            return r.message;
          });
          this.alertService.showError(errors);
        } else {
          // document.body.className = "";
          // this.alertService.showSuccess(this.translate.instant("alerts.LOGINWITHSUCCESFULLY"));
          // this.goToHomeUrl(returnUrl);
          // this.goToDashboard();
          // this.goToUrl("/order/list/");
          this.tableService.tableGroups().subscribe((data) => {
            this.tableList = data;
            document.getElementById('place').hidden = true;
            if (this.valForm.value.selectAll) {
              for (const tablegroup of this.tableList) {
                for (const table of tablegroup.tables) {
                  this.tableIds.push(table.id);
                }
              }
              this.tableService.takeTables(this.tableIds).subscribe((data) => {
                if (!data.data) {
                  const errors = data.errors.map((r) => {
                    return r.message;
                  });
                  this.alertService.showError(errors);
                } else {
                  this.alertService.showSuccess(
                    this.translate.instant('alerts.LOGINWITHSUCCESFULLY')
                  );
                  this.goToUrl('/order/list/');
                }
              });
            } else {
              document.getElementById('tables').hidden = false;
            }
          });
        }
      });

  }

  taketables() {
    this.tableService.takeTables(this.tableIds).subscribe((data) => {
      if (!data.hasError) {
        this.alertService.showSuccess(
          this.translate.instant('alerts.LOGINWITHSUCCESFULLY')
        );
        this.goToUrl('/order/list/');
      } else {
        const errors = data.errors.map((r) => {
          return r.message;
        });
        this.alertService.showError(errors);
      }
    });
  }
}
