import { Component, OnInit } from '@angular/core';
// models
import { NotificationModel } from '../../models/NotificationModel';
// services
import { NotificationService } from '../../services/notfication.service';
import { NotificationRoleService } from '../../services/notfication.service';
// componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from '@ngx-translate/core';
@Component({
selector: 'notification-list',
templateUrl: './list.component.html',
styleUrls: ['./list.component.scss']
})
export class NotificationListComponent extends AuthenticatedComponent implements OnInit {

constructor(
private noti: NotificationRoleService,
protected translate: TranslateService,
private notService: NotificationService) {
super();
}

  public notList: Array<NotificationModel> = [];
  public data: Array<NotificationModel> = [];
  public clientNotifs: Array<NotificationModel> = [];
  public partnerNotifs: Array<NotificationModel> = [];
  public adminNotifs: Array<NotificationModel> = [];
  public clientNotifsdata: Array<NotificationModel> = [];
  public partnerNotifsdata: Array<NotificationModel> = [];
  public adminNotifsdata: Array<NotificationModel> = [];
  public columns: Array<any> = [
{ title: 'id', name: 'id' },
{ title: 'name', name: 'name' },
{ title: 'settings', name: 'settings' },
];

ngOnInit() {
this.notService.getAll().subscribe(data => {
this.notList = data;
this.clientNotifs = this.notList.filter(
        notif => notif.isForPlatform
      );
this.partnerNotifs = this.notList.filter(
        notif => notif.isForPartner
      );
this.adminNotifs = this.notList.filter(
        notif => notif.isForAdmin
      );
});
}

public save(not: NotificationModel ) {
this.noti.roleToggle(not.id).subscribe(data => {
if (!data.hasError ) {
this.alertService.showSuccess(this.translate.instant('alerts.NOTIFICATIONUPDATEDSUCCESSFULLY'));
} else {
const errors = data.errors.map(r =>r.message);
this.alertService.showError(errors);
}
});
}
}
