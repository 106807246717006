import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { OrganisationModel } from '../../models/OrganisationModel';
import { OrganisatioInsertnModel } from '../../models/OrganisationModel';
//services
import { OrganisationService } from "../../services/organisation.service";
//components
import { BaseComponent } from '../../../shared/components/BaseComponent';
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
import {AccountService} from "../../../account.module/services/account.service";

@Component({
	selector: 'organisation-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss']
})
export class OrganisationEditComponent extends AuthenticatedComponent implements OnInit {

    constructor(
        public orgService :OrganisationService,
        protected translate:TranslateService,
        private activeRoute: ActivatedRoute) {
    	super();
    }

    logo:any;
    images:any;
  private clicked: boolean=false;
  organisation: OrganisationModel = new OrganisationModel();

    ngOnInit(){

        if (this.isEdit) {
            let orgId = this.activeRoute.snapshot.params['id'];
            this.orgService.get(orgId).subscribe(res => {
                if (!!res) {
                    this.organisation = res;
                }else {
                    this.alertService.showError(this.translate.instant("alerts.SOMETHINGWENTWRONG"));
                    this.back();
                }
            });


        }
    }

    public upload(event) {
        switch (event.key) {
            case "logo":
            this.logo = event.value;
            break;
            case "images":
            this.images = event.value;
            break;
        }
    }

    public logoUpload():Promise<number> {
        return new Promise((resolve, reject) => {
            if(this.logo) {
                this.orgService.multipleUploadFile(this.logo).subscribe(res => {
                    resolve(res.data[0].id);
                });
            } else {
                resolve();
            }
        });
    }

    public imagesUpload():Promise<Array<number>> {
        return new Promise((resolve, reject) => {
            if (this.images) {
                this.orgService.multipleUploadFile(this.images).subscribe(res => {
                    resolve([...res.data.map(item => { return item.id })]);
                });
            } else {
                resolve();
            }
        });
    }

    createInsertModel(organ: OrganisationModel): OrganisatioInsertnModel {

        let insertModel: OrganisatioInsertnModel = new OrganisatioInsertnModel();

        insertModel.id = organ.id;
        insertModel.name = organ.name;
        insertModel.description = organ.description;
        insertModel.ownerId = organ.ownerId;
        insertModel.tin = organ.tin;
        insertModel.balance = organ.balance;
        insertModel.bank = organ.bank;
        insertModel.bankAccount = organ.bankAccount;
        insertModel.contact  = organ.contact;
        insertModel.imageIds = organ.imageIds;
        insertModel.logoId = organ.logoId;
        insertModel.coverId = organ.coverId;
        insertModel.isActive = organ.isActive;

        return insertModel;
    }

    public save() {

        if (!this.isFormValid()) return;

        this.organisation.ownerId = this.store._('account.loggedUser.id');
        let insertModel = this.createInsertModel(this.organisation);

        if (!this.organisation.id) {
          this.clicked=true;
          this.orgService.save(insertModel).subscribe(data => {
                this.clicked=false;
                if(!data.hasError) {

                    this.orgService.updateData({id:data.data}).subscribe(res => {

                        this.alertService.showSuccess(this.translate.instant("alerts.ORGANISATIONADDEDSUCCESSFULLY"));

                        let data = this.createInsertModel(res.data);

                        this.logoUpload().then(logoId => {

                            if (!!logoId) { data.logoId = logoId }

                            this.imagesUpload().then(imageIds => {

                                if (!!imageIds) { data.imageIds = [...imageIds] }

                                if (data.imageIds.length > 0 || !!data.logoId) {

                                    this.orgService.save(data).subscribe(d => {
                                        if(!d.hasError) {
                                            this.orgService.updateData({id:data.id}).subscribe(res => {
                                                this.alertService.showSuccess(this.translate.instant("alerts.ORGANISATIONUPDATEDSUCCESSFULLY"));
                                                this.back();
                                            })
                                        } else {
                                            let errors = d.errors.map(r => {return r.message});
                                            this.alertService.showError(errors);
                                        }
                                    });
                                } else {
                                    this.back();
                                }
                            });
                        });
                    });
                } else {
                    let errors = data.errors.map(r => {return r.message});
                    this.alertService.showError(errors);
                }
            })
        } else {
          this.clicked=false;
          this.logoUpload().then(logoId => {

                if (!!logoId) { insertModel.logoId = logoId }

                this.imagesUpload().then(imageIds => {

                    if (!!imageIds) { insertModel.imageIds = [...insertModel.imageIds, ...imageIds] }

                    this.orgService.save(insertModel).subscribe(d => {
                      this.clicked=true;
                      if(!d.hasError) {
                            this.orgService.updateData({id:insertModel.id}).subscribe(res => {
                                this.alertService.showSuccess(this.translate.instant("alerts.ORGANISATIONUPDATEDSUCCESSFULLY"));
                                this.back();
                            })
                        } else {
                            let errors = d.errors.map(r => {return r.message});
                            this.alertService.showError(errors);
                        }
                    });
                });
            });
        }
    }

    public isFormValid() {
        if (!super.isFormValid()) {
            this.alertService.showError(this.translate.instant("alerts.INVALID"));
            return false;
        }
        return true;
    }

    public get isEdit() {
        return this.activeRoute.snapshot.url.join('').includes("edit");
    }

	// back() {
	// 	this.router.navigateByUrl("organisations/list");
	// }
}
