import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TreeNode, TREE_ACTIONS, KEYS, IActionMapping } from 'angular-tree-component';
//models
import { CategoryModel } from '../../models/CategoryModel';
//services
import { CategoryService } from '../../services/category.service';
//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
import {SortingPlacesModalComponent} from "../../../place.module/places/list/sortingPlaces-modal/sortingPlaces-modal.component";
import {ModalsService} from "../../../bootstrap-components.module/modals/modals.service";
import {SortingCategoriesModalComponent} from "./sortingCategories-modal/sortingCategories-modal.component";

const actionMapping: IActionMapping = {
	mouse: {
		dblClick: TREE_ACTIONS.TOGGLE_EXPANDED,
		click: (tree, node, $event) => {
			$event.shiftKey
			? TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event)
			: TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
		}
	},
	keys: {
		[KEYS.ENTER]: (tree, node, $event) => alert(`This is ${node.data.name}`)
	}
};
@Component({
	selector: 'app-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CategoryListComponent extends AuthenticatedComponent implements OnInit {

	constructor(private categoryService: CategoryService,
              private modalService: ModalsService,
              protected translate: TranslateService
  ) {
		super();
	}

	public categoryList: Array<CategoryModel> = [];
	public data: Array<CategoryModel> = [];
  private modalRef: any = null;
  public customTemplateStringOptions = {
		isExpandedField: 'expanded',
		idField: 'uuid',
		actionMapping,
		allowDrag: true
	};

	ngOnInit() {
		this.categoryService.getAll().subscribe(data => {
			this.categoryList = data.filter(item => item.parentId == null);
		});
	}

  public openSortmodal()
  {
    this.modalRef = this.modalService.simpleLg("Sort Categories", SortingCategoriesModalComponent,);
  }

  public save(category: CategoryModel ) {
		this.categoryService.save(category).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.CATEGORYUPDATEDSUCCESSFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}

	public childrenCount(node: TreeNode): string {
		return node && node.children ? `(${node.children.length})` : '';
	}

	public filterNodes(text, tree) {
		tree.treeModel.filterNodes(text, true);
	}

	public go(data) {
		this.router.navigateByUrl("category/edit/" + data.id);
	}

}
