import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
//components
import { PlaceShellComponent } from './place-shell.component';
import { PlaceListComponent } from './places/list/list.component';
import { PlaceEditComponent } from './places/edit/edit.component';
import { PlacePersonalEditComponent } from './places/edit/place-personal/edit/edit.component';

export const place = [
     {
        path: "list",
        component: PlaceListComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "add",
        component: PlaceEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "edit/:id",
        component: PlaceEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: "user/add/:placeId/:orgId",
        component: PlacePersonalEditComponent,
        canActivate: [RedirectToLoginGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      }
]
