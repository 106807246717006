import { Injectable } from "@angular/core";
import { DataStore } from "../shared/models/DataStore";

@Injectable()
export class AdditionalStore extends DataStore {
    constructor() {
        super();
    }

    public initializeStore() {
        this.set('additionalInfo', null);
    }
}