import { Component, OnInit } from "@angular/core";
//modele
import { UserProfileModel } from "../../../account.module/models/UserProfileModel";
//service
import { UserblockService } from "./userblock.service";
//component
import { AuthenticatedComponent } from "../../../shared/components/AuthenticatedComponent";
import { AccountService } from "src/app/account.module/services/account.service";
@Component({
  selector: "app-userblock",
  templateUrl: "./userblock.component.html",
  styleUrls: ["./userblock.component.scss"],
})
export class UserblockComponent
  extends AuthenticatedComponent
  implements OnInit
{
  user: UserProfileModel = new UserProfileModel();
  picture: string = null;

  constructor(
    public userblockService: UserblockService,
    private accountService: AccountService
  ) {
    super();
  }

  ngOnInit() {
    this.accountService.loadUserProfile.subscribe(()=>{
        this.user = this.store._("account.loggedUser");
    });
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }
}
