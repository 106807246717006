import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//services
import { AuthenticationService } from '../services/authentication.service';
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss']
})
export class LogoutComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private authService: AuthenticationService) {
		super();
	}

	ngOnInit() {
		this.authService.logout();
		this.goToUrl("/login");
	}

	logout(){
	}
}
