import { Component, Input, OnChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { PlaceModel } from '../../../models/PlaceModel';
//services
import { PlaceService } from "../../../services/place.service";
//components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
@Component({
	selector: 'app-place-contact',
	templateUrl: './place-contact.component.html',
	styleUrls: ['./place-contact.component.scss']
})
export class PlaceContactComponent extends AuthenticatedComponent implements OnChanges {


	@Input() place: PlaceModel = new PlaceModel();
	lat: number = null;
	lng: number = null;
	zoom: number = 15;

	constructor(
		private activeRoute: ActivatedRoute,
		public placeService :PlaceService) {
		super();
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

 	ngOnChanges() {

		if (this.isEdit) {

			if(!!this.place.addressLocation.latitude ) {this.lat =+ this.place.addressLocation.latitude};
			if(!!this.place.addressLocation.longitude ) {this.lng =+ this.place.addressLocation.longitude};
		} else {
			this.lat = 40.177238;
			this.lng = 44.513062;
		}
 	}

	ngOnInit() {

	}

	ngAfterViewInit() {
console.log(this.isEdit , this.place)

	}

	myMarker(lat:number, lng:number) {
		let city , region ,country ;
		this.place.addressLocation.latitude  = lat;
		this.place.addressLocation.longitude = lng;
		var latlng = new google.maps.LatLng(lat, lng);
		new google.maps.Geocoder().geocode({ 'location': latlng }, (res)=> {
			this.place.addressLocation.formattedAddress = res[0].formatted_address;
			let filteredData = res.filter((item ) => item.types[0]=='locality' );

			if (filteredData.length > 0) {

				for (var i=0; i < filteredData[0].address_components.length; i++)
				{
					if (filteredData[0].address_components[i].types[0] == "locality") {
						city = filteredData[0].address_components[i];
					}
					if (filteredData[0].address_components[i].types[0] == "country") {
						country = filteredData[0].address_components[i];
					}
				}

				this.place.addressLocation.cityShortName = city.short_name;
				this.place.addressLocation.countryLongName = country.long_name;
				this.place.addressLocation.countryShortName = country.short_name;

			} else {
				this.alertService.showError("Unnamed Road");
			}
		})
	}
}
