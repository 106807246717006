import { Component, OnInit } from '@angular/core';
//models
import { NotificationModel } from '../../models/NotificationModel';
//services
import { NotificationRoleService } from '../../services/notfication.service';
import { PlaceService } from '../../../place.module/services/place.service';

//componts
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {IngFilter} from "../../../ingredient.module/ingredient/list/list.component";
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class NotificationRoleListComponent extends AuthenticatedComponent implements OnInit {


	constructor(
    private placeService: PlaceService,

    private notService: NotificationRoleService) {
		super()
	}

	public notList: Array<any> = [];
	public data: Array<NotificationModel> = [];

  public placeList: Array<any> = [];
  public placename: number=0;
	public columns: Array<any> = [

	{ title: 'id', name: 'id' },
	{ title: 'title', name: 'title' },
	{ title: 'name', name: 'role' },
    { title: 'placeName', name: 'place Name' },
	{ title: 'settings', name: 'settings' }

	];

	ngOnInit() {
		this.notService.getAll().subscribe(data => {
			this.notList = data;
		});
    this.placeService.getAllSimplified().subscribe(res => {
      this.placeList = res;
    });
	}

  public config: any = {
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered', 'mb-0', 'd-table-fixed']
  };

  public filter() {
    this.notService.getAllByPlace(this.placename).subscribe(res=> {
      this.notList = res;
    })
  }

  public clear(){
    this.placename = 0;
    this.filter();
  }

  public onChangeTable(config: any): any {
    this.notService.getAllNotificationRoleRef().subscribe(res=>{
      this.notList = res;

    });
  }

	public save(not) {

		this.notService.roleToggle(not).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess('Notification updated successfully.');
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}

}
