import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { PlaceModel } from '../../models/PlaceModel';
import { PlaceInsertModel } from '../../models/PlaceModel';
//services
import { PlaceService } from "../../services/place.service";
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
import {MenuService} from "../../../menu.module/services/menu.service";
@Component({
	selector: 'place-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss']
})
export class PlaceEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		public placeService :PlaceService,
    protected translate: TranslateService,
    private menuService: MenuService,
    private activeRoute: ActivatedRoute) {
		super();
	}

	logos;
	covers;
	images;
	place: PlaceModel = new PlaceModel();
	copyList:Array<any>;
	setParent: SetParent = new SetParent();

	ngOnInit() {
		if (this.isEdit) {
			let placeId = this.activeRoute.snapshot.params['id'];
			this.placeService.get(placeId).subscribe(res => {
				if (!!res) {
					this.place = res;
          this.menuService.getAllForCopy(this.place.organisationId).subscribe((data=> {
            this.copyList=data;
          }));
				}else {
					this.alertService.showError(this.translate.instant("alerts.SOMETHINGWENTWRONG"));
					this.back();
				}
			});
		}

	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public upload(event) {
		switch (event.key) {
			case "logo":
			this.logos = event.value;
			break;
			case "cover":
			this.covers = event.value;
			break;
			case "images":
			this.images = event.value;
			break;
		}
	}

	public logoUpload():Promise<Array<number>> {
		return new Promise((resolve, reject) => {
			if(this.logos) {
        this.placeService.multipleUploadFile(this.logos).subscribe(res => {
          resolve([...res.data.map(item => { return item.id })]);
        });
			} else {
				resolve();
			}
		});
	}

	public coverUpload():Promise<Array<number>> {
		return new Promise((resolve, reject) => {
			if (this.covers) {
        this.placeService.multipleUploadFile(this.covers).subscribe(res => {
          resolve([...res.data.map(item => { return item.id })]);
        });
			} else {
				resolve();
			}

		});
	}

	public imagesUpload():Promise<Array<number>> {
		return new Promise((resolve, reject) => {
			if (this.images) {
				this.placeService.multipleUploadFile(this.images).subscribe(res => {
					resolve([...res.data.map(item => { return item.id })]);
				});
			} else {
				resolve();
			}
		});
	}

	createInsertModel(place:PlaceModel): PlaceInsertModel {
		let insertModel: PlaceInsertModel = new PlaceInsertModel();
		insertModel.id = place.id;
		insertModel.organisationId = place.organisationId;
		insertModel.wifiId = place.wifiId;
		insertModel.availabilityRadius = place.availabilityRadius;
		insertModel.serviceFeeInPercent = place.serviceFeeInPercent;
		insertModel.sortOrder = place.sortOrder;
		insertModel.averageCount = place.averageCount;
		insertModel.isNew = place.isNew;
		insertModel.isParent = place.isParent;
		insertModel.averageRate = place.averageRate;
		insertModel.name = place.name;
		insertModel.description = place.description;
		insertModel.openHour = place.openHour;
		insertModel.closeHour = place.closeHour;
		insertModel.wifi = place.wifi;
		insertModel.addressLocation = place.addressLocation;
		insertModel.addressDisplayName = place.addressDisplayName;
		insertModel.addressDisplayNameTranslationId = place.addressDisplayNameTranslationId;
		insertModel.contact = place.contact;
		insertModel.imageIds = place.imageIds;
		insertModel.logoIds = place.logoIds;
		insertModel.coverIds = place.coverIds;
		insertModel.categoryIds = place.categoryIds;
		insertModel.additionalInfoIds = place.additionalInfoIds;
		insertModel.nameTranslationId = place.nameTranslationId;
		insertModel.descriptionTranslationId = place.descriptionTranslationId;
		insertModel.isActive = place.isActive;

		return insertModel;
	}

	public save() {

		if (!this.isFormValid()) return;

		let insertModel = this.createInsertModel(this.place);

		if (!this.place.id) {
			this.placeService.save(insertModel).subscribe(data => {
				if(!data.hasError) {

					this.placeService.updateData({id:data.data}).subscribe(res => {

						this.alertService.showSuccess(this.translate.instant("alerts.PLACEADDEDSUCCESSFULLY"));

						let data = this.createInsertModel(res.data);

						this.logoUpload().then(logoIds => {

							if (!!logoIds) { data.logoIds = [ ...logoIds] }

								this.coverUpload().then(coverIds => {

									if (!!coverIds) { data.coverIds = [ ...coverIds] }

										this.imagesUpload().then(imageIds => {

											if (!!imageIds) { data.imageIds = [ ...imageIds] }

												if(data.imageIds.length > 0||data.coverIds.length > 0||data.logoIds.length > 0) {

													this.placeService.save(data).subscribe(d => {
														if(!d.hasError) {
															this.setParent.placeId = data.id;
															this.setParent.placeIds = this.place.childrenIds;
															this.placeService.setParent(this.setParent).subscribe(res => {
																if(!res.hasError) {
																	this.placeService.updateData({id:data.id}).subscribe(res => {
																		this.alertService.showSuccess(this.translate.instant("alerts.PLACEUPDATEDSUCCESSFULLY"));
																		this.back();
																	});
																} else {
																	let errors = d.errors.map(r => {return r.message});
																	this.alertService.showError(errors);
																}
															});
														} else {
															let errors = d.errors.map(r => {return r.message});
															this.alertService.showError(errors);
														}
													});
												} else {
													this.back();
												}
											});
								});
						});
					});
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			})
		} else {

			insertModel.addressLocation.id = null;
      this.logoUpload().then(logoIds => {

				if (!!logoIds) { insertModel.logoIds = [...insertModel.logoIds, ...logoIds] }

					this.coverUpload().then(coverIds => {

						if (!!coverIds) { insertModel.coverIds = [...insertModel.coverIds, ...coverIds] }

							this.imagesUpload().then(imageIds => {

								if (!!imageIds) { insertModel.imageIds = [...insertModel.imageIds, ...imageIds] }

									this.placeService.save(insertModel).subscribe(d => {
                    if(!d.hasError) {

											this.setParent.placeId = insertModel.id
											this.setParent.placeIds = this.place.childrenIds;
											this.placeService.setParent(this.setParent).subscribe(res => {
												if(!res.hasError) {
													this.placeService.updateData({id:insertModel.id}).subscribe(res => {
														this.alertService.showSuccess('Place updated successfully.');
														this.back();
													})
												} else {
													let errors = d.errors.map(r => {return r.message});
													this.alertService.showError(errors);
												}
											});

										} else {
											let errors = d.errors.map(r => {return r.message});
											this.alertService.showError(errors);
										}
									});
							});
					});
			});
		}
	}

	public isFormValid() {
		if (!super.isFormValid()) {
			this.alertService.showError("The form is not valid");
			return false;
		}
		return true;
	}
}

export class SetParent {
	placeId: number;
	placeIds: Array<any> = [];
	isActive: boolean = true;
}
