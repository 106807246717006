import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// services
import { PlaceService } from '../../../../../place.module/services/place.service';
import { SectionService } from '../../../../../menu.module/services/menu.service';
import { OrganisationService } from '../../../../services/organisation.service';
import { AccountService } from '../../../../../account.module/services/account.service';
import { TextMaskConfig } from 'angular2-text-mask';
// models
import { KeyValuePair } from '../../../../../models/KeyValuePair';
import { RoleModel } from '../../../../../menu.module/models/SectionModel';
import { PlaceModel } from '../../../../../place.module/models/PlaceModel';
import { CreateUserModel } from '../../../../../account.module/models/CreateUserModel';
// components
import { AuthenticatedComponent } from '../../../../../shared/components/AuthenticatedComponent';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class OrganisationUsersEditComponent
  extends AuthenticatedComponent
  implements OnInit {
  public formId = 'user-form';
  public user: CreateUserModel = new CreateUserModel();
  public roleList: Array<RoleModel> = [];
  public placeList: Array<PlaceModel> = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private placeService: PlaceService,
    private sectionService: SectionService,
    protected translate: TranslateService,

    private orgService: OrganisationService,
    private accountService: AccountService
  ) {
    super();
  }

  ngOnInit() {
    const orgId = this.activeRoute.snapshot.params.orgId;
    const userId = this.activeRoute.snapshot.params.userId;
    this.accountService.getUser(orgId, userId).subscribe((res) => {
      if (!!res) {
        this.user = res;
        console.log(res);
      } else {
        this.alertService.showError(
          this.translate.instant('alerts.USERDOESNTEXIST')
        );
      }
    });
  }
}
