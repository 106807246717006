import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//services
import { PlaceService } from "../../../services/place.service";
import { OrganisationService } from "../../../../Organisation.module/services/organisation.service";
import { CategoryService } from "../../../../category.module/services/category.service";
import { AdditionalService } from "../../../../additional.module/services/additional.services";
//models
import { PlaceModel } from '../../../models/PlaceModel';
import { KeyValuePair } from '../../../../models/KeyValuePair';
import { CategoryModel } from '../../../../category.module/models/CategoryModel';
import { AdditionalInfoModel } from '../../../../additional.module/models/AdditionalInfoModel';

//components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
@Component({
  selector: 'app-place-info',
  templateUrl: './place-info.component.html',
  styleUrls: ['./place-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlaceInfoComponent extends AuthenticatedComponent implements OnInit {

  constructor(
    public placeService: PlaceService,
    public categoryService: CategoryService,
    public additionalService: AdditionalService,
    public orgService: OrganisationService,
    private activeRoute: ActivatedRoute) {
    super();
  }

  @Input() isEdit: boolean = false;
  @Input() place: PlaceModel = new PlaceModel();
  categoryList: Array<CategoryModel> = [];
  additionalInfoList: Array<AdditionalInfoModel> = [];
  organisationList: Array<any> = [];

  ngOnInit() {
    this.categoryService.getAll().subscribe(res => {
      this.categoryList = res;
    })
    this.additionalService.getAll().subscribe(res => {
      this.additionalInfoList = res;
    })
    this.orgService.getAll().subscribe(res => {
      this.organisationList = res;
    })
  }

}


