import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class RequestShellComponent extends AuthenticatedComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() { }
}
