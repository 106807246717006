
export class NotificationModel {
    type: string;
    message: string;
    dismissable: boolean;
    id: number = -1;
    closeAfter: number = 1000;
    category: string = "";

    constructor(type: string, message: string, dismissable?: boolean, category?: string, closeAfter?: number, id?: number) {
        this.type = type;
        this.message = message;
        this.dismissable = (dismissable != null && dismissable != undefined) ? dismissable : true;
        this.closeAfter = closeAfter;
        this.id = id || Math.trunc(Math.random() * 1000);
        this.category = category || "";
    }
}

export enum NotificationType {
    SUCCESS,
    INFO,
    WARNING,
    DANGER
}