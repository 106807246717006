import { LoginPartnerComponent } from './login-partner/login-partner.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RecoverComponent } from './recover/recover.component';
import { LockComponent } from './lock/lock.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { AuthenticationService } from './services/authentication.service';
import { LogoutComponent } from './logout/logout.component';
import { NgxSelectModule } from 'ngx-select-ex';

/* Use this routes definition in case you want to make them lazy-loaded */
/*const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
];*/
const CustomSelectOptions: any = { // Check the interface for more options
    optionValueField: 'id',
    optionTextField: 'name'
};
@NgModule({
    imports: [
        SharedModule,
        NgxSelectModule.forRoot(CustomSelectOptions),
        // RouterModule.forChild(routes)
    ],
    declarations: [
        LoginComponent,
        LoginPartnerComponent,
        LogoutComponent,
        RegisterComponent,
        RecoverComponent,
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component,
    ],
    exports: [
        RouterModule,
        LoginComponent,
        LoginPartnerComponent,
        LogoutComponent,
        RegisterComponent,
        RecoverComponent,
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component
    ],
    providers:[
    AuthenticationService]
})
export class PagesModule { }


