import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { StatusStore } from './status.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';

import { StatusService } from "./services/status.service";
import { StatusShellComponent } from './status-shell.component';
import { StatusListComponent } from './status/list/list.component';
import { StatusEditComponent } from './status/edit/edit.component';

@NgModule({
  imports: [
  CommonModule,
  BrowserModule,
  SharedModule,
  RouterModule,
  NgxQRCodeModule,
  ],
  declarations: [
    StatusShellComponent,
    StatusListComponent,
    StatusEditComponent,
  ],
  exports: [],
  entryComponents: [],

  providers: [ StatusService ]
})
export class StatusModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
      ...services.statusService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let statusService = appinjector.get(StatusService);

    return {
      store,
      statusService,
    }
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    StatusStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    StatusStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.statusService.preloadData();
  }

}
