import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxQRCodeModule } from "ngx-qrcode2";
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { appInjector } from "../bootstrap-components.module/utils/appInjector";
import { AppStore } from "../shared/services/store.service";
import { LogStore } from "../log.module/log.store";
import { RequestShellComponent } from "./request-shell.component";
import { RequestStore } from "./request.store";
import { RequestEditComponent } from "./request/edit/edit.component";
import { RequestListComponent } from "./request/list/list.component";
import { RequestService } from "./services/request.service";


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    NgxQRCodeModule,
  ],
  declarations: [RequestShellComponent, RequestListComponent, RequestEditComponent],
  exports: [],
  entryComponents: [],

  providers: [RequestService],
})
export class RequestModule {
  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [...services.statusService.preloadData()],
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let statusService = appinjector.get(RequestService);

    return {
      store,
      statusService,
    };
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    LogStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    RequestStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.statusService.preloadData();
  }
}
