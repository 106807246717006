import { Component, OnInit , OnChanges, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
//models
import { DiscountModel } from '../../models/DiscountModel';
import { KeyValuePair } from '../../../models/KeyValuePair';
//services
import { DiscountService } from "../../services/discount.service";
import { PlaceService } from "../../../place.module/services/place.service";
import { ClientService } from "../../../client.module/services/client.service";
//components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {TranslateService} from "@ngx-translate/core";
declare var moment;
@Component({
	selector: 'discount-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DiscountEditComponent extends AuthenticatedComponent implements OnInit {

	constructor(
		private datePipe: DatePipe,
		private placeService: PlaceService,
    protected translate: TranslateService,

    private activeRoute: ActivatedRoute,
		private clientService: ClientService,
		private discountService: DiscountService) {
		super();
	}

	public placeId: string = null;
	public discount: DiscountModel =  new DiscountModel();
	public placeList: Array<KeyValuePair> = [];
	public clientList: Array<any> = [];
	public place: any = null;
	public date:any = [];
  public now = new Date();
  clicked:boolean=false;



	ngAfterViewInit() {
		this.placeId = this.activeRoute.snapshot.params['placeId'];

		if ( !!this.placeId ) {
			this.discount.placeId = Number(this.placeId);

			this.placeService.get(this.placeId).subscribe(res => {
				this.place = res.name;
			});
		}
	}

    bsConfig = {
        containerClass: 'theme-angle',
      dateInputFormat: 'DD-MM-YYYY',
      minDate:new Date(this.now.getTime()+24*60*60*1000)
       // rangeInputFormat: 'DD-MM-YYYY'
    }
	ngOnInit() {

		// this.clientService.getAll().subscribe(res => {
		// 	console.log(res)
		// 	this.clientList = res.map(k => new ListClass(k.id, k.userName));
		// });

		if (this.isEdit) {
			let discountId = this.activeRoute.snapshot.params['id'];
			this.discountService.get(discountId).subscribe(res => {
				if (!!res) {
					this.discount = res;
					this.date.push(moment(res.startDate).format('dd-MM-yyyy'));
					this.date.push(moment(res.expireDate).format('dd-MM-yyyy'));
				}else {
					this.alertService.showError(this.translate.instant("alerts.INVALID"));
					this.back();
				}
			});
		}
	}




	getdate(event){
      	this.discount.startDate = event[0];
      	this.discount.expireDate = event[1];
      	this.changeClient();
	}


	changeClient(){


		// this.clientService.getAllUsers().subscribe(res=> {
		// 	console.log(res,3333)
		// })
		this.discountService.GetUsersByDate(this.discount.placeId, this.datePipe.transform(this.discount.startDate,'yyyy-MM-dd'), this.datePipe.transform(this.discount.expireDate,'yyyy-MM-dd')).subscribe(res => {
			this.clientList = res.map(k => new ListClass(k.id, k.login));

		})
	}

	public get isEdit() {
		return this.activeRoute.snapshot.url.join('').includes("edit");
	}

	public isFormValid() {

		if (!super.isFormValid()) {
			this.alertService.showError(this.translate.instant("alerts.INVALID"));
			return false;
		}
		return true;
	}

	public getClients(event) {
		this.discount.userIds = event.map(i => i.key);
	}

	public save() {

		delete this.discount.user;
		delete this.discount.place;

		if (!this.isFormValid()) return;

		if (!this.discount.id ) {
      this.clicked=true;
			this.discountService.save(this.discount).subscribe(data => {
			  this.clicked=false;
				if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.DISCOUNTADDEDSUCCESSFULLY"));
					this.back();
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});

		} else {
      this.clicked=true;
			this.discountService.save(this.discount).subscribe(data => {
        this.clicked=false
        if(!data.hasError) {
					this.alertService.showSuccess(this.translate.instant("alerts.DISCOUNTUPDATEDSUCCESSFULLY"));
					this.back();
				} else {
					let errors = data.errors.map(r => {return r.message});
					this.alertService.showError(errors);
				}
			});
		}
	}
}

export class ListClass {

	constructor(id, name) {
		this.id = id;
		this.name = name;
	}

	id:number ;
	name: string;
}


export class DateFilter {
	startDate: Date = null;
	expireDate: Date = null;
}
