const Home = {
  text: 'Home',
  link: '/home',
  icon: 'icon-home',
};

const Organisations = {
  text: 'Organisations',
  link: '/organisations/list',
  icon: 'fas fa-sitemap',
};

const Places = {
  text: 'Places',
  link: '/place/list',
  icon: 'fas fa-store-alt',
};

const Category = {
  text: 'Categories',
  link: '/category/list',
  icon: 'fas fa-tags',
};

const Menu = {
  text: 'Menu',
  icon: 'fas fa-utensils',
  link: '/menu/list',
};
// {
//     text: 'Options',
//     submenu: [
//     {
//         text: 'Options List',
//         link: '/option/list'
//     },
//     {
//         text: 'Propertyes List',
//         link: '/property/list'
//     },
//     ]
// },

const Table = {
  text: 'Tables',
  link: '/tables/table-group/list',
  icon: 'fas fa-chair',
};

const Status = {
  text: 'Status',
  link: '/status/list',
  icon: 'fas fa-check',
};

const Log = {
  text: 'Log',
  link: '/log/list',
  icon: 'fas fa-check',
};

const Additional = {
  text: 'Additional Info',
  link: '/additional-info/list',
  icon: 'fas fa-clipboard',
};

const Order = {
  text: 'Orders',
  link: '/order/list',
  icon: 'fas fa-concierge-bell',
};

const Intro = {
  text: 'Intros',
  link: '/intro/list',
  icon: 'fas fa-mobile-alt',
};

const Client = {
  text: 'Clients',
  link: '/client/list',
  icon: 'fas fa-users',
};

const Settings = {
  text: 'Settings',
  icon: 'fas fa-cog',
  submenu: [
    {
      text: 'Privacies',
      link: '/settings/privacy/list',
    },
    {
      text: 'Measurenits',
      link: '/settings/measureunit/list',
    },
    {
      text: 'Ingredients',
      link: '/ingredient/list',
    },
  ],
};

const Notifications = {
  text: 'Notifications',
  icon: 'fas fa-bell',
  link: '/notification/list',
};

const Discount = {
  text: 'Discounts',
  link: '/discount/list',
  icon: 'fas fa-percent',
};
const Request = {
  text: 'Requests',
  link: '/request/list',
  icon: 'fas fa-clipboard',
};

const headingMain = {
  text: 'Main Navigation',
  heading: true,
};

const headingComponents = {
  text: 'Components',
  heading: true,
};

const headingMore = {
  text: 'More',
  heading: true,
};

export const admin = [
  headingMain,
  Order,
  Organisations,
  Places,
  Menu,
  Additional,
  Category,
  Notifications,
  Status,
  Client,
  Intro,
  Settings,
  Log,
  Request
];
export const orgAdmin = [
  headingMain,
  Order,
  Organisations,
  Places,
  Menu,
  // Notifications
];

export const placeAdmin = [
  headingMain,
  Order,
  Menu
];

export const partner = [
  headingMain,
  Order
  // Notifications
];
