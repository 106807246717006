export class CategoryModel{
	id: number;
	name: string;
	parentId: number;
	description: string;
	imageUrl: string;
	parent: object;
	children: Array<any> = [];
	nameTranslationId: number = 0;
	descriptionTranslationId: number = 0;
	sortOrder:number=0;
	isActive: boolean = true;
}


export class CategoryInsertModel{
	id: number;
	name: string;
	parentId: number;
	description: string;
	imageUrl: string;
	nameTranslationId: number = 0;
	descriptionTranslationId: number = 0;
  sortOrder:number=0;
  isActive: boolean = true;
}
