import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
//components
import { TablesShellComponent } from './table-shell.component';
import { TableListComponent } from './tables/list/list.component';
import { TableEditComponent } from './tables/edit/edit.component';
import { TableGropesListComponent } from './table-groupes/list/list.component';
import { TableGropesEditComponent } from './table-groupes/edit/edit.component';

export const table = [
  {
    path: "table/list",
    component: TableListComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "table/add",
    component: TableEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "table/add/:placeId",
    component: TableEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "table/edit/:id/:placeId",
    component: TableEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "table/edit/:id",
    component: TableEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "table-group/list",
    component: TableGropesListComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "table-group/add",
    component: TableGropesEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "table-group/add/:placeId",
    component: TableGropesEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "table-group/edit/:id",
    component: TableGropesEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
  {
    path: "table-group/edit/:id/:placeId",
    component: TableGropesEditComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  }
]
