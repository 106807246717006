import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PropertyModele } from '../models/PropertyModele';

@Injectable()
export class PropertyService extends CrudService<PropertyModele> {

    protected serviceItemsKey = 'property';

    constructor(protected store: AppStore) {
        super('Property', null);
    }

    public preloadData() {
        return [
            this.getAllProperty(),
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

    public getAllProperty() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetAll'))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }
  public Export(optionId)
  {
    return this.apirequest("get",this.apiCallTo(`/Export/Property?parentId=${optionId}`))
      .pipe(
        map(i=>i.data)
      )
  }

  public Import(file: any ,optionId:number) {
    var formData: FormData = new FormData();
    formData.append("file", file[0]);
    return this.apirequest("post", this.apiCallTo(`/Import/Property?optionId=${optionId}`), "undefined", formData);
  }

    public GetAllByOptionId(id) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAllByOptionId?optionId=${id}`))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }
    public getSortmenu(optionId)
    {
      return this.apirequest("get", this.apiCallTo(this.serviceApiUrl+`/GetAllForSorting?optionId=${optionId}`))
        .pipe(
          map(i => i.data),
        );
    }
  
    public UpdateSortmenu(sortList)
    {
      return this.apirequest("post", this.apiCallTo(this.serviceApiUrl + `/UpdateSortOrder`), null, JSON.stringify(sortList))
        .pipe(
          map(i => i.data),
        );
    }
}
