import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IngredientModele } from '../models/ingredientModele';

@Injectable()
export class IngredientService extends CrudService<IngredientModele> {

    protected serviceItemsKey = 'ingredient';

    constructor(protected store: AppStore) {
        super('Ingredient', null);
    }

    public preloadData() {
        return [
            this.getAllIngredient(),
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

    public getAllIngredient(page = 1, pagesize = 10) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAll?page=${page}&pagesize=${pagesize}`))
            .pipe(
                map(i => i.data),
                // tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }   

    public getAllByPlace(placeId) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAll?placeId=${placeId}`))
            .pipe(
                map(i => i.data),
                // tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

    public getAllByName(obj){
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAllByName?placeName=${obj.placeName}&ingName=${obj.ingName}&page=${obj.page}&pageSize=${obj.pageSize}`))
            .pipe(
                map(i => i.data),
            );
    }
}