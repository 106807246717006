import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// services
import { PlaceService } from '../../../../place.module/services/place.service';
// models
import { PlaceModel } from '../../../../place.module/models/PlaceModel';
import { PlaceInsertModel } from '../../../../place.module/models/PlaceModel';
// components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
@Component({
selector: 'app-organisation-places',
templateUrl: './organisation-places.component.html',
styleUrls: ['./organisation-places.component.scss']
})
export class OrganisationPlacesComponent extends AuthenticatedComponent implements OnInit {

@Input() placeList: Array<PlaceModel> = [];
public data: Array<PlaceModel> = [];

constructor(
private activeRoute: ActivatedRoute,
private placeService: PlaceService) {
super();
}

public columns: Array<any> = [
{ title: 'id', name: 'id' },
{ title: 'name', name: 'name' },
{ title: 'settings', name: 'settings' },
];

	ngOnInit() {
	}

	createInsertModel(place: PlaceModel): PlaceInsertModel {
		const insertModel: PlaceInsertModel = new PlaceInsertModel();
		insertModel.id = place.id;
		insertModel.organisationId = place.organisationId;
		insertModel.parentId = place.parentId;
		insertModel.wifiId = place.wifiId;
		insertModel.availabilityRadius = place.availabilityRadius;
		insertModel.serviceFeeInPercent = place.serviceFeeInPercent;
		insertModel.averageCount = place.averageCount;
		insertModel.isNew = place.isNew;
		insertModel.isParent = place.isParent;
		insertModel.averageRate = place.averageRate;
		insertModel.name = place.name;
		insertModel.description = place.description;
		insertModel.openHour = place.openHour;
		insertModel.closeHour = place.closeHour;
		insertModel.wifi = place.wifi;
		insertModel.addressLocation = place.addressLocation;
		insertModel.contact = place.contact;
		insertModel.imageIds = place.imageIds;
		insertModel.logoIds = place.logoIds;
		insertModel.coverIds = place.coverIds;
		insertModel.categoryIds = place.categoryIds;
		insertModel.additionalInfoIds = place.additionalInfoIds;
		insertModel.nameTranslationId = place.nameTranslationId;
		insertModel.descriptionTranslationId = place.descriptionTranslationId;
		insertModel.isActive = place.isActive;

		return insertModel;
	}

	public save(place: PlaceModel ) {

		const insertModel = this.createInsertModel(place);

		this.placeService.save(insertModel).subscribe(data => {
			if (!data.hasError ) {
				this.alertService.showSuccess('Place updated successfully.');
			} else {
				const errors = data.errors.map(r =>r.message);
				this.alertService.showError(errors);
			}
		});
	}

}
