import { AuthenticatedComponent } from '../shared/components/AuthenticatedComponent';
import { Component, OnInit } from '@angular/core';
import { MenuService } from './services/menu.service';


@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class MenuShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private menuService: MenuService) {
        super();
    }

    ngOnInit() {
        // if (!this.moduleDataLoaded) {
        //     this.menuService.loadData().subscribe(
        //         r => {
        //             this.alertService.showInfo(`Menu data has been loaded!`);
        //             this.goToUrl('/menu/list');
        //         },
        //         e => {
        //             this.alertService.showError(`Loading Menu data had encounter error.`);
        //             this.goToUrl('/dashboard');
        //         });
        // } else {
        //     this.alertService.showInfo(`Menu data has been loaded!`);
        //     this.goToUrl('/menu/list');
        // }
    }

    get moduleDataLoaded() {
        const storeKeysToVerify = ['menu'];
        return storeKeysToVerify.map(k => !!this.store._(k)).
            reduce((acc, val) => {
                return acc && val;
            }, true);
    }
}
