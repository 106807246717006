import { Component, Input } from "@angular/core";
import { TranslationsProvider } from "../utils/translation-directive/translations.provider";

@Component({
    selector: 'action',
    template: `

        <a  *ngIf="href" class="btn btn-round btn-{{color}}" [routerLink]="[href]" title="{{text}}">
        <i [class]="icon" aria-hidden="true"></i> {{text}}
        </a>

        <a *ngIf="!href" class="btn btn-round btn-{{color}}" title="{{text}}">
        <i [class]="icon" aria-hidden="true"></i> {{text}}
        </a>

    `,
    styles: [`

    `]
})
export class ActionComponent {

    @Input() icon: string = "";
    private _text: string;
    @Input() get text(): string {
        return this._text;
    }
    set text(v: string) {
        this._text = v;//this.translateProvider.getTranslationFor(v) || v;
    }


    @Input() href: string = null;
    @Input() color: string = "info"
    constructor(protected translateProvider: TranslationsProvider) {
    }
}
