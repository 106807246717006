import { NgModule } from "@angular/core";
import {
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    SortableModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    TypeaheadModule,

    BsDatepickerModule
} from 'ngx-bootstrap'
//modules
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
//services
import { ModalsService } from "./modals/modals.service";
import { NotifyService } from "./notifications/notify.service";
//components
import { ActionComponent } from "./card/action";
import { UploadComponent } from "./upload/upload.component";
import { TextboxComponent } from "./textbox/textbox.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { TextAreaComponent } from "./textarea/textarea.component";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { DatepickerComponent } from "./datepicker/datepicker.component";
import { SimpleInputComponent } from "./_input-component/input.component";
import { DatatableComponent } from './tables/datatable/datatable.component';
import { InformModalComponent } from "./modals/inform/inform-modal.component";
import { MultiplDropdownComponent } from "./multipldropdown/dropdown.component";
import { ConfirmModalComponent } from "./modals/confirm/confirm-modal.component";
import { NotificationsComponent } from "./notifications/notification/notification.component";
import { ModalContainerComponent } from "./modals/modal-container/modal-container.component";
import { TranslateDirective } from "./utils/translation-directive/translate.directive";
import { TranslationsProvider } from "./utils/translation-directive/translations.provider";

import { CardComponent } from "./card/card.component";
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from "@angular/router";
import { ImageViewComponent } from './image-view/image-view.component';
import {TranslateModule} from "@ngx-translate/core";

export const moduleData = {
    components: [
        //Components
        NotificationsComponent,
        CardComponent,
        DatatableComponent,
        ActionComponent,
        TextboxComponent,
        SimpleInputComponent,
        TextAreaComponent,
        ConfirmModalComponent,
        InformModalComponent,
        ModalContainerComponent,
        CheckboxComponent,
        DropdownComponent,
        MultiplDropdownComponent,
        DatepickerComponent,
        UploadComponent,
        ImageViewComponent,
        TranslateDirective,
    ],
    modules: [
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        SortableModule,
        TabsModule,
        TimepickerModule,
        BsDatepickerModule,
        TooltipModule,
        TypeaheadModule
    ],
    services: [
        NotifyService,
        ModalsService,
        TranslationsProvider,
    ],
    entryComponents: [
        ConfirmModalComponent,
        InformModalComponent,
        ModalContainerComponent,
    ]
};

export let importsModulesProviders = [

    //moduleData.ngxModules.map(m => m.forRoot())
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    SortableModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot()
]

@NgModule({
    imports: [FormsModule,
        CommonModule,
        RouterModule,
        Ng2TableModule,
        NgxDatatableModule,
        BrowserAnimationsModule,
        ...importsModulesProviders, TranslateModule],
    declarations: [
        ...moduleData.components,
        ImageViewComponent
    ],
    exports: [
        //ngx Modules
        ...moduleData.modules,
        ...moduleData.components,
    ],
    providers: [
        ...moduleData.services
    ],
    entryComponents: [...moduleData.entryComponents]
})
export class BootstrapComponentsModule {

    static entryComponents(): Array<any> {
        return [
            ...moduleData.entryComponents
        ];
    }
}
