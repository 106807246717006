import { BaseService } from "./BaseService";
import { map, tap } from 'rxjs/operators';
import { Observable, of } from "rxjs";


export class CrudService<T> extends BaseService {
    constructor(protected apiUrl: string, protected selectedCompanyId = "SelectedCompanyId") {
        super();
        this.serviceApiUrl = apiUrl || "";
    }

    protected serviceApiUrl = "";
    protected serviceItemsKey = "";

    private get serviceItemsKeyWithCo() {
        return this.serviceItemsKey;
    }

    protected get storeItems() {
        if (!this.appStore) {
            return null;
        }
        return this.appStore._(this.serviceItemsKeyWithCo);
    }

    public getAll(forceApiCall: boolean = false): Observable<T[]> {
        // let request = this.apirequest("get", this.apiCallTo(this.serviceApiUrl)).pipe(map(r => r.data));
        if (!!this.appStore) {
            if (forceApiCall || !this.storeItems || this.storeItems.length <= 0) {
                this.appStore.set(this.serviceItemsKeyWithCo, []);
                //return
                this.apirequest("get", this.apiCallTo(this.serviceApiUrl + '/GetAll')).
                    pipe(map(r => r.data), tap(d => this.appStore.set(this.serviceItemsKeyWithCo, d))).subscribe();
            }
            return this.appStore.$(this.serviceItemsKeyWithCo);
        }
        return this.apirequest("get", this.apiCallTo(this.serviceApiUrl+ '/GetAll')).pipe(map(r => r.data));
    }

    public filterData(filter: Object = {}) {
        return this.apirequest("get", this.apiCallTo(this.serviceApiUrl + "?" + this.urlEncode(filter)))
            .pipe(map(r => r.data), tap(d => this.appStore.set(this.serviceItemsKeyWithCo, d)));
    }

    public get(id): Observable<T> {
        if (!!this.appStore && !!this.appStore._(this.serviceItemsKeyWithCo)) {
            let foundItem = this.appStore._(this.serviceItemsKeyWithCo).find(e => e.id === Number(id));
            if (foundItem) {
                return of(foundItem)
            }
        }
        return this.apirequest("get", this.apiCallTo(this.serviceApiUrl + '/Get?id=' + id))
            .pipe(map(r => { return r.data }));
    }

    public save(item): Observable<any> {
        if (!item) {
            of(null);
        }
        return (!item.id || item.id === 0) ? this.create(item) : this.update(item);
    }

    public delete(id): Observable<T> {
        return this.apirequest("delete", this.apiCallTo(this.serviceApiUrl + id))
            .pipe(
                //tap(response => //console.log('delete: ' + response.data)),
                tap(response => {
                    try {
                        if (this.storeItems) {
                            this.appStore.set(this.serviceItemsKeyWithCo, this.storeItems.filter(i => i['id'] != id));
                        }
                    } catch (e) {
                        //console.log('Crud service error - method delete:', e);
                        throw new Error("Some error occured, but object was deleted with success. Please reload data!")
                    }
                })
            );
    }

    protected create(item): Observable<any> {
        return this.apirequest("post", this.apiCallTo(this.serviceApiUrl + '/Insert'), null, JSON.stringify(item))
            .pipe(
                //tap(response => console.log('createObject: ', response.data)),
                // tap(response => {
                //     if (this.storeItems) {
                //         this.appStore.set(this.serviceItemsKeyWithCo, [...this.storeItems, response.data]);
                //     }
                // })
            );
    }

    public updateData(filter: Object = {}): Observable<any> {
        return this.apirequest("get", this.apiCallTo(this.serviceApiUrl + "/Get?" + this.urlEncode(filter)))
            .pipe(
                tap(response => {
                    if (!!this.storeItems) {
                        if (!!this.storeItems.find(x => x.id == response.data.id)) {
                            this.appStore.set(this.serviceItemsKeyWithCo, [...this.storeItems.map(i => i['id'] == response.data.id ? response.data : i)]);
                        } else {
                            this.appStore.set(this.serviceItemsKeyWithCo, [...this.storeItems.filter(i => i.id != response.data.id), response.data]);
                        }
                    }
                }));
    }

    public uploadFile(file: any) {
        var formData: FormData = new FormData();
        formData.append("files", file);

        return this.apirequest("post", this.apiCallTo('AdminImage/AddMultiple'), "undefined", formData);
    }

    public multipleUploadFile(file: any) {
        var formData: FormData = new FormData();

        for(let item of file) {
            formData.append("files", item);
        }
        return this.apirequest("post", this.apiCallTo('AdminImage/AddMultiple'), "undefined", formData);

    }

    public deleteFile(url:string) {
        return this.apirequest("get", this.apiCallTo('AdminImage/DeactivateMultiple?url='+url))            .pipe(
                map(i => i.data),
            );
    }

    protected update(object): Observable<any> {
        return this.apirequest("post", this.apiCallTo(this.serviceApiUrl + '/Update'), null, JSON.stringify(object))
            .pipe(
                //tap(response => console.log('updateObject: ', response.data)),
                //     if (this.storeItems) {
                //         this.appStore.set(this.serviceItemsKeyWithCo, [...this.storeItems.map(i => i['id'] == response.data.id ? response.data : i)]);
                //     }
                // })
            );
    }

    public getTotal() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetTotal'))
            .pipe(
                map(i => i.data),
            );
    }

    protected updateLocal(object) {
        const items = this.storeItems.filter(v => v.id != object.id);
        this.appStore.set(this.serviceItemsKeyWithCo, [...items, object]);
    }

}
