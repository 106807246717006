import { AccountService } from "src/app/account.module/services/account.service";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { SettingsService } from "../../../core/settings/settings.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { AuthenticationService } from "../services/authentication.service";
import { PublicComponent } from "../../../shared/components/PublicComponent";
import { LoginModel } from "../models/LoginModel";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends PublicComponent implements OnInit {
  valForm: FormGroup;
  countries: Array<any>;
  phoneCode = "+374";
  isPhone = true;
  constructor(
    public fb: FormBuilder,
    public settings: SettingsService,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private authService: AuthenticationService
  ) {
    super();
  }

  ngOnInit() {
    this.valForm = this.fb.group({
      email: [null],
      phone: [null],
      password: [null, Validators.required],
    });
    this.authService.Countries().subscribe((data) => {
      this.countries = data;
    });
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    const loginM = new LoginModel();
    if (this.isPhone) {
      loginM.login = this.phoneCode + value.phone;
    } else {
      loginM.login = value.email;
    }
    loginM.password = value.password;
    if (!loginM.login) {
      this.alertService.showError(this.translate.instant("general.THISFIELDISREQUIRED"));
    } else {
      this.authService.login(loginM).subscribe((response) => {
        // var apiResponse = this.getApiResponseModel(response);
        if (!response.data) {
          const errors = response.errors.map((r) => r.message);
          this.alertService.showError(errors);
        } else {
          document.body.className = "";
          // this.goToHomeUrl(returnUrl);
          // this.goToDashboard();
          this.accountService.loadUserProfile.subscribe(() => {
            this.store.$("account.loggedUser").subscribe((c) => {
              if (
                c.roles[0] == "SuperAdmin" ||
                c.roles[0] == "OrgAdmin" ||
                c.roles[0] == "PlaceAdmin"
              ) {
                this.goToUrl("/order/list/");
              } else {
                this.storageService.save(this.storageService.Keys.Token, null);
                this.storageService.save(
                  this.storageService.Keys.LoggedUserInfo,
                  null
                );
                document.getElementById("container").innerHTML =
                  this.translate.instant("alerts.YOUMAYREGISTEREDASPARNER");
              }
            });
          });
        }
      });
    }
  }
}
