import { RequestService } from "./../../services/request.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
//models
import { KeyValuePair } from "../../../models/KeyValuePair";
import { AuthenticatedComponent } from "../../../shared/components/AuthenticatedComponent";
import { RequestModel } from "../../models/RequestModel";
@Component({
  selector: "status-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
})
export class RequestEditComponent
  extends AuthenticatedComponent
  implements OnInit
{
  constructor(
    private activeRoute: ActivatedRoute,
    private requestService: RequestService
  ) {
    super();
  }

  public request: RequestModel = new RequestModel();

  ngOnInit() {
    let requestId = this.activeRoute.snapshot.params["id"];
    this.requestService.get(requestId).subscribe((res) => {
      if (!!res) {
        this.request = res;
      } else {
        this.alertService.showError("Something went wrong!");
        this.back();
      }
    });
  }
}
