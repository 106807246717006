import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";
import { TablesService } from "./services/tables.service";
import { TablesGroupesService } from "./services/tableGroupes.service";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class TablesShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private tablesService: TablesService,
        private tgroupesService: TablesGroupesService) {
        super();
    }

    ngOnInit() {
    }

    get moduleDataLoaded() {
        const storeKeysToVerify = ['table', 'tablegroup'];
        return storeKeysToVerify.map(k => !!this.store._(k)).
        reduce((acc, val) => {
            return acc && val;
        }, true)
    }
}