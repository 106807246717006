import { DeclineOrderModalComponent } from './Declineorder-modal/Declineorder-modal.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// models
import {MenuItemOrderRefGroupModel, MenuItemOrderRefModel, OrderModel, UpdateModel} from '../../models/OrderModel';
import { KeyValuePair } from '../../../models/KeyValuePair';
// services
import { OrderService } from '../../services/order.service';
import { ColorsService } from '../../../shared/colors/colors.service';
import { StatusService } from '../../../status.module/services/status.service';

// components
import { AuthenticatedComponent } from '../../../shared/components/AuthenticatedComponent';
import {SectionModalComponent} from '../../../menu.module/menu/edit/sections/section-modal/section-modal.component';
import {ModalsService} from '../../../bootstrap-components.module/modals/modals.service';
import {StatusHistoryModalComponent} from './statushistory-modal/Statushistory-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {SignalRService} from '../../../shared/services/signal-r.service';
import {LoadingService} from '../../../shared/services/loading.service';
@Component({
  selector: 'order-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class OrderEditComponent extends AuthenticatedComponent implements OnInit {

  constructor(
    public orderService: OrderService,
    private activeRoute: ActivatedRoute,
    private statusService: StatusService,
    private modalService: ModalsService,
    protected translate: TranslateService,
    private signalRSevice: SignalRService,
    private loadingService: LoadingService,
    public colors: ColorsService) {
    super();
  }
  private modalRef: any = null;
  public columns: Array<any> = [

    { title: 'image', name: 'image' },
    { title: 'menuItem', name: 'menuItem' },
    { title: 'user', name: 'user' },
    { title: 'tableName', name: 'tableName' },
    { title: 'itemPrice', name: 'itemPrice' },
    { title: 'properties', name: 'properties' },
    { title: 'Item amount', name: 'itemAmount' },
    { title: 'quantity', name: 'quantity' },
    { title: 'amount', name: 'amount' },
    { title: 'orderStatus', name: 'orderStatus' },
  ];
  public order: OrderModel = new OrderModel();
  public update: UpdateModel = new UpdateModel();
  public ress: any;
  public typelist: Array<KeyValuePair> = [
    new KeyValuePair(0, 'payment.Withoutcardbinding'),
    new KeyValuePair(1,  'payment.Wiyhcardbinding'),
    new KeyValuePair(2, 'payment.Cash'),
    new KeyValuePair(3,  'payment.Withbingingcard'),
    new KeyValuePair(4,  'payment.Idram'),
    new KeyValuePair(5,  'payment.Reversed'),

  ];

  public statusList: Array<KeyValuePair> = [

    new KeyValuePair(0, 'statuses.NEW'),
    new KeyValuePair(1,  'statuses.COMPLETED'),
    new KeyValuePair(2,  'statuses.REQUESTED'),
    new KeyValuePair(3,  'statuses.LEFT'),
    new KeyValuePair(4,  'statuses.LEFT'),
    new KeyValuePair(5,  'statuses.CANCELED'),

  ];

  ngOnInit() {
    this.loadingService.startLoading();
    this.signalRSevice.connection.on('UpdateStatus', () => {
      this.loadData();
    });
    this.signalRSevice.connection.on('UpdateOrderStatus', () => {
      this.loadData();
    });
    this.signalRSevice.connection.on('UpdateItemStatus', () => {
      this.loadData();
    });
    this.loadData();
  }

  public loadData() {
    const orderId = this.activeRoute.snapshot.params.id;
    this.orderService.get(orderId).subscribe(res => {
      if (!!res) {
        this.order = this.order.fromObject(res);
        for (const item of this.order.menuItemOrderRefGroups) {
          item.isChecked = true;
        }
      } else {
        this.alertService.showError(this.translate.instant('alerts.SOMETHINGWENTWRONG'));
        this.goToUrl('/order/list');
      }
    });
  }



  public get isEdit() {
    return this.activeRoute.snapshot.url.join('').includes('edit');
  }

  public statusHistory(ref: MenuItemOrderRefGroupModel) {

    this.modalRef = this.modalService.simpleLg(this.translate.instant("order.STATUSHISTORIES"), StatusHistoryModalComponent, {
          ref,
          currency: this.order.table.place.currencySymbol
    });
  }
  public declineOrder(update: UpdateModel) {
    this.modalRef = this.modalService.simpleLg(this.translate.instant("decline.DECLINE"), DeclineOrderModalComponent, {
          update,
          transactions: this.order.transactions
    });
    const subscription = this.modalRef.onHidden.subscribe(() => {
      this.loadData();
  });
  }
  public closeTheBill(serveAll: boolean) {
    this.loadingService.startLoading();
    this.orderService.closeTheBill(this.order.id, serveAll).subscribe(data => {
      if (!data.hasError) {
        this.loadData();
        this.alertService.showSuccess(this.translate.instant('alerts.BILLCLOSEDSUCCESSFULLY'));
      } else {
        const errors = data.errors.map(r => r.message);
        this.alertService.showError(errors);
      }
    });
  }

  public moveTo(status: number) {
    this.update.menuItemOrderRefIds = [];
    this.update.statusId = status;
    for (const item of this.order.menuItemOrderRefGroups) {
        if (item.isChecked) {
          this.update.menuItemOrderRefIds = this.update.menuItemOrderRefIds.
          concat(item.menuItemOrderRefIds);
        }
      }
    if (status === 5) {
        this.declineOrder(this.update);
      } else {
        this.loadingService.startLoading();
        this.orderService.UpdateStatus(this.update).subscribe(data => {
        this.loadData();
        if (!data.hasError) {
            this.alertService.showSuccess(this.translate.instant('alerts.STATUSUPDATEDSUCCESSFULLY'));
       } else {
         const errors = data.errors.map(r => r.message);
         this.alertService.showError(errors);
      }
      });
    }
  }
  public isFormValid() {

    if (!super.isFormValid()) {
      this.alertService.showError(this.translate.instant('alerts.'));
      return false;
    }
    return true;
  }
  public reverse(id: number) {
    this.orderService.ReverseTransaction(id).subscribe(data => {
        console.log(data);
    });
  }


}
