import { ImageModel } from '../../models/ImageModel';
import { PlaceModel } from '../../place.module/models/PlaceModel';

export class ImageUploadModel {
id: number;
url: string;
}

export class MenuModel {
id: number;
name: string;
placeId: number;
sortOrder = 0;
imageUpload: ImageUploadModel = new ImageUploadModel();
imageIds: Array<number> = [];
place: PlaceModel ;
imageUrls: Array<ImageModel> = [];
sections: any;
nameTranslationId = 0;
isActive = true;
}

export class MenuInsertModel {
id: number;
name: string;
placeId: number;
sortOrder = 0;
imageIds: Array<number> = [];
nameTranslationId = 0;
isActive = true;
}


