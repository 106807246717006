import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OptionModel } from '../models/OptionModel';

@Injectable()
export class OptionService extends CrudService<OptionModel> {

    protected serviceItemsKey = 'option';

    constructor(protected store: AppStore) {
        super('Option', null);
    }

     public preloadData() {
        return [
            this.getAllOptions(),
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

    public getAllOptions() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetAll'))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

    public getAllByMenuItem(id) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + `/GetAllByMenuItem?menuItemId=${id}`))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }
    
    public getSortmenu(menuitemId)
    {
      return this.apirequest("get", this.apiCallTo(this.serviceApiUrl+`/GetAllForSorting?menuitemId=${menuitemId}`))
        .pipe(
          map(i => i.data),
        );
    }
  
    public UpdateSortmenu(sortList)
    {
      return this.apirequest("post", this.apiCallTo(this.serviceApiUrl + `/UpdateSortOrder`), null, JSON.stringify(sortList))
        .pipe(
          map(i => i.data),
        );
    }
}