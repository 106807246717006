export class NotificationModel {
id: number;
title: string;
body: string;
type: number;
sound: string;
priority: number;
isForPartner: boolean;
isForPlatform: boolean;
isForAdmin: boolean;
titleTranslationId = 0;
bodyTranslationId = 0;
isActive = true;
}
