import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models

//services

//component
import { AuthenticatedComponent} from "../../../../shared/components/AuthenticatedComponent";
import {PlaceService} from "../../../services/place.service";
import {BsModalRef} from "ngx-bootstrap";
@Component({
	selector: 'app-section-modal',
	templateUrl: './sortingPlaces-modal.component.html',
	styleUrls: ['./sortingPlaces-modal.component.scss']
})

export class SortingPlacesModalComponent extends AuthenticatedComponent implements OnInit {

	constructor(public placeService:PlaceService,public bsModalRef:BsModalRef){
		super();
	}
	sortList:Array<any>;
	clicked:boolean=false;
	ngOnInit() {
      this.placeService.getSortmenu().subscribe(data=>
      {
            this.sortList= data.sort((a, b) => a.sortOrder - b.sortOrder);
      })
	}
  public save()
  {
    for (let i=0;i<this.sortList.length;i++)
    {
      this.sortList[i].sortOrder=i+1;
    }
    this.clicked=true;
    this.placeService.UpdateSortmenu(this.sortList).subscribe(d => {
      this.clicked=false;
      if(!d.hasError) {
          this.alertService.showSuccess(this.translate.instant("alerts.SORTORDERUPDATEDSUCCESSFULLY"));
          this.bsModalRef.hide();
        }
       else {
        let errors = d.errors.map(r => {return r.message});
        this.alertService.showError(errors);
      }
    });
  }






}
