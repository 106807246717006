import { Component, OnInit, Directive, ComponentFactoryResolver, ViewContainerRef, ViewChild, Type, EventEmitter, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ModalComponent } from '../ModalComponent';

@Component({
    selector: 'modal-container',
    templateUrl: './modal-container.component.html',
    styleUrls: ['./modal-container.component.css']
})
export class ModalContainerComponent extends ModalComponent implements OnInit, AfterViewInit {

    public title: string;
    public message: string;
    public componentType: Type<{}>;
    public componentData: any;
    public largeModal: boolean = false;
    constructor(public bsModalRef: BsModalRef,
        private componentFactoryResolver: ComponentFactoryResolver) {
        super();
    }

    @ViewChild('template', {static: true, read: ViewContainerRef}) template: ViewContainerRef;
    ngOnInit() { }

    ngAfterViewInit() {
        // get component
        if (!!this.componentType) {
            let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.componentType)
            this.template.clear();
            let c = this.template.createComponent(componentFactory);
            const componentDataKeys = Object.keys((this.componentData || {}));
            componentDataKeys.filter(k => k.indexOf("$") < 0).forEach(e => {
                c.instance[e] = this.componentData[e];
            })
            componentDataKeys.filter(k => k.indexOf("$") >= 0)
                .forEach(e => {
                    const eventName = e.replace(/\$/g, "")
                    let instanceEvent = c.instance[eventName]; // is an EventEmitter
                    const eventHandler: Function = this.componentData[e];
                    if (instanceEvent && eventHandler) {
                        instanceEvent.subscribe(eventHandler);
                        // instanceEvent.subscribe(d => {
                        //     if (eventHandler) eventHandler.call(this, d);
                        // });
                    }
                })
        }
    }

    confirmAction(option) {
        this.actionResponse.next(!!option);
        this.bsModalRef.hide();
    }
}
