import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//models
import { MenuModel } from '../../../models/MenuModel';
import { SectionModel } from '../../../models/SectionModel';
import { SectionInsertModel } from '../../../models/SectionModel';
//services
import {MenuService, SectionService} from "../../../services/menu.service";
import { ModalsService } from '../../../../bootstrap-components.module/modals/modals.service';
//components
import { AuthenticatedComponent } from '../../../../shared/components/AuthenticatedComponent';
import { SectionModalComponent } from './section-modal/section-modal.component';
import {TranslateService} from "@ngx-translate/core";
import {StatusHistoryModalComponent} from "../../../../order.module/order/edit/statushistory-modal/Statushistory-modal.component";
import {SortingModalComponent} from "./sorting-modal/sorting-modal.component";
import {saveAs} from "file-saver";
import {SortingSectionsModalComponent} from "../menu/sortingSections-modal/sortingSections-modal.component";
import {ImportSectionsModalComponent} from "./importSections-modal/importSections-modal.component";
import {ImportMenuItemsModalComponent} from "./importMenuItems-modal/importMenuItems-modal.component";
@Component({
	selector: 'app-sections',
	templateUrl: './sections.component.html',
	styleUrls: ['./sections.component.scss']
})
export class SectionsComponent extends AuthenticatedComponent implements OnInit, OnChanges {

	constructor(
		private activeRoute: ActivatedRoute,
		private modalService: ModalsService,
    public menuService:MenuService,
    protected translate: TranslateService,

    private sectionService: SectionService) {
		super();
	}

	private modalRef: any = null;
	public sectionList: Array<SectionModel> = [];
	public data: Array<SectionModel> = [];
	@Input() menu: SectionModel = new SectionModel();

	public columns: Array<any> = [
	{ title: 'id', name: 'id' },
	{ title: 'name', name: 'name' },
    { title: 'settings', name: 'settings' },
	];

	ngOnInit() {

		}
	ngOnChanges() {
		this.store.$("section").subscribe(c => {
			setTimeout(() => {
				this.sectionList = c;
			}, 50);
			this.hideModal();
		})

		if (this.menu.id ) {
			this.sectionService.getAllSections(this.menu.id).subscribe(data => {
				this.sectionList = data;
			});
		}
	}

	public openSortmodal(sectionId)
  {
    this.modalRef = this.modalService.simpleLg("Sort Menu Items", SortingModalComponent, {
      sectionId:sectionId,
    });
  }
	public addSection(id:number = null) {
		this.modalRef = this.modalService.simpleLg(!!id ? 'Update Section' : 'Add Section', SectionModalComponent, {
			menu: this.menu,
			sectionId: id
		});
	}

	public hideModal() {
		if (this.modalRef) {
			setTimeout(() => {
				this.modalRef.ref.hide();
			}, 10);
		}
	}
 public openImportmodal()
 {
   this.modalRef = this.modalService.simpleLg("Import Sections", ImportSectionsModalComponent, {
     menuId:this.menu.id
   });
 }
  public openImportMenuItemsmodal(sectionId)
  {
    this.modalRef = this.modalService.simpleLg("Import Menu Items", ImportMenuItemsModalComponent, {
      sectionId:sectionId,
      menuId:this.menu.id
    });
  }
  public export()
  {
    this.menuService.Export(this.menu.id).subscribe(data=>
    {
      var blob = new Blob([data],{type:`text/csv`});
      saveAs(blob,"Sections.csv");
    })
  }
  public exportMenuItems(sectionId)
  {
    this.sectionService.Export(sectionId).subscribe(data=>
    {
      var blob = new Blob([data],{type:`text/csv`});
      saveAs(blob,"MenuItems.csv");
    })
  }
  public openSectionsSortmodal(menuId)
  {
    this.modalRef = this.modalService.simpleLg("Sort Sections", SortingSectionsModalComponent, {
      menuId:menuId,
    });
  }

	createInsertModel(section: SectionModel): SectionInsertModel {

		let insertModel: SectionInsertModel = new SectionInsertModel();

		insertModel.id = section.id;
		insertModel.name = section.name;
		insertModel.menuId = section.menuId;
		insertModel.roleIds = section.roleIds;
		insertModel.nameTranslationId = section.nameTranslationId;
		insertModel.sortOrder = section.sortOrder
		insertModel.isActive = section.isActive;

		return insertModel;
	}

	public save(section: SectionModel ) {

		let insertModel = this.createInsertModel(section);

		this.sectionService.save(insertModel).subscribe(data => {
			if(!data.hasError ) {
				this.alertService.showSuccess(this.translate.instant("alerts.SECTIONTUPDATEDSUCCESSFULLY"));
			} else {
				let errors = data.errors.map(r => {return r.message});
				this.alertService.showError(errors);
			}
		})
	}
}
