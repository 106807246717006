import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";
import { StatusService } from "./services/status.service";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class StatusShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private statusService: StatusService) {
        super();
    }

    ngOnInit() { }
}