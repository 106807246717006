import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { RedirectToLoginGuard } from '../shared/services/security/redirectToLoginGuard';
import { DataStore } from '../shared/models/DataStore';
import { SettingsStore } from './settings.store';
import { appInjector } from '../bootstrap-components.module/utils/appInjector';
import { AppStore } from '../shared/services/store.service';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SettingsShellComponent } from './settings-shell.component';

import { SettingsService } from "./services/settings.service";
import { PrivacyEditComponent } from './settings/privacy/edit/edit.component';
import { PrivacyListComponent } from './settings/privacy/list/list.component';
import { AboutEditComponent } from './settings/about/edit/edit.component';
import { AboutListComponent } from './settings/about/list/list.component';
import { TermsEditComponent } from './settings/terms/edit/edit.component';
import { TermsListComponent } from './settings/terms/list/list.component';
import { MeasureUnitListComponent } from './measureunits/list/list.component';
import { MeasureunitEditComponent } from './measureunits/edit/edit.component';

import {MeasureUnitService} from "./services/MeasureUnitService";


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    AngularEditorModule,
  ],
  declarations: [
    SettingsShellComponent,
    PrivacyEditComponent,
    PrivacyListComponent,
    AboutEditComponent,
    AboutListComponent,
    TermsEditComponent,
    TermsListComponent,
    MeasureUnitListComponent,
    MeasureunitEditComponent
  ],

  exports: [],
  entryComponents: [],

  providers: [ SettingsService ,MeasureUnitService]
})
export class SettingsModule {

  static preloadData() {
    const services = this.moduleServices();
    return {
      resources: [
        ...services.settingsService.preloadData(),
      ]
    };
  }

  private static moduleServices() {
    let appinjector = appInjector.injector();
    let store = appinjector.get(AppStore);
    let settingsService = appinjector.get(SettingsService);

    return {
      store,
      settingsService,
    }
  }

  static initializeModuleData() {
    let injector = appInjector.injector();
    let store = injector.get(AppStore);
    SettingsStore.prototype.initializeStore.call(store);
    this.loadData();
  }

  // static routerRoutes() {
  //   return routes;
  // }

  static initializeStore(store) {
    SettingsStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();
    services.settingsService.preloadData();
  }

}
