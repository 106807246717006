import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AdditionalInfoModel } from '../models/AdditionalInfoModel';

@Injectable()
export class AdditionalService extends CrudService<AdditionalInfoModel> {

    protected serviceItemsKey = 'additionalInfo';

    constructor(protected store: AppStore) {
        super('AdditionalInfo', null);
    }

    public preloadData() {
        return [
            this.getAllAdditionalInfo(),
        ];
    }

    public loadData() {
        return forkJoin(this.preloadData());
    }

    public getAllAdditionalInfo() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetAll'))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

}