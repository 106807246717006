import { Directive, AfterViewInit, ElementRef } from "@angular/core";
import { TranslationsProvider } from "./translations.provider";
import { TranslateService } from '@ngx-translate/core';


@Directive({
    selector: '[translate]'
})
export class TranslateDirective implements AfterViewInit {
    constructor(
        private elementRef: ElementRef<any>,
        public translate: TranslateService,
        private translationsProvider: TranslationsProvider) {
    }

    ngAfterViewInit() {
        // if (localStorage.getItem("save-translations-keys") ==="true" && translationKey) {
        //     let translationsKeys = {};
        //     try {
        //         translationsKeys = JSON.parse(localStorage.getItem("translations-keys"));
        //     } catch (e) {
        //     }
        //     translationsKeys = translationsKeys || {};
        //     translationsKeys[translationKey] = translationKey;
        //     localStorage.setItem("translations-keys", JSON.stringify(translationsKeys));
        // }
        if (this.elementRef.nativeElement.innerText === 'Update Invoice') {
            console.log(`translate directive on empty element: ${this.elementRef.nativeElement}`);
        }
        this.elementRef.nativeElement.innerText = this.translationsProvider.getTranslationFor(this.elementRef.nativeElement.innerText);
    }
}