import { Injectable } from '@angular/core';
import { CrudService } from './../../shared/services/crud.service';
import { AppStore } from './../../shared/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PrivacyPolicyModel } from '../models/PrivacyPolicyModel';

@Injectable()
export class SettingsService extends CrudService<PrivacyPolicyModel> {

    protected serviceItemsKey = 'privacy';

    constructor(protected store: AppStore) {
        super('Privacy', null);
    }

    preloadData() {
        return [
            this.getAllPrivacy(),
        ];
    }

    public loadData() {
       return forkJoin(this.preloadData());
    }

    public getAllPrivacy() {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + '/GetAll'))
            .pipe(
                map(i => i.data),
                // tap(d => this.appStore.set(this.serviceItemsKey, d))
            );
    }

}