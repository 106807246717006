export class CreateUserModel {
id: number;
login: string;
password: string;
organisationId: number;
placeIds: Array<number> = [];
roleIds: Array<number> = [];
gender: number;
birthDate: string;
firstName: string;
lastName: string;
isActive = true;
}
