import { AlertsService } from "./../../../shared/services/alerts.service";
import { Component, OnInit } from "@angular/core";
import { AuthenticatedComponent } from "../../../shared/components/AuthenticatedComponent";
import { RequestService } from "../../services/request.service";
@Component({
  selector: "request-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class RequestListComponent implements OnInit {
  constructor(
    private requestService: RequestService,
    private alertService: AlertsService
  ) {}
  public requestList: Array<any> = [];
  public data: Array<any> = [];
  ngOnInit() {
    this.requestService.getAllRequests().subscribe((data) => {
      this.requestList = data;
    });
  }
  public rows: Array<any> = [];
  public columns: Array<any> = [
    { title: "id", name: "id" },
    { title: "name", name: "name" },
    { title: "country", name: "country" },
    { title: "tin", name: "tin" },
  ];
}
