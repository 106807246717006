import { AuthenticatedComponent } from "../shared/components/AuthenticatedComponent";
import { Component, OnInit } from "@angular/core";
import { PlaceService } from "./services/place.service";

@Component({
    template: `
    <router-outlet></router-outlet>
    `
})
export class PlaceShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private placeService: PlaceService) {
        super();
    }

    ngOnInit() {

        // if (!this.moduleDataLoaded) {
        //     this.placeService.loadData().subscribe(
        //         r => {
        //             this.alertService.showInfo(`Places data has been loaded!`);
        //             this.goToUrl('/place/list');
        //         },
        //         e => {
        //             this.alertService.showError(`Loading Places data had encounter error.`);
        //             this.goToUrl('/dashboard');
        //         });
        // } else {
        //     this.alertService.showInfo(`Places data has been loaded!`);
        //     this.goToUrl('/place/list');
        // }
    }

    get moduleDataLoaded() {
        const storeKeysToVerify = ['place'];
        return storeKeysToVerify.map(k => !!this.store._(k)).
        reduce((acc, val) => {
            return acc && val;
        }, true)
    }

}