import { RedirectToLoginGuard } from "../shared/services/security/redirectToLoginGuard";
import { AuthorizeGuard } from "../shared/services/security/authorizeGuard";
//components
import { NotificationShellComponent } from './notification-shell.component';
import { NotificationListComponent } from './notification/list/list.component';
import { NotificationEditComponent } from './notification/edit/edit.component';
import { NotificationRoleListComponent } from './notificationRole/list/list.component';
import { EditComponent } from './notificationRole/edit/edit.component';
import { RoleEditComponent } from './roles/edit/edit.component';
import {MyNotificationComponent} from "./my-notification/my-notification.component";

export const notification = [
    {
      path: "list",
      component: NotificationListComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    },
  {
    path: "my-notifications",
    component: MyNotificationComponent,
    canActivate: [RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: []
      }
    }
  },
    {
      path: "edit/:id",
      component: NotificationEditComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    } ,
    {
      path: "user-role/list",
      component: NotificationRoleListComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    },
    {
      path: "user-role/add",
      component: EditComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    },
    {
      path: "role/add/:placeId",
      component: RoleEditComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    },
    {
      path: "role/edit/:id",
      component: RoleEditComponent,
      canActivate: [RedirectToLoginGuard],
      data: {
        mustHave: {
          permissions: []
        }
      }
    }
]
