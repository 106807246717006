import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AccountService } from '../account.module/services/account.service';
import { AppStore } from '../shared/services/store.service';

import { partner, admin, orgAdmin } from './menu';
import { routes } from './routes';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, { useHash: true}),
    PagesModule,
    DashboardModule,
  ],
  declarations: [],
  exports: [RouterModule],
})
export class RoutesModule {
  //   constructor(
  //     public menuService: MenuService,
  //     private store: AppStore,
  //     tr: TranslatorService
  //   ) {
  //  menuService.addMenu(admin);
  // this.store.$("account.loggedUser").subscribe((c) => {
  //   if (!!c && c.roles[0] === "SuperAdmin") {
  //     menuService.addMenu(admin);
  //   } else if (!!c && c.roles[0] === "OrgAdmin") {
  //     menuService.addMenu(orgAdmin);
  //   } else {
  //     menuService.addMenu(partner);
  //   }
  // });
}
